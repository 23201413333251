import ShowMoreButton from '../common/Buttons/ShowMoreButton';
import axios from 'axios';
import classNames from 'classnames';
import { isEmpty, isNil } from 'lodash';
import React, { useEffect, useState } from 'react';

import BuGroupButton from 'components/UI/BuGroupButton';
import MeetingsCallsRow from 'components/UI/MeetingAndCallsTable/Row';
import styles from 'components/UI/MeetingAndCallsTable/styles';
import {
  DataItem,
  DataState,
  IProps,
  RequestState,
  ServerResponse,
} from 'components/UI/MeetingAndCallsTable/types';
import { tableSizeStyle } from 'components/UI/common/TypedTable/styles';
import { useBoundary } from 'components/hooks/useBoundary';

const MeetingsCalls: React.FC<IProps> = (props: IProps) => {
  const isFirstLoad = useBoundary(props.position);

  const [pastMeetings, setPastMeetings] = useState<DataState>({
    data: [],
    total_count: 0,
  });
  const [upcomingMeetings, setUpcomingMeetings] = useState<DataState>({
    data: [],
    total_count: 0,
  });
  const [requestState, setRequestState] = useState<RequestState>({
    page_number: 0,
    page_size: 5,
  });
  const [activeTab, setActiveTab] = useState('past');
  const [isLoadingState, setLoadingState] = useState(false);

  const fetchData = async (): Promise<void> => {
    const url: string = `/api/data/deals/${props.objectId}/meetings_calls/${props.objectType}`;
    const { data: past }: ServerResponse = await axios.get(url, {
      params: requestState,
    });
    const { data: upcoming }: ServerResponse = await axios.get(url, {
      params: { ...requestState, upcoming_meetings: true },
    });

    setPastMeetings({
      total_count: past.data.total_count,
      data: isNil(past.data.events)
        ? pastMeetings.data
        : [...pastMeetings.data, ...past.data.events],
    });

    setUpcomingMeetings({
      total_count: upcoming.data.total_count,
      data: isNil(upcoming.data.events)
        ? upcomingMeetings.data
        : [...upcomingMeetings.data, ...upcoming.data.events],
    });
  };

  useEffect(() => {
    if (!isFirstLoad) {
      setLoadingState(true);
      fetchData().then(() => setLoadingState(false));
    }
  }, [isFirstLoad, requestState]);

  const buildOptionGroupData = () => {
    return [
      {
        id: 'past',
        text: `Past Meetings (${pastMeetings.total_count})`,
      },
      {
        id: 'upcoming',
        text: `Upcoming Meetings (${upcomingMeetings.total_count})`,
      },
    ];
  };

  const dataToRender = activeTab === 'past' ? pastMeetings : upcomingMeetings;

  return (
    <div className={styles.table}>
      <div className={styles.tabs_container}>
        <div className={classNames(styles.section_header, 'bu-font-heading')}>
          Meetings
        </div>
        <div className={styles.buttons_box_content}>
          <BuGroupButton
            className={styles.group_button_container}
            options={buildOptionGroupData()}
            selectedOption={activeTab}
            onSelect={(value: string) => {
              setActiveTab(value);
            }}
            useRevampStyle
          />
        </div>
      </div>
      <table
        className={classNames(
          styles.new_table,
          styles.table_sticky_header,
          tableSizeStyle,
          'table-compact'
        )}
      >
        <thead className={styles.table_header}>
          <tr>
            <th
              className={classNames(
                styles.table_header_column,
                styles.table_header_first_column,
                'table-header-label'
              )}
            >
              Name
            </th>
            <th
              className={classNames(
                styles.table_header_column,
                'table-header-label'
              )}
            >
              Customer Attendees
            </th>
            <th
              className={classNames(
                styles.table_header_column,
                'table-header-label'
              )}
            >
              Company Attendees
            </th>
            <th
              className={classNames(
                styles.table_header_column,
                'table-header-label'
              )}
            >
              Call Audio
            </th>
            <th
              className={classNames(
                styles.table_header_column,
                'table-header-label'
              )}
              style={{ width: '35%' }}
            >
              Meeting follow up email
            </th>
          </tr>
        </thead>
        {isEmpty(dataToRender.data) ? (
          <tbody>
            <tr>
              <td colSpan={5} className={styles.nothing_found}>
                Nothing found
              </td>
            </tr>
          </tbody>
        ) : (
          <tbody>
            {dataToRender.data.map((item: DataItem) => (
              <MeetingsCallsRow
                objectId={props.objectId}
                key={item.id}
                data={item}
              />
            ))}
          </tbody>
        )}

        {dataToRender.data.length < dataToRender.total_count && (
          <tbody>
            <tr>
              <td colSpan={5}>
                <div role="none" className="table-body-btn">
                  <ShowMoreButton
                    showing={dataToRender.data.length}
                    count={dataToRender.total_count}
                    loading={isLoadingState}
                    onClick={() =>
                      setRequestState({
                        ...requestState,
                        page_number: requestState.page_number + 1,
                      })
                    }
                  />
                </div>
              </td>
            </tr>
          </tbody>
        )}
      </table>
    </div>
  );
};

export default MeetingsCalls;
