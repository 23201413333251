import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';

import { goToSearch } from 'actions/routeActions';
import { changeSearchQuery, goToResource } from 'actions/searchActions';
import { getSearchSuggestions } from 'actions/searchActions';
import InlineSearch from 'components/UI/InlineSearch';
import TypedSearchInput from 'components/UI/TypedSearchInput';
import * as styles from 'components/dashboard/styles';
import { IReduxState } from 'reducers/types';
import {
  getSearchQuery,
  isLoadingSearchSuggestions,
  getSearchSuggestionsForInline,
  hasMoreSearchResults,
} from 'selectors';

interface ActivitySyncInfoRecord {
  status: string;
  category: string;
  text: string;
  created_at: string;
}
interface Suggestion {
  activity_sync_info: ActivitySyncInfoRecord[] | [] | undefined;
  description: string;
  icon: string;
  shouldLink: boolean;
  title: string;
  type: string;
  value: string;
  warning: boolean;
}
interface Props {
  query: string;
  suggestions:
    | {
        meetings?: Suggestion[];
        accounts?: Suggestion[];
        opportunities?: Suggestion[];
      }
    | {};
  replaceSearch: boolean;
  loading: boolean;
  hasMoreResults: boolean;
  search: Function;
  openResource: Function;
  changeQuery: Function;
  getSearch: Function;
}

const SearchTopPanel: React.FC<Props> = ({
  query,
  suggestions,
  loading,
  hasMoreResults,
  changeQuery,
  search,
  openResource,
  getSearch,
}) => {
  const [isLoader, setLoader] = useState<boolean>(false);
  let timeoutId = useRef<NodeJS.Timer | null>();

  useEffect(() => {
    if (!loading) {
      setLoader(false);
    }
  }, [loading]);

  const debounce = (fn: Function, value: string, ms: number) => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }
    timeoutId.current = setTimeout(() => fn(value), ms);
  };

  const fetch = (value: string) => {
    setLoader(true);
    getSearch(value);
  };

  const onChange = (value: string) => {
    changeQuery(value);
    debounce(fetch, value, 1500);
  };

  return (
    <InlineSearch
      className={styles.search}
      placeholder="Search opportunity, meeting or account"
      searchBoxComponent={TypedSearchInput}
      query={query}
      results={suggestions}
      loading={isLoader}
      hasMoreResults={hasMoreResults}
      onQueryChange={(value: string) => onChange(value)}
      onResultClick={(type: string, value: string) =>
        openResource(type, value, query)
      }
      onSearch={() => search(query)}
    />
  );
};

const mapStateToProps = (state: IReduxState) => ({
  query: getSearchQuery(state),
  loading: isLoadingSearchSuggestions(state),
  suggestions: getSearchSuggestionsForInline(state),
  hasMoreResults: hasMoreSearchResults(state),
});

const mapDispatchToProps = (dispatch: Function) => {
  return {
    changeQuery: (query: string) => dispatch(changeSearchQuery(query)),
    search: (query: string, replaceSearch: boolean) =>
      dispatch(goToSearch(query, replaceSearch)),
    openResource: (type: string, id: string, query: string) =>
      dispatch(goToResource(type, id, query)),
    getSearch: (query: string) => dispatch(getSearchSuggestions(query)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchTopPanel);
