type align = 'left' | 'center' | 'right';

export const getColumnAlignmentByType = (
  columnType: string,
  align?: align
): align => {
  switch (columnType) {
    case 'percentage':
    case 'money':
      return 'right';
    case 'number':
    case 'checkbox':
    case 'risk':
      return 'center';
    default:
      return align || 'left';
  }
};
