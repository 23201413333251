export const DEALS = 'DEALS';
export const CALLS = 'CALLS';
export const CALLS_STATS = 'CALLS_STATS';
export const EVENTS = 'EVENTS';
export const INIT = 'INIT';
export const TRANSCRIPT = 'TRANSCRIPT';
export const ACCOUNT = 'ACCOUNT';
export const ACCOUNTS = 'ACCOUNTS';
export const BUSINESS_TYPES = 'BUSINESS_TYPES';
export const SPLIT_TYPES = 'SPLIT_TYPES';
export const BUSINESS_TYPES_CONFIG = 'BUSINESS_TYPES_CONFIG';
export const SETTINGS = 'SETTINGS';
export const REPORT = '_REPORT';
export const ROUTER_CONFIG = 'ROUTER_CONFIG';
export const SETTINGS_USERS = 'SETTINGS_USERS';
export const API = '_API';
export const TOKENS = '_TOKENS';
export const SERVICES = '_SERVICES';
export const TEAM_MEMBERS = 'TEAM_MEMBERS';
export const SUGGESTED_TEAM_MEMBERS = 'SUGGESTED_TEAM_MEMBERS';
export const PRIMARY_FIELD = 'PRIMARY_FIELD';
export const TOPICS = 'TOPICS';
export const LOGIN = 'LOGIN';
export const SIGN_UP = 'SIGN_UP';
export const SALESFORCE = 'SALESFORCE';
export const HUBSPOT = 'HUBSPOT';
export const SEARCH = 'SEARCH';
export const EVENT = 'EVENT';
export const PREVIOUS_ROUTE = 'PREVIOUS_ROUTE';
export const DEAL_STAGE = 'DEAL_STAGE';
export const COMPANY_SETTINGS = 'COMPANY_SETTINGS';
export const FLAGS = 'FLAGS';
export const CUSTOM_VIEWS = 'CUSTOM_VIEWS';
export const PIN = 'PIN';
export const MODAL = 'MODAL';
export const ACTIVITY = 'ACTIVITY';
export const ACTIVITY_MATCHING = '_ACTIVITY_MATCHING';
export const SALESFORCE_SCHEMA = 'SALESFORCE_SCHEMA';
export const AI_TRAINING = 'AI_TRAINING';
export const CACHE = 'CACHE';
export const WIDGETS = 'WIDGETS';
export const ADMIN = 'ADMIN';
export const EMAIL = 'EMAIL';
export const DEAL = 'DEAL';
export const FORECAST = 'FORECAST';
export const NEW_PIPELINE_CREATED = 'NEW_PIPELINE_CREATED';
export const PIPELINE_CREATED_BY_CLOSE_DATE = 'PIPELINE_CREATED_BY_CLOSE_DATE';
export const PIPELINE_STAGES = 'PIPELINE_STAGES';
export const TARGETS = 'TARGETS';
export const TABLE = 'TABLE';
export const HIDE = 'HIDE';
export const PERSIST = '_PERSIST';
export const RESET = '_RESET';
export const RENAME = '_RENAME';
export const OVERIDE = '_OVERIDE';
export const PROGRESSION = '_PROGRESSION';
export const CUSTOM_FIELD = '_CUSTOM_FIELD';
export const TOTALS = '_TOTALS';
export const FIELD_CONFIGURATION = 'FIELD_CONFIGURATION';
export const FIELD_CONFIGURATION_TABLES = 'FIELD_CONFIGURATION_TABLES';
export const FILTERS = 'FILTERS';
export const TABLE_CONFIGURATION = 'TABLE_CONFIGURATION';
export const CRM_FORECAST_CATEGORY = '_CRM_FORECAST_CATEGORY';
export const CRM_SYNC = '_CRM_SYNC';
export const FREQUENCY_TIME = '_FREQUENCY_TIME';
export const RT = '_RT';
export const FORECAST_CATEGORY = '_FORECAST_CATEGORY';
export const TABLE_DRAFT = '_TABLE_DRAFT';
export const PACE_QUARTERLY = '_PACE_QUARTERLY';
export const QUOTA_ATTAINMENT = '_QUOTA_ATTAINMENT';
export const SEQUENCE = '_SEQUENCE';
export const SALES_PROCESS = '_SALES_PROCESS';
export const SALES_CONFIG = '_SALES_CONFIG';
export const INCLUDED_DEALS = '_INCLUDED_DEALS';
export const VIEW = '_VIEW';
export const PROFILES = '_PROFILES';
export const PROFILE = '_PROFILE';
export const DASHBOARD = '_DASHBOARD';
export const OPP_SPLIT_VIEW = '_OPP_SPLIT_VIEW';
export const MEDDIC = '_MEDDIC';

export const AUTH = '_AUTH';
export const IMPERSONATE = '_IMPERSONATE';
export const STATUS = '_STATUS';
export const VERSION = '_VERSION';
export const CONTENT = '_CONTENT';
export const LABELED_CONTENT = '_LABELED_CONTENT';
export const ALL = '_ALL';
export const GET = '_GET';
export const CHANGE = '_CHANGE';
export const FILTER = '_FILTER';
export const DETAIL = '_DETAIL';
export const CREATE = '_CREATE';
export const REMOVE = '_REMOVE';
export const UPDATE = '_UPDATE';
export const REMAP = '_REMAP';
export const READY = '_READY';
export const PAGINATION = '_PAGINATION';
export const SET_ACTIVE_TAB = '_SET_ACTIVE_TAB';
export const ACTIVE = '_ACTIVE';
export const ENABLED = '_ENABLED';
export const OAUTH = '_OAUTH';
export const SSO = '_SSO';
export const STEP = '_STEP';
export const QUERY = '_QUERY';
export const SUGGESTION = '_SUGGESTION';
export const RESULT = '_RESULT';
export const METRICS = 'METRICS';
export const MENTIONS = '_MENTIONS';
export const ALL_COMPETITORS = '_ALL_COMPETITORS';
export const ALL_TOPICS = '_ALL_TOPICS';
export const SCROLL = '_SCROLL';
export const CLEAR = '_CLEAR';
export const APPLY = '_APPLY';
export const ADD = '_ADD';
export const RESTORE = '_RESTORE';
export const SORTING = '_SORTING';
export const VIEW_BY = '_VIEW_BY';
export const EXPORT = '_EXPORT';
export const PROSPECT = '_PROSPECT';
export const ENGAGEMENT = '_ENGAGEMENT';
export const DEALTOPICS = '_TOPICS';
export const DESCRIBE = '_DESCRIBE';
export const COUNT = '_COUNT';
export const BY_USER = '_BY_USER';
export const BY_MANAGER = '_BY_MANAGER';
export const VISIBLE_SERIES = '_VISIBLE_SERIES';
export const USERS = '_USERS';
export const USER = '_USER';
export const MANAGE = '_MANAGE';
export const MANAGERS = '_MANAGERS';
export const MANAGERS_TEAM = '_MANAGERS_TEAM';
export const UNMAPPED_USERS = '_UNMAPPED_USERS';
export const SYNC = '_SYNC';
export const DATE = '_DATE';
export const SUBMIT = '_SUBMIT';

export const SUCCESS = '_SUCCESS';
export const ERROR = '_ERROR';
export const REQUEST = '_REQUEST';
export const LOADING = '_LOADING';
export const CLOSE = '_CLOSE';
export const OPEN = '_OPEN';
export const SET = '_SET';
export const REFRESH = '_REFRESH';
export const START = '_START';
export const TEST = '_TEST';
export const CANCEL = '_CANCEL';
export const ENTER = '_ENTER';
export const EXIT = '_EXIT';
export const TOGGLE = '_TOGGLE';
export const CLEAR_FILTERS = '_CLEAR_FILTERS';
export const SAVE_TAB_SCROLL = '_SAVE_TAB_SCROLL';
export const SAVE_PAGE_SIZE_OF_TABLE = '_SAVE_PAGE_SIZE_OF_TABLE';
export const SHOW_OR_HIDE_TABLE_COLUMN = '_SHOW_OR_HIDE_TABLE_COLUMN';
export const LOCAL = '_LOCAL';
export const GLOBAL = '_GLOBAL';
export const APP_INITIATED = 'APP_INITIATED';
export const APP_DISPLAY_MODE = 'APP_DISPLAY_MODE';
export const HIDDEN_COLUMNS = 'HIDDEN_COLUMNS';
export const SOQL_QUERY = '_SOQL_QUERY';
export const CONDITIONS = '_CONDITIONS';
export const DISPLAY = '_DISPLAY';
export const CONFIGURATION = '_CONFIGURATION';
export const CATEGORY = '_CATEGORY';
export const SORTING_KEYS = '_SORTING_KEYS';
export const FIELDS_INFO = '_FIELDS_INFO';
export const FILTER_POLICY = '_FILTER_POLICY';
export const SET_SHOW_LIVE_BADGE_ON_MODAL = 'SET_SHOW_LIVE_BADGE_ON_MODAL';

export const FORECAST_SUBMISSION_CREATE = 'FORECAST_SUBMISSION_CREATE';
export const FORECAST_SUBMISSION_CREATE_SUCCESS =
  'FORECAST_SUBMISSION_CREATE_SUCCESS';
export const FORECAST_SUBMISSION_CREATE_ERROR =
  'FORECAST_SUBMISSION_CREATE_ERROR';
export const FORECAST_SUBMISSION_CREATE_LOADING =
  'FORECAST_SUBMISSION_CREATE_LOADING';

export const FORECAST_SETTINGS_AVAILABLE_GET =
  'FORECAST_SETTINGS_AVAILABLE_GET';
export const FORECAST_SETTINGS_AVAILABLE_SUCCESS =
  'FORECAST_SETTINGS_AVAILABLE_SUCCESS';
export const FORECAST_SETTINGS_AVAILABLE_LOADING =
  'FORECAST_SETTINGS_AVAILABLE_LOADING';

export const FORECAST_SETTING_HISTORY_GET = 'FORECAST_SETTING_HISTORY_GET';
export const FORECAST_SETTING_HISTORY_GET_SUCCESS =
  'FORECAST_SETTING_HISTORY_GET_SUCCESS';
export const FORECAST_SETTING_HISTORY_GET_LOADING =
  'FORECAST_SETTING_HISTORY_GET_LOADING';

export const FORECAST_SETTING_DEALS_GET = 'FORECAST_SETTING_DEALS_GET';
export const FORECAST_SETTING_DEALS_GET_SUCCESS =
  'FORECAST_SETTING_DEALS_GET_SUCCESS';
export const FORECAST_SETTING_DEALS_GET_LOADING =
  'FORECAST_SETTING_DEALS_GET_LOADING';

export const FORECAST_SUBMISSIONS_BY_PERIOD_TO_COPY_GET =
  'FORECAST_SUBMISSIONS_BY_PERIOD_TO_COPY';
export const FORECAST_SUBMISSIONS_BY_PERIOD_TO_COPY_SUCCESS =
  'FORECAST_SUBMISSIONS_BY_PERIOD_TO_COPY_SUCCESS';
export const FORECAST_SUBMISSIONS_BY_PERIOD_TO_COPY_LOADING =
  'FORECAST_SUBMISSIONS_BY_PERIOD_TO_COPY_LOADING';

export const FORECAST_USER_SUBMISSION_HISTORY =
  'FORECAST_USER_SUBMISSION_HISTORY';
export const FORECAST_USER_SUBMISSION_HISTORY_SUCCESS =
  'FORECAST_USER_SUBMISSION_HISTORY_SUCCESS';
export const FORECAST_USER_SUBMISSION_HISTORY_ERROR =
  'FORECAST_USER_SUBMISSION_HISTORY_ERROR';
export const FORECAST_USER_SUBMISSION_HISTORY_LOADING =
  'FORECAST_USER_SUBMISSION_HISTORY_LOADING';

export const DEALS_MODIFIED_SINCE_RECALCULATE_RESET =
  'DEALS_MODIFIED_SINCE_RECALCULATE_RESET';
export const FORECAST_SETTING_RESET_DATA = 'FORECAST_SETTING_RESET_DATA';

export const transcriptTypes = {
  UPDATE_STATE: 'TRANSCRIPT_UPDATE_STATE',
  DO_SEARCH: 'TRANSCRIPT_DO_SEARCH',
  RESET_SEARCH: 'RESET_SEARCH',
  RESET_ACTIVE_TOPIC: 'RESET_ACTIVE_TOPIC',
  RESET_STATE: 'TRANSCRIPT_RESET_STATE',
  RESET_SELECTION: 'TRANSCRIPT_RESET_SELECTION',
  REMOVE_TAG: 'REMOVE_TAG',
  TOGGLE_ADDING_TAG: 'TOGGLE_ADDING_TAG',
  CANCEL_ADDING_TAG: 'CANCEL_ADDING_TAG',
  ADD_TAG: 'ADD_TAG',
  ADD_TAG_ERROR: 'ADD_TAG_ERROR',
  RESET_TAG_ERROR: 'RESET_TAG_ERROR',
  PLAY_TOPIC: 'PLAY_TOPIC',
};
