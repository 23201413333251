import React from 'react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuIcon from 'components/UI/BuIcon';
import { TemplateFilter } from 'components/dashboard/Metrics/Widget/TemplateFilters/TemplateFilter/TemplateFilter';
import {
  FilterBarELement,
  FiltersContainer,
  FiltersDropdownsContainer,
} from 'components/dashboard/Metrics/Widget/TemplateFilters/styles';
import {
  DataDescriptor,
  BIMetricsFilter,
  BIWidget,
} from 'components/dashboard/Metrics/metrics.types';
import { Loader, Popup } from 'semantic-ui-react';
import { getConsolidatedFilters } from 'api/DashboardFilters';
import { useQuery } from '@tanstack/react-query';

interface Props {
  isDateFirstPivot?: boolean;
  showControls?: boolean;
  templateFilters?: BIMetricsFilter[];
  widgetFilters?: DataDescriptor[];
  dashboardFilters?: BIMetricsFilter[];
  relativeDateFilterOptions?: Filters.DateFilterElement[];
  tables?: string[];
  timeInterval?: string;
  forecastPeriod?: string;
  timePeriod?: string;
  targetPeriod?: string;
  onChangeWidget: (widget: Partial<BIWidget>) => void;
}

export const TemplateFilters: React.FC<Props> = ({
  isDateFirstPivot = false,
  showControls = false,
  templateFilters = [],
  widgetFilters = [],
  dashboardFilters = [],
  relativeDateFilterOptions = [],
  tables = [],
  timeInterval,
  timePeriod,
  targetPeriod,
  onChangeWidget,
}) => {
  const dashboardFiltersNames = dashboardFilters.map(
    (filter) => filter.column.name
  );

  const { isFetching: loading, data } = useQuery({
    queryKey: [
      'dashboard_filters',
      'filters_widget',
      widgetFilters,
      templateFilters,
      dashboardFilters,
    ],
    queryFn: () =>
      getConsolidatedFilters(
        widgetFilters,
        templateFilters,
        dashboardFilters,
        tables
      ),
    enabled: showControls && dashboardFilters.length !== 0,
  });
  const filters =
    dashboardFilters.length && data
      ? data.filters.map((filter) => filter.column)
      : widgetFilters;
  const consolidatedFilters =
    dashboardFilters.length && data ? data.filters : templateFilters;
  const disabledFilters =
    dashboardFilters.length && data
      ? data.disabled_filters.map((filter) => filter.name)
      : [];

  if (!filters.length) {
    return null;
  }
  const newDashboardFilters = filters
    .filter((filter) => dashboardFiltersNames.includes(filter.name))
    .sort(
      (a, b) =>
        dashboardFiltersNames.indexOf(a.name) -
        dashboardFiltersNames.indexOf(b.name)
    );
  const newWidgetFilters = widgetFilters
    .filter((filter) => !dashboardFiltersNames.includes(filter.name))
    .sort((a, b) => widgetFilters.indexOf(a) - widgetFilters.indexOf(b));

  return (
    <FiltersContainer
      showControls={showControls}
      data-testing="filters-container"
    >
      {loading ? (
        <Loader active inline size="small" />
      ) : (
        <FiltersDropdownsContainer>
          {newDashboardFilters.length ? (
            <>
              <Popup
                content="Dashboard filters"
                trigger={
                  <FilterBarELement>
                    <BuIcon
                      name={BoostUpIcons.ColumnGraphBox}
                      color="var(--bu-gray-600)"
                    />
                  </FilterBarELement>
                }
              />
              {newDashboardFilters.map((filter, idx) => {
                if (filter && filter.name && filter.type) {
                  return (
                    <FilterBarELement
                      key={`dashboard_filter_${filter.type}_${filter.name}_${filter.label}`}
                      data-testing={`filter-bar-element-${idx}`}
                    >
                      <TemplateFilter
                        isDateFirstPivot={isDateFirstPivot}
                        widgetFilter={filter}
                        templateFilters={consolidatedFilters.filter(
                          (elem) =>
                            elem.column.name === filter.name &&
                            elem.operator !== 'all'
                        )}
                        disabled={disabledFilters.includes(filter.name)}
                        relativeDateFilterOptions={relativeDateFilterOptions}
                        timeInterval={timeInterval}
                        timePeriod={timePeriod}
                        targetPeriod={targetPeriod}
                        onChangeWidget={(widget: Partial<BIWidget>) => {
                          const currentFilter = widget.template_filters?.find(
                            (templateFilter) =>
                              filter.name === templateFilter.column.name
                          );
                          if (currentFilter) {
                            onChangeWidget({
                              dashboard_filters: dashboardFilters.map(
                                (filter) =>
                                  filter.column.name ===
                                  currentFilter.column.name
                                    ? currentFilter
                                    : filter
                              ),
                            });
                          } else {
                            onChangeWidget({
                              dashboard_filters: dashboardFilters.map((elem) =>
                                elem.column.name === filter.name
                                  ? {
                                      column: filter,
                                      operator: 'all',
                                      value: 'all',
                                    }
                                  : elem
                              ),
                            });
                          }
                        }}
                      />
                    </FilterBarELement>
                  );
                }
              })}
            </>
          ) : null}
          {newWidgetFilters.length ? (
            <>
              <Popup
                content="Widget filters"
                trigger={
                  <FilterBarELement>
                    <BuIcon
                      name={BoostUpIcons.Filter}
                      color="var(--bu-gray-600)"
                    />
                  </FilterBarELement>
                }
              />
              {newWidgetFilters.map((filter, idx) => {
                if (filter && filter.name && filter.type) {
                  return (
                    <FilterBarELement
                      key={`${filter.type}_${filter.name}_${filter.label}_${consolidatedFilters.length}`}
                      data-testing={`filter-bar-element-${idx}`}
                    >
                      <TemplateFilter
                        isDateFirstPivot={isDateFirstPivot}
                        widgetFilter={filter}
                        templateFilters={consolidatedFilters.filter(
                          (elem) => elem.operator !== 'all'
                        )}
                        disabled={disabledFilters.includes(filter.name)}
                        relativeDateFilterOptions={relativeDateFilterOptions}
                        timeInterval={timeInterval}
                        timePeriod={timePeriod}
                        targetPeriod={targetPeriod}
                        onChangeWidget={onChangeWidget}
                      />
                    </FilterBarELement>
                  );
                }
              })}
            </>
          ) : null}
        </FiltersDropdownsContainer>
      )}
    </FiltersContainer>
  );
};
