import { createTranscriptAction } from '../../actions/transcriptActions';
import * as selectors from '../../selectors';
import * as service from '../UI/AudioPlayer/service';
import { AnalyticsTracker } from '../common/analyticsUtils';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Icon } from 'semantic-ui-react';

class TranscriptSearchBar extends Component {
  onPrevious = () => {
    const {
      selectedTopics,
      formattedFilteredSnippets,
      updateState,
      searchIndex,
    } = this.props;
    const audio = document.getElementById('music');
    const isSearch = formattedFilteredSnippets.length > 0;
    const fragments = isSearch ? formattedFilteredSnippets : selectedTopics;
    if (fragments.length > 0) {
      const nextTopicIndex = fragments.findIndex((t) => t > audio.currentTime);
      if (nextTopicIndex === -1) {
        audio.currentTime =
          fragments[fragments.length > 2 ? fragments.length - 2 : 0];
      } else {
        audio.currentTime =
          fragments[
            nextTopicIndex > 1 ? nextTopicIndex - 2 : fragments.length - 1
          ];
      }
      if (isSearch) {
        updateState(
          'searchIndex',
          searchIndex === 0 ? fragments.length - 1 : searchIndex - 1
        );
      }
    } else {
      audio.currentTime -= 10; // go backward by 10 seconds
    }

    AnalyticsTracker.event(this.props, {
      category: 'Call Insights',
      action: 'Search Results',
      label: 'Go Previous',
    });
  };

  onNext = () => {
    const { selectedTopics, formattedFilteredSnippets, updateState } =
      this.props;
    const audio = document.getElementById('music');
    const isSearch = formattedFilteredSnippets.length > 0;
    const fragments = isSearch ? formattedFilteredSnippets : selectedTopics;

    if (fragments.length > 0) {
      const nextTopic =
        fragments.find((t) => t > audio.currentTime) || fragments[0];
      audio.currentTime = nextTopic;
      if (isSearch) {
        const index = fragments.findIndex((t) => t > audio.currentTime);
        updateState(
          'searchIndex',
          index === -1 ? fragments.length - 1 : index - 1
        );
      }
    } else {
      audio.currentTime += 10; // go forward by 10 seconds
    }
    AnalyticsTracker.event(this.props, {
      category: 'Call Insights',
      action: 'Search Results',
      label: 'Go Next',
    });
  };

  handleSearchTextChange = (evt) => {
    const { activeTopic, setSearchText } = this.props;
    if (activeTopic !== '') {
      evt.preventDefault();
      return false;
    }
    setSearchText(evt.target.value);
  };

  handleResetSearch = (evt) => {
    evt.preventDefault();
    service.resetPlayer();
    AnalyticsTracker.event(this.props, {
      category: 'Call Insights',
      action: 'Search Results',
      label: 'Reset Search',
    });
  };

  handleSearch = (evt) => {
    if (evt.keyCode === 13 || evt.type === 'click') {
      evt.preventDefault();

      const { searchText, doSearch } = this.props;
      doSearch();
      AnalyticsTracker.event(this.props, {
        category: 'Call Insights',
        action: 'Search Results',
        label: `Search Text: ${searchText}`,
      });
    }
  };

  render() {
    const {
      searchText,
      selectedTopics,
      filteredSnippets,
      searchIndex,
      activeTopic,
      currentTime,
    } = this.props;

    const isPanelActive = searchText !== '' || activeTopic;
    const inputActive = classNames({
      'callTranscript-form-control': true,
      active: isPanelActive,
    });
    const inputActiveBtnPrev = classNames({
      'callTranscript-search-prev': true,
      active: isPanelActive,
    });
    const inputActiveBtnNext = classNames({
      'callTranscript-search-next': true,
      active: isPanelActive,
    });
    const inputActiveBtnReset = classNames({
      'callTranscript-reset-search': true,
      active: isPanelActive,
    });

    const inputActiveBtnGo = classNames({
      'callTranscript-go-search-btn': true,
      active: isPanelActive,
    });

    const termName = searchText || (activeTopic && `Topic: ${activeTopic}`);
    const termsAmount = filteredSnippets.length || selectedTopics.length;

    function getCurrentIndex() {
      if (selectedTopics.length > 0) {
        const filteredTopics = selectedTopics.filter((t) => t < currentTime);
        return filteredTopics.length;
      }
      return searchIndex === '' || searchIndex === null ? 0 : searchIndex + 1;
    }

    return (
      <nav className="callTranscript-search-conatiner">
        <div className="callTranscript-search-box">
          <form className="callTranscript-search-form" id="search">
            <div className="callTranscript-form-group">
              <input
                onChange={this.handleSearchTextChange}
                onKeyDown={this.handleSearch}
                type="search"
                placeholder="Search"
                className={inputActive}
                autoComplete="off"
                value={termName}
                data-testing="txt_field"
                id="search-input-box"
              />
              <ul className="callTranscript-search-result">
                {(searchIndex !== null || activeTopic) && (
                  <li className="search-count">
                    <span className="callTranscript-active-search-item">
                      {`${getCurrentIndex()} of `}
                    </span>
                    <span className="callTranscript-total-search-item">
                      {termsAmount}
                    </span>
                  </li>
                )}

                <li
                  role="presentation"
                  onClick={this.onPrevious}
                  className={inputActiveBtnPrev}
                >
                  <span>
                    <Icon name="chevron left" />
                    Prev
                  </span>
                </li>
                <li
                  role="presentation"
                  onClick={this.onNext}
                  className={inputActiveBtnNext}
                >
                  <span>
                    Next
                    <Icon name="chevron right" />
                  </span>
                </li>

                {searchIndex === null && !activeTopic ? (
                  <li
                    role="presentation"
                    onClick={this.handleSearch}
                    className={inputActiveBtnGo}
                  >
                    <span>Go</span>
                  </li>
                ) : (
                  <li
                    role="presentation"
                    onClick={this.handleResetSearch}
                    className={inputActiveBtnReset}
                  >
                    <span>Reset</span>
                  </li>
                )}
              </ul>
            </div>
          </form>
        </div>
      </nav>
    );
  }
}

TranscriptSearchBar.defaultProps = {
  callDetail: PropTypes.shape({
    attendees: PropTypes.array,
    audio_url: PropTypes.string,
    competitors_discussed: PropTypes.array,
    description: PropTypes.string,
    duration: PropTypes.number,
    id: PropTypes.string,
    my_tags: PropTypes.array,
    opportunity_name: PropTypes.string,
    other_tags: PropTypes.array,
    segments: PropTypes.array,
    start: PropTypes.string,
    title: PropTypes.string,
    topics_discussed: PropTypes.array,
  }),
  selectedTopics: PropTypes.array,
  formattedFilteredSnippets: PropTypes.array,
  updateState: PropTypes.func,
  searchIndex: PropTypes.number,
  activeTopic: PropTypes.string,
  setSearchText: PropTypes.func,
  searchText: PropTypes.string,
  doSearch: PropTypes.func,
  filteredSnippets: PropTypes.arrayOf(PropTypes.number),
  currentTime: PropTypes.number,
};

TranscriptSearchBar.propTypes = {
  callDetail: PropTypes.shape({
    attendees: PropTypes.array,
    audio_url: PropTypes.string,
    competitors_discussed: PropTypes.array,
    description: PropTypes.string,
    duration: PropTypes.number,
    id: PropTypes.string,
    my_tags: PropTypes.array,
    opportunity_name: PropTypes.string,
    other_tags: PropTypes.array,
    segments: PropTypes.array,
    start: PropTypes.string,
    title: PropTypes.string,
    topics_discussed: PropTypes.array,
  }),
  selectedTopics: PropTypes.arrayOf(PropTypes.number),
  formattedFilteredSnippets: PropTypes.arrayOf(PropTypes.number),
  updateState: PropTypes.func,
  searchIndex: PropTypes.number,
  activeTopic: PropTypes.string,
  setSearchText: PropTypes.func,
  searchText: PropTypes.string,
  doSearch: PropTypes.func,
  filteredSnippets: PropTypes.arrayOf(PropTypes.number),
  currentTime: PropTypes.number,
};

const mapStateToProps = (state) => ({
  filteredSnippets: selectors.getFilteredSnippets(state),
  formattedFilteredSnippets: selectors.getFormattedFilteredSnippets(state),
  selectedSnippet: selectors.getSelectedSnippet(state),
  selectedTopics: selectors.getSelectedTopics(state),
  isPlaying: selectors.getPlayingState(state),
  selectedGroupType: selectors.getSelectedGroupType(state),
  selectedGroup: selectors.getSelectedGroup(state),
  searchText: selectors.getSearchText(state),
  searchIndex: selectors.getSearchIndex(state),
  callDetail: selectors.getCallDetail(state),
  activeTopic: selectors.getActiveTopic(state),
  currentTime: selectors.getCurrentTimeOffset(state),
  isAdmin: selectors.isAdmin(state),
});

const mapDispatchToProps = {
  setSearchText: createTranscriptAction.setSearchText,
  updateState: createTranscriptAction.updateState,
  doSearch: createTranscriptAction.doSearch,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TranscriptSearchBar);
