import { PROFILE_VIEWS_TABLE_COLUMNS } from './CustomViewsConfigurator.helper';
import { ViewSubSection } from './ProfileCustomViewsConfigurator.types';
import classNames from 'classnames';
import React from 'react';
import { Accordion } from 'semantic-ui-react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuIcon from 'components/UI/BuIcon';
import Table from 'components/UI/TableConfig/Table';
import { onChangeCallback } from 'components/UI/common/TypedTable/TypedTable';
import * as s from 'components/settings/UserProfiles/Views/CustomViewsConfigurator.styles';

interface Props {
  viewSubSection: ViewSubSection;
  isAccordionExpanded: boolean;
  onTitleClick: () => void;
  updateViewEnabled: (id: string, newValue: boolean) => void;
}

const SubSectionViewAccordion = ({
  viewSubSection,
  isAccordionExpanded,
  onTitleClick,
  updateViewEnabled,
}: Props) => {
  const enabledViewsForSubSection = viewSubSection.sharedViews.filter(
    (view) => view.enabled
  ).length;

  const totalViewsForSubSection = viewSubSection.sharedViews.length;

  const onToggleClick: onChangeCallback = (_column, { id }, newValue) => {
    updateViewEnabled(id as string, newValue as boolean);
  };

  return (
    <>
      <Accordion.Title
        index={viewSubSection.value}
        active={isAccordionExpanded}
        onClick={onTitleClick}
      >
        <div
          className={classNames(s.accordionHeader, {
            [s.accordionActive]: isAccordionExpanded,
          })}
          onClick={onTitleClick}
        >
          <BuIcon
            className={s.accordionIcon}
            name={
              isAccordionExpanded
                ? BoostUpIcons.SquareMinus
                : BoostUpIcons.SquarePlus
            }
          />

          <h3 className={classNames('bu-font-section-title', s.accordionTitle)}>
            {viewSubSection.displayName}
          </h3>

          <div
            className={classNames('bu-font-caption', s.accordionEnabledViews)}
          >
            ({enabledViewsForSubSection}/{totalViewsForSubSection} views
            enabled)
          </div>
        </div>
      </Accordion.Title>
      <Accordion.Content active={isAccordionExpanded}>
        <Table
          columns={PROFILE_VIEWS_TABLE_COLUMNS}
          data={viewSubSection.sharedViews}
          onChange={onToggleClick}
          fullscreen
          rowsPerPage={0}
          currentPage={0}
          hidePaginationEnd
          hidePaginationStart
          hideSearch
        />
      </Accordion.Content>
    </>
  );
};

export default SubSectionViewAccordion;
