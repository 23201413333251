import classNames from 'classnames';
import get from 'lodash/get';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Segment, Dimmer, Loader } from 'semantic-ui-react';

import { actions } from 'actions';
import { DEFAULT_PAGE_SIZE } from 'actions/eventActions';
import PlayImage from 'assets/fonts/boostup-icons/play.svg';
import AttendeeItem from 'components/UI/AttendeeItem';
import Pagination from 'components/UI/DealsFlatTableTS/Table/Pagination';
import * as styles from 'components/UI/EventsTable/styles';
import {
  IProps,
  Attendee,
  Event,
  IUser,
} from 'components/UI/EventsTable/types';
import ExpandibleItems from 'components/UI/ExpandibleItems';
import { ModalLink, Link } from 'navigation/Link';
import { PersistQueryParams } from 'navigation/types';
import { openModal } from 'navigation/utils';
import { fetchApi } from 'utils/network';

import MindtickleCallIcon from '../../../assets/fonts/boostup-icons/mindtickle_call_icon.svg';

const EventsTable: React.FC<IProps> = (props) => {
  const dispatch = useDispatch();
  const [count, setCount] = useState(0);
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(
    get(props, 'persistedState.filters.page_number', 0)
  );
  const [pageSize, setPageSize] = useState<number>(
    get(
      props,
      'persistedState.filters.page_size',
      props.embedded ? 50 : DEFAULT_PAGE_SIZE
    )
  );
  const totalPages = Math.ceil(count / pageSize);

  useEffect(() => {
    setCurrentPage(0);
  }, [props.filters]);

  /* Fetch data when page size or number changes */
  useEffect(() => {
    const abortController = new AbortController();
    setLoading(true);
    fetchApi<any, any>({
      url: props.apiUrl ? props.apiUrl : '/api/data/events/',
      queryMethod: 'post',
      queryParams: JSON.stringify({
        ...props.filters,
        page_size: pageSize,
        page_number: currentPage,
      }),
      setData(response) {
        if (response?.data) {
          setEvents(response.data.events);
          setCount(response.data.count);
          setLoading(false);
        }
      },
      signal: abortController.signal,
    });

    return () => {
      if (abortController) {
        abortController.abort();
      }
    };
  }, [currentPage, pageSize, JSON.stringify(props.filters)]);

  /* Persist state when something of interest changes */
  useEffect(() => {
    if (!props.persistName) {
      return;
    }

    props.persistTableParams({
      name: props.persistName,
      type: 'common',
      filter: {
        page_size: pageSize,
        page_number: currentPage,
        sort: props.filters.sort,
      },
    });
  }, [currentPage, pageSize, props.filters]);

  /* Reset page to 0 when changing page size */
  const onPageSizeChange = (pageSize: number) => {
    setPageSize(pageSize);
    setCurrentPage(0);
  };

  const redirectToUrl = (event: Event) => {
    return fetchApi({
      url: `${process.env.REACT_APP_BACKEND_URL}/api/data/events/call_ai/${event.id}`,
      queryMethod: 'get',
      setData(response) {
        if (response) {
          const activity = (response as any).activities[0];

          openModal({
            scheme: '/activity/:activityId',
            params: {
              activityId: `${event.opportunity.id || event.id}_${
                activity.record_id
              }`,
            },
            persistParams: {
              activity: response,
            },
            persistor: (params: PersistQueryParams) =>
              dispatch(actions.ui.modal.persist(params)),
          });
        }
      },
      setError() {
        const win = window.open(event.recording_url, '_blank');
        win?.focus();
        return null;
      },
    });
  };

  const renderAttendees = (
    attendees: Attendee[],
    customer: boolean,
    creator: IUser,
    organizer: IUser
  ) => {
    return (
      <ExpandibleItems
        max={5}
        // @ts-ignore
        items={attendees
          .filter((attendee: Attendee) => {
            return customer == attendee.is_customer;
          })
          .map((attendee: Attendee) => (
            <AttendeeItem
              key={attendee.email || attendee.display_name}
              creator={creator}
              organizer={organizer}
              attendee={attendee}
            />
          ))}
      />
    );
  };

  const tableRows = events.map((event: Event) => (
    <div
      className={classNames(styles.rows, {
        [styles.rows_embedded]: props.embedded,
      })}
      key={event.id}
    >
      <div className={styles.body_column}>
        <ModalLink
          key={event.id}
          url={{
            scheme: '/event/:eventId',
            params: { eventId: event.id },
          }}
          className={styles.name_field_name}
        >
          {event.summary}
        </ModalLink>
        <div className={styles.name_field_date}>
          {moment(event.start).local().format('DD MMM YYYY, h:mm A')}
        </div>
      </div>
      <div className={styles.body_column}>
        {event.opportunity ? (
          <ModalLink
            key={event.opportunity.id}
            url={{
              scheme: '/deal/:id',
              params: { id: event.opportunity.id },
            }}
            className={styles.dealName}
          >
            <div className={styles.dealName}>
              {event.opportunity.opportunity_name}
            </div>
          </ModalLink>
        ) : null}
      </div>
      <div className={styles.body_column}>
        {event && event.attendees
          ? renderAttendees(
              event.attendees,
              true,
              event.creator,
              event.organizer
            )
          : null}
      </div>
      <div className={styles.body_column}>
        {event && event.attendees
          ? renderAttendees(
              event.attendees,
              false,
              event.creator,
              event.organizer
            )
          : null}
      </div>
      <div className={styles.body_column}>
        {event.call && moment.utc(event.start) < moment.utc() ? (
          event.recording_url && event.source === 'Mindtickle' ? (
            <div>
              <button
                onClick={() => redirectToUrl(event)}
                style={{ cursor: 'pointer' }}
                type="button"
                className={styles.callButton}
              >
                Play
                <img
                  style={{
                    width: '32px',
                    marginLeft: '5px',
                    marginRight: '-1px',
                  }}
                  src={MindtickleCallIcon}
                  alt=""
                />
              </button>
            </div>
          ) : (
            <Link
              key={event.call}
              url={{
                scheme: '/transcript/:id',
                params: { id: event.call },
              }}
            >
              <button
                style={{ cursor: 'pointer' }}
                type="button"
                className={styles.callButton}
              >
                Play
                <img src={PlayImage} alt="play" />
              </button>
            </Link>
          )
        ) : (
          <span className={styles.callStatus}>Call Not Available</span>
        )}
      </div>
      {props.embedded && (
        <>
          <div className={styles.body_column}>
            {event.topics_discussed
              .filter((topic) => !topic.is_competitor)
              .map((topic) => topic.topic_name)
              .join(', ')}
          </div>
          <div className={styles.body_column}>
            {event.topics_discussed
              .filter((topic) => topic.is_competitor)
              .map((topic) => topic.topic_name)
              .join(', ')}
          </div>
        </>
      )}
    </div>
  ));

  return (
    <Segment basic padded={false}>
      <div className={styles.paginationWrapper}>
        <div className={styles.paginationTitle}>
          {props.title ? props.title : 'Events'}{' '}
          {!loading ? <span>({count})</span> : null}
        </div>

        <Pagination
          rowsPerPage={pageSize}
          totalPages={totalPages}
          currentPage={currentPage + 1}
          onClickPrev={() =>
            currentPage >= 1 ? setCurrentPage(currentPage - 1) : null
          }
          onClickNext={() =>
            currentPage + 1 < totalPages
              ? setCurrentPage(currentPage + 1)
              : null
          }
          onChangePageNumber={(page: number) => setCurrentPage(page)}
          onChangeRowsPerPage={onPageSizeChange}
        />
      </div>

      <div className={styles.table}>
        <div
          className={classNames(styles.table_header, {
            [styles.table_header_embedded]: props.embedded,
          })}
        >
          <div className={styles.header_column}>Name</div>
          <div className={styles.header_column}>Deal Name</div>
          <div className={styles.header_column}>Prospect Attendees</div>
          <div className={styles.header_column}>Company Attendees</div>
          <div className={styles.header_column}>Call Audio</div>
          {props.embedded && (
            <>
              <div className={styles.header_column}>Topics</div>
              <div className={styles.header_column}>Competitors</div>
            </>
          )}
        </div>
        <div className={classNames(styles.table_body, 'table-body')}>
          {loading ? (
            <div style={{ height: '80vh' }}>
              <Loader active />
            </div>
          ) : (
            tableRows
          )}
        </div>
      </div>
    </Segment>
  );
};

export default EventsTable;
