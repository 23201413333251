import React, { useContext, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  parseCsvColumns,
  parseDrilldownColumns,
  parseDrilldownItems,
  useManageCustomObjectData,
} from './CustomObjectModal.helper';

import { isAxiosError } from 'axios';
import { DownloadButtonProps } from 'components/UI/DownloadButton/types';
import Table from 'components/UI/TableConfig/Table';
import {
  ColumnTypesCallback,
  columnTypes,
  typeToScheme,
} from 'components/UI/TableConfig/column-helper';
import { isColumnConfigClickable } from 'components/UI/TableConfig/types';
import {
  IRow,
  onChangeCallback,
} from 'components/UI/common/TypedTable/TypedTable';
import { currencyFormatter } from 'components/UI/common/TypedTable/formatters';
import { useRevBiDrilldownModalWithScheme } from 'components/dashboard/Metrics/Widget/hooks/useRevBiDrilldownModal';
import { getDrilldownUrlQueryParams } from 'components/dashboard/Metrics/Widget/metrics.widget.helpers';
import { RevBISettingsContext } from 'components/dashboard/Metrics/contexts/RevBISettingsContext';
import { useManageRowStatuses } from 'components/hooks/useManageRowStatuses';
import { openModal } from 'navigation/utils';
import { getSalesforceUrl, isMulticurrencyEnabled } from 'selectors';

const PAGE_LIMIT = 15;
export const CustomObjectTableModal: React.FC = () => {
  const [orderByExpression, setOrderByExpression] =
    useState<string | undefined>('');

  const { currency: companyCurrencyCode } = useContext(RevBISettingsContext);

  const isMultiCurrencyEnabled = useSelector(isMulticurrencyEnabled);
  const { getProps, markShouldReFetchOnClose } =
    useRevBiDrilldownModalWithScheme('/revbi-custom-object-table-modal');

  const modalProps = getProps();

  const [offsetIndex, setOffsetIndex] = useState<number>(0);
  const [paginationIndex, setPaginationIndex] = useState<number>(1);

  const {
    rowStatuses,
    setLoadingRowField,
    setSuccessRowField,
    setErrorRowField,
  } = useManageRowStatuses();

  const customObjectFilters = {
    ...modalProps?.drilldownParams,
    limit: PAGE_LIMIT,
    offset: offsetIndex,
    order_by_expression: orderByExpression,
  };

  const {
    drilldownColumns,
    drilldownItems,
    totalCount,
    isLoading,
    order,
    updateObjectField,
  } = useManageCustomObjectData(
    modalProps.apiUrl,
    modalProps.metricObject,
    customObjectFilters,
    {
      doNotInvalidateOnSettled: true,
    }
  );

  const csvColumns = parseCsvColumns(drilldownColumns);

  const handleOpportunitiesTableSpecialClick: ColumnTypesCallback = (props) => {
    if (
      isColumnConfigClickable(props.columnConfig) &&
      props.columnConfig.meta.object_id &&
      props.row?.opportunity
    ) {
      const scheme = typeToScheme[props.columnConfig.meta.object_id];
      const opp: IRow = props.row.opportunity as IRow;

      if (scheme) {
        openModal({
          scheme,
          params: { id: opp[props.columnConfig.meta.object_id] as string },
        });
      } else {
        console.warn('Action not supported');
      }
    }
  };

  const getColumnType = columnTypes(
    {
      currencyFormatter: currencyFormatter(
        companyCurrencyCode,
        0,
        isMultiCurrencyEnabled
      ),
      sequence: [],
    },
    handleOpportunitiesTableSpecialClick,
    '',
    isMultiCurrencyEnabled
  );

  const tableColumns = parseDrilldownColumns(drilldownColumns, getColumnType);

  const salesforceUrl = useSelector(getSalesforceUrl);

  const rows = parseDrilldownItems(drilldownItems, salesforceUrl);

  const handlePageChange = (pageNumber: number, _: number) => {
    setOffsetIndex(pageNumber - 1);
    setPaginationIndex(pageNumber);
  };

  const drilldownUrlQueryParams = getDrilldownUrlQueryParams(
    modalProps.drilldownParams
  );

  const downloadButton: DownloadButtonProps = useMemo(
    () => ({
      url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/drill_down/${modalProps?.metricObject}/download${drilldownUrlQueryParams}`,
      queryMethod: 'post',
      serializedQueryParams: JSON.stringify({
        drill_down: {
          ...modalProps?.drilldownParams,
          limit: 100000,
          offset: 0,
        },
        columns: csvColumns,
      }),
    }),
    [modalProps, csvColumns]
  );

  const tableTitle: string = useMemo(() => {
    return `${modalProps?.metricName ?? ''}`;
  }, [modalProps]);

  const handleChange: onChangeCallback = async (column, row, newValue) => {
    const objectId = row.id.toString();
    const fieldToUpdate = column.field;
    const updateOperation = {
      [fieldToUpdate]: newValue,
    };

    setLoadingRowField(objectId, fieldToUpdate);

    try {
      await updateObjectField({
        id: objectId,
        editedFields: updateOperation,
      });

      toast.success('Field updated successfully');
      setSuccessRowField(objectId, fieldToUpdate);
      markShouldReFetchOnClose();
    } catch (e) {
      const errorMessage =
        isAxiosError(e) && e.response?.data?.error?.message?.[0];
      toast.error(errorMessage || 'Failed to update field');
      setErrorRowField(objectId, fieldToUpdate);
    }
  };

  if (!modalProps) {
    return null;
  }

  return (
    <div style={{ minHeight: '150px' }}>
      <Table
        hidePaginationEnd
        hideSearch
        fullscreen
        isModal
        statuses={rowStatuses}
        totalAmount={0}
        loading={isLoading}
        title={tableTitle}
        totalCount={totalCount}
        currentPage={paginationIndex}
        rowsPerPage={PAGE_LIMIT}
        downloadButton={downloadButton}
        data={rows}
        columns={tableColumns}
        showTotalAmount={false}
        onPaginationChange={handlePageChange}
        onChange={handleChange}
        sortOrder={orderByExpression ? orderByExpression : order}
        onSort={setOrderByExpression}
      />
    </div>
  );
};
