import classNames from 'classnames';
import { nanoid } from 'nanoid';
import React, { PureComponent, useContext } from 'react';
import { Loader } from 'semantic-ui-react';

import { useHeader } from 'components/UI/Wrapper/Header/header.context';
import ActivityDashboardFilters from 'components/chart-dashboards/Dashboard/ActivityDashboardFilters';
import DashboardFilters from 'components/chart-dashboards/Dashboard/DashboardFilters';
import * as styles from 'components/chart-dashboards/Dashboard/styles';
import { IProps, Chart } from 'components/chart-dashboards/Dashboard/types';
import MainIndicators from 'components/chart-dashboards/MainIndicators';
import Widget from 'components/chart-dashboards/Widget';
import { VirtualizedItem } from 'components/virtualized/VirtualizedItem';
import { dashboardToIndex } from 'navigation/routes';

export const defaultOptions = {
  extraFilters: {
    roles: [
      'Account Executive',
      'Sales Manager / VP Sales / CRO',
      'GM / CRO / CEO',
      'Customer Success Manager',
      'BDR / SDR',
    ],
  },
};

export const ChartRow: React.FC<{
  charts: Chart[];
  dashboardId: string;
  email?: string;
  mode?: 'default' | 'lazy';
  withActivitySelect?: boolean;
  personalActivityProps?: { email: string; filters: { [key: string]: any } };
  isModal?: boolean;
}> = React.memo(
  ({
    personalActivityProps,
    charts,
    dashboardId,
    email,
    mode = 'lazy',
    withActivitySelect,
    isModal,
  }) => (
    <div className={styles.chartRow}>
      {charts.map((chart) => (
        <div
          key={chart.title}
          className={classNames(
            styles.chartWrapper(charts.length > 1 ? 49 : 100),
            isModal && styles.heightChart
          )}
        >
          <Widget
            hideFullScreenButton={charts.length === 1}
            isModal={isModal}
            {...{ ...defaultOptions, ...chart }}
            dashboardId={dashboardId}
            filters={
              email
                ? {
                    activity_period:
                      personalActivityProps?.filters?.activity_period,
                    sort_by: 'count',
                    users_seller: [email],
                  }
                : undefined
            }
            withActivitySelect={withActivitySelect}
            hideTimeSpanButtons
            mode={mode}
          />
        </div>
      ))}
    </div>
  ),
  (prev, next) => JSON.stringify(prev.charts) === JSON.stringify(next.charts)
);

class Dashboard extends PureComponent<IProps> {
  componentDidUpdate(prevProps: IProps) {
    const { title = '' } = this.props.dashboard;
    const scrollArea = document.getElementById('main_content');
    const email = 'email' in this.props ? this.props.email : undefined;
    const dashboardId =
      'email' in this.props ? '2' : dashboardToIndex[this.props.match.path];
    const tabPartition = this.props.tabPartition ?? '';

    if (scrollArea !== null && prevProps.dashboard?.title !== title) {
      scrollArea.scrollTo({ top: 0, behavior: 'smooth' });
    }

    this.props.headerContext?.setPartition(tabPartition);
    this.props.headerContext?.setShowTabs(
      (!email && dashboardId === '0') || tabPartition === 'dashboards'
    );
  }

  handleClickWrapper = () => {
    this.props.toggleAllFilters('_dashboard_', true);
  };

  componentWillUnmount() {
    this.props.headerContext?.clearContext();
  }

  render() {
    const email = 'email' in this.props ? this.props.email : undefined;
    const { personalActivityProps, dashboard, isManager } = this.props;

    if (!dashboard) {
      return (
        <div>
          <Loader active inline="centered" />
        </div>
      );
    }

    const dashboardId =
      'email' in this.props ? '2' : dashboardToIndex[this.props.match.path];

    const rows =
      'email' in this.props ? dashboard.rows.slice(0, 4) : dashboard.rows;

    return (
      <div className={styles.wrapper} onClick={this.handleClickWrapper as any}>
        {!email && dashboardId !== '0' ? (
          <>
            <DashboardFilters
              dashboardId={dashboardId}
              title={dashboard.title}
              showViewBySwitch={isManager}
            />

            <MainIndicators dashboardsHeader={dashboard.header} />
          </>
        ) : (
          !email && (
            <>
              <ActivityDashboardFilters showViewBySwitch={isManager} />
              <MainIndicators dashboardsHeader={dashboard.header} />
            </>
          )
        )}

        <div className={styles.charts}>
          {rows.map((row, i) => (
            <VirtualizedItem key={nanoid()} height={485}>
              <ChartRow
                charts={row.charts}
                dashboardId={dashboardId}
                email={email}
                personalActivityProps={personalActivityProps}
              />
            </VirtualizedItem>
          ))}
        </div>
      </div>
    );
  }
}

const DashboardWrapper: React.FC<IProps> = (props) => {
  const headerContext = useHeader();

  return <Dashboard headerContext={headerContext} {...props} />;
};

export default DashboardWrapper;
