import classNames from 'classnames';
import * as R from 'ramda';
import React, { useEffect, useState, useCallback, useContext } from 'react';
import { toast, ToastOptions } from 'react-toastify';

import Table from 'components/UI/TableConfig/Table';
import { useHeader } from 'components/UI/Wrapper/Header/header.context';
import ColumnsControl from 'components/UI/common/ColumnsControl';
import { IColumn, IRow } from 'components/UI/common/TypedTable/TypedTable';
import ActivityDashboardFilters from 'components/chart-dashboards/Dashboard/ActivityDashboardFilters';
import { getColumns, getRows } from 'components/dashboard/Scorecard/helper';
import * as styles from 'components/dashboard/Scorecard/styles';
import { IProps, ApiResponse } from 'components/dashboard/Scorecard/types';
import { fetchApi, QueryStatus } from 'utils/network';

const scorecardApi = `${process.env.REACT_APP_BACKEND_URL}/api/data/metrics/scorecard/by_user`;
const toastOptions: ToastOptions = { position: 'bottom-left' };

type TableData = {
  columns: IColumn[];
  rows: IRow[];
};

const Scorecard: React.FC<IProps> = (props: IProps) => {
  const { setPartition, clearContext } = useHeader();

  const {
    filters,
    persistModalParams,
    viewBy,
    companyCurrency,
    isManager,
    tabPartition,
  } = props;

  const [dataStatus, setDataStatus] = useState<QueryStatus>('notAsked');
  const [tableData, setTableData] = useState<TableData>({
    columns: [],
    rows: [],
  });
  const [sortOrder, setSortOrder] = useState('');

  const appliedFilters = {
    ...filters,
    rollup_by: viewBy,
    sort_by: sortOrder,
  };

  const serializedQueryParams = JSON.stringify(appliedFilters);

  const processApiResponse = (apiResponse: ApiResponse) => {
    const { columns, data, users } = apiResponse;
    const showManager = users.filter((e) => e.manager !== null).length > 0;
    const tableColumns = getColumns(
      columns,
      filters,
      persistModalParams,
      showManager,
      viewBy,
      companyCurrency
    );
    const rows = getRows(data, users, columns);
    setTableData({ columns: tableColumns, rows });
  };

  useEffect(() => {
    setPartition(tabPartition);

    return () => {
      clearContext();
    };
  }, []);

  useEffect(() => {
    if (!R.isEmpty(filters)) {
      const abortController = new AbortController();
      fetchApi<string, ApiResponse>({
        queryParams: serializedQueryParams,
        setData: (apiResponse: ApiResponse) => {
          processApiResponse(apiResponse);
        },
        setError: (error: string | null) =>
          toast.error(`Fetching table data failed: ${error}`, toastOptions),
        setStatus: setDataStatus,
        signal: abortController.signal,
        url: scorecardApi,
      });
    }
  }, [serializedQueryParams]);

  const handleEnabledColumnsChange = ({
    id,
    show,
  }: {
    id: string;
    show: boolean;
  }) => {
    const newColumns = tableData.columns.map((item) => {
      if (item.display_name === id) {
        return {
          ...item,
          hidden: !show,
        };
      }
      return item;
    });
    setTableData({ columns: newColumns, rows: tableData.rows });
  };

  const handleSort = useCallback((sortOrder?: string) => {
    setSortOrder(sortOrder || '');
  }, []);

  const displayedColumns = tableData.columns
    .filter((col) => col.field !== 'user')
    .map((column) => ({
      label: column.label,
      id: column.label,
      show: !column.hidden,
    }));

  const isLoading = ['notAsked', 'loading'].includes(dataStatus);

  return (
    <div className={classNames('wrapper', styles.wrapper)}>
      <div className={styles.dashboardFilters}>
        <ActivityDashboardFilters
          showSortBy={false}
          showViewBySwitch={isManager}
          activeTab="scorecard"
        />
      </div>
      <div className={styles.columnFilters}>
        <ColumnsControl
          displayedColumns={displayedColumns}
          onChange={handleEnabledColumnsChange}
        />
      </div>
      <div className={styles.content}>
        <Table
          title=""
          columns={tableData.columns}
          rowsPerPage={0}
          currentPage={0}
          data={tableData.rows}
          hideSearch
          hidePaginationEnd
          hidePaginationStart
          loading={isLoading}
          fullscreen
          innerScrollRef={undefined}
          columnsDnD={false}
          onSort={handleSort}
          sortOrder={sortOrder}
        />
      </div>
    </div>
  );
};

export default Scorecard;
