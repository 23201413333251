import classNames from 'classnames';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import * as R from 'ramda';
import React, { Component } from 'react';
import { Input, Checkbox } from 'semantic-ui-react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import CalendarGreen from 'assets/images/icons/calendar_small.svg';
// image
import TimeGreen from 'assets/images/icons/time_green.svg';
import AudioPlayer from 'components/UI/AudioPlayer';
import ShareArrowIcon from '../../assets/images/new_icon/share_arrow.svg';
import UserDefaultGreyIcon from '../../assets/images/user_default_grey.svg';
import * as service from 'components/UI/AudioPlayer/service';
// image
import BuButton, { BuControlSize } from 'components/UI/BuButton';
import BuIcon from 'components/UI/BuIcon';
import ShareCall from 'components/call-transcription/ShareCall';
import * as styles from 'components/call-transcription/styles';
import 'components/call-transcription/styles.css';
import TranscriptDetails from 'components/call-transcription/transcriptDetails';
import TranscriptSearchBar from 'components/call-transcription/transcriptSearchBar';
import { AnalyticsTracker } from 'components/common/analyticsUtils';
import { Link } from 'navigation/Link';

class Call extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShareOpen: false,
      newTag: '',
      topicsPage: 1,
      isHideInfo: false,
      isAutoScroll: true,
    };
    this.setActiveTopicFromSearch = this.setActiveTopicFromSearch.bind(this);
    this.onScroll = this.onScroll.bind(this);
  }

  componentDidMount() {
    const url = window.location.pathname + window.location.search;
    AnalyticsTracker.event(this.props, {
      category: 'Call Insights',
      action: 'Page Loaded',
      label: `${url}`,
    });
    AnalyticsTracker.setUserProperties(this.props);
    this.setActiveTopicFromSearch();
    const body = document.getElementById('body');
    body.addEventListener('scroll', _.throttle(this.onScroll, 200));
  }

  componentDidUpdate(prevProps) {
    const { call } = this.props;
    if (
      !R.equals(call, prevProps.call) &&
      R.pathOr(false, ['location', 'search'], this.props)
    ) {
      this.setActiveTopicFromSearch();
    }
  }

  componentWillUnmount() {
    const body = document.getElementById('body');
    body.addEventListener('scroll', this.onScroll);
  }

  onScroll() {
    if (!this.callsTable) return;
    const scrollHeightTop = this.callsTable.getBoundingClientRect().top;
    if (this.callsTable !== null && scrollHeightTop !== null) {
      if (scrollHeightTop < 350) {
        this.setState({
          isHideInfo: true,
        });
      }
      if (scrollHeightTop > 350) {
        this.setState({
          isHideInfo: false,
        });
      }
    }
  }

  setActiveTopicFromSearch() {
    const { call, location, setSearchText, doSearch } = this.props;
    if (R.pathOr(false, ['location', 'search'], this.props) && call) {
      const parsedSearch = queryString.parse(location.search);
      if (parsedSearch && parsedSearch.topic) {
        const topic = R.compose(
          R.find((el) => el.text === parsedSearch.topic),
          R.pathOr([], ['topics_discussed'])
        )(call);
        if (topic) {
          this.handleSelectTopic(topic.start_times, topic.text);
          service.setCurrentTime(topic.start_times[0]);
        } else {
          setSearchText(parsedSearch.topic);
          doSearch();
        }
      }
    }
  }

  clickOnPause = () => {
    const { updateState } = this.props;
    const audio = document.getElementById('music');
    updateState('isPlaying', false);
    audio.pause();
  };

  handleSelectTopic = (times, name) => {
    const { updateState, activeTopic } = this.props;
    if (activeTopic === name) {
      service.play();
    } else {
      updateState('selectedTopics', times);
      updateState('activeTopic', name);
      updateState('filteredSnippets', []);
      updateState('searchText', '');
    }
  };

  handleAddTag = () => {
    const { user, addTag, match } = this.props;
    const { newTag } = this.state;
    addTag(user.email, user.api_access_token, match.params.id, newTag);
    this.setState({ newTag: '' });
  };

  handleRemoveTag = (tag) => {
    const { user, removeTag, match } = this.props;
    removeTag(user.email, user.api_access_token, match.params.id, tag);
  };

  handleClose = () => {
    const { previousRoute, goBack } = this.props;
    goBack(previousRoute);
  };

  setTableRef = (element) => {
    this.callsTable = element;
  };

  changeAutoScroll = () => {
    const { isAutoScroll } = this.state;
    this.setState({
      isAutoScroll: !isAutoScroll,
    });
  };

  render() {
    const {
      user,
      call,
      currentTime,
      isTranscriptOpen,
      updateState,
      isPlaying,
      activeTopic,
      previousSection,
    } = this.props;

    const { isHideInfo, topicsPage, newTag, isShareOpen, isAutoScroll } =
      this.state;
    const anonymousUser = user.email === '';

    const topicsBtnClasses = classNames({
      'callTranscript__buttons-box-item': true,
      active: isTranscriptOpen === 'Topics',
    });

    const transcriptBtnClasses = classNames({
      'callTranscript__buttons-box-item': true,
      active: isTranscriptOpen === 'Competitors',
    });

    const competitorsBtnClasses = classNames({
      'callTranscript__buttons-box-item': true,
      active: isTranscriptOpen === 'Transcript',
    });

    const isHideTagsblock = classNames({
      'callTranscript__tags-box-left': true,
      hiden: isHideInfo || anonymousUser,
    });

    const isTopSearchBlock = classNames({
      'callTranscript__tags-box-right': true,
      positionTop: isHideInfo,
    });

    const removeMargin = classNames({
      'callTranscript__buttons-box': true,
      noMargin: isHideInfo,
    });

    const isHideTitle = classNames({
      'container__top-callTranscript-title': true,
      hiden: isHideInfo,
    });

    const sortedCompetitorsDiscussed =
      call &&
      R.compose(
        R.dropLast(call.competitors_discussed.length - topicsPage * 10),
        R.reverse,
        R.sortBy(R.prop('mentions'))
      )(call.competitors_discussed);

    const sortedTopicsDiscussed =
      call &&
      R.compose(
        R.dropLast(call.topics_discussed.length - topicsPage * 50),
        R.reverse,
        R.sortBy(R.prop('mentions'))
      )(call.topics_discussed);
    if (!call) return <span />;
    return (
      <>
        <div ref={this.setTableRef} className="container__callTranscript">
          <div className="container__top-callTranscript">
            <div className="container__top-callTranscript-fixed">
              <div className="container__top-callTranscript-breadcrumb">
                {anonymousUser ? (
                  <div> &nbsp; </div>
                ) : (
                  <>
                    <BuButton
                      secondary
                      size={BuControlSize.REGULAR}
                      onClick={this.handleClose}
                    >
                      <BuIcon
                        name={BoostUpIcons.ChevronLeft}
                        className="container__top-callTranscript-icon-left"
                      />
                      <span>Back</span>
                    </BuButton>
                    <div className="container__top-callTranscript-breadcrump-info">
                      <span className="text-blue">
                        <Link url={{ scheme: '/calls' }}>
                          <span className="text-blue">{previousSection}</span>
                        </Link>
                        &nbsp;
                      </span>
                      /&nbsp; Call Insights
                    </div>
                  </>
                )}
              </div>
              <div className="top-callTranscript-header">
                <p className={isHideTitle}>{call.title}</p>
                <div className="top-callTranscript__box-buttons">
                  <BuButton
                    secondary
                    size={BuControlSize.REGULAR}
                    className="callTranscript__box-buttons-shared"
                    onClick={() =>
                      this.setState({
                        isShareOpen: true,
                      })
                    }
                  >
                    <img
                      className="callTranscript__box-buttons-shared-img"
                      src={ShareArrowIcon}
                      alt=""
                    />
                    <p>Share</p>
                  </BuButton>
                </div>
              </div>
              <div className="top-callTranscript__box">
                <div className="top-callTranscript__box-info">
                  {!_.isNull(call.opportunity_name) &&
                    !_.isNull(call.opportunity_id) && (
                      <span className="callTranscript__box-info-item">
                        <div className="header-info">
                          <p className="text-bold">Opportunity:</p>
                          <Link
                            key={call.opportunity_id}
                            url={{
                              scheme: '/opportunities/preview/:dealId',
                              params: { dealId: call.opportunity_id },
                            }}
                          >
                            <span className="text-blue">
                              {call.opportunity_name}
                            </span>
                          </Link>
                        </div>
                      </span>
                    )}

                  {!_.isNull(call.account_name) && !_.isNull(call.account_id) && (
                    <span className="callTranscript__box-info-item">
                      <div className="header-info">
                        <p className="text-bold">Account:</p>

                        <Link
                          key={call.account_id}
                          url={{
                            scheme: '/accounts/preview/:id',
                            params: { id: call.account_id },
                          }}
                        >
                          <span className="text-blue">{call.account_name}</span>
                        </Link>
                      </div>
                    </span>
                  )}

                  {!_.isNull(call.account_executive) && (
                    <span className="callTranscript__box-info-item">
                      <div className="header-info">
                        <p className="text-bold">AE:</p>
                        <p className="text-gray">{call.account_executive}</p>
                      </div>
                    </span>
                  )}
                  {!_.isNull(call.account_executive) && (
                    <span className="callTranscript__box-info-item">
                      <div className="header-info">
                        <p className="text-bold">Close Date:</p>
                        <p className="text-gray">
                          {moment(call.opportunity_close_date).format(
                            'DD MMM YYYY'
                          )}
                        </p>
                      </div>
                    </span>
                  )}
                </div>
              </div>
              <div className="top-callTranscript__icons_box">
                <span className="callTranscript__box-time-item">
                  <img
                    src={CalendarGreen}
                    alt="iconCalendarGreen"
                    className="box-info-item-icon"
                  />
                  <p className="box-info-item-text">
                    {moment(call.start).format('h:mm a, DD MMM YYYY')}
                  </p>
                </span>
                <span className="callTranscript__box-time-item">
                  <img src={TimeGreen} alt="" className="box-info-item-icon" />
                  <p className="box-info-item-text">
                    {Math.floor(call.duration / 60)}
                    {' minutes'}
                  </p>
                </span>
                <span className="callTranscript__box-time-item">
                  <p className="box-info-item-text">{`Attendees (${call.attendees.length})`}</p>
                </span>
              </div>
              <div className="participants">
                {!_.isNull(call.attendees) &&
                  _.filter(call.attendees, ['is_customer', true]).length !==
                    0 && (
                    <div className="participants-prospect">
                      <div className="blue-circle" />
                      <p>
                        {`Prospect (${
                          !_.isNull(call.attendees) &&
                          _.filter(call.attendees, ['is_customer', true]).length
                        }) :`}
                      </p>
                      {_.filter(call.attendees, ['is_customer', true]).map(
                        (el) => {
                          return (
                            <div key={el.email} className="participant">
                              <img
                                className="participant-user-icon"
                                src={UserDefaultGreyIcon}
                                alt="attendees icon"
                              />
                              <p className="text-gray">{el.name || el.email}</p>
                            </div>
                          );
                        }
                      )}
                    </div>
                  )}
                {!_.isNull(call.attendees) &&
                  _.filter(call.attendees, ['is_customer', false]).length !==
                    0 && (
                    <div className="participants-company">
                      <div className="orange-circle" />
                      <p>
                        {`Company (${
                          !_.isNull(call.attendees) &&
                          _.filter(call.attendees, ['is_customer', false])
                            .length
                        }) :`}
                      </p>
                      {_.filter(call.attendees, ['is_customer', false]).map(
                        (el) => {
                          return (
                            <div key={el.email} className="participant">
                              <img
                                className="participant-user-icon"
                                src={UserDefaultGreyIcon}
                                alt="attendees icon"
                              />
                              <p className="text-gray">{el.name || el.email}</p>
                              {/* <p className="text-bold">(VP Sales)</p> */}
                            </div>
                          );
                        }
                      )}
                    </div>
                  )}
              </div>

              <div className="callTranscript__player-box">
                <AudioPlayer />
              </div>
              {!_.isEmpty(call.topics_discussed) && (
                <div className="callTranscript__topics-box">
                  <div className="callTranscript__topics-name">Topics:</div>
                  {call.topics_discussed &&
                    call.topics_discussed.map((t) => (
                      <span
                        className="table-body-item-keywords_grey"
                        style={{ cursor: 'pointer' }}
                        key={t.text}
                        onClick={() =>
                          this.handleSelectTopic(t.start_times, t.text)
                        }
                      >
                        {`${t.text} (${t.mentions})`}
                      </span>
                    ))}
                </div>
              )}
              <div className="callTranscript__tags">
                <div className={isHideTagsblock}>
                  <div className="callTranscript__tags-custom-addBtn">
                    <Input
                      placeholder="Type"
                      className="callTranscript__tags-custom-input"
                      value={newTag}
                      onKeyDown={(e) => e.keyCode === 13 && this.handleAddTag()}
                      onChange={(e, { value }) =>
                        this.setState({ newTag: value })
                      }
                      data-testing="txt_field"
                    />
                    <button
                      type="button"
                      onClick={this.handleAddTag}
                      className="callTranscript__tags-custom-link"
                    >
                      Add Tag
                    </button>
                  </div>
                  <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {call.my_tags.map((tag) => (
                      <div className="callTranscript__tags-item" key={tag.text}>
                        <span>{tag.text}</span>
                        <button
                          type="button"
                          className="callTranscript__tags-item-close"
                          onClick={() => this.handleRemoveTag(tag.text)}
                        />
                      </div>
                    ))}
                    {call.other_tags.map((tag) => (
                      <div className="callTranscript__tags-item" key={tag.text}>
                        <span>{tag.text}</span>
                      </div>
                    ))}
                  </div>
                </div>
                <div className={isTopSearchBlock}>
                  <TranscriptSearchBar />
                </div>
              </div>

              <div className="callTranscript__buttons_row">
                <div className={removeMargin}>
                  <div className={styles.btnGroup}>
                    <div
                      className={classNames(styles.btnGroup_btn, {
                        active: isTranscriptOpen === 'Transcript',
                      })}
                      type="button"
                      onClick={() => {
                        updateState('isTranscriptionOpen', 'Transcript');
                        AnalyticsTracker.event(this.props, {
                          category: 'Call Insights',
                          action: 'Tab: Transcript',
                          label: 'Tab Opened',
                        });
                      }}
                    >
                      Transcript
                    </div>
                    <div
                      className={classNames(styles.btnGroup_btn, {
                        active: isTranscriptOpen === 'Topics',
                      })}
                      type="button"
                      onClick={() => {
                        updateState('isTranscriptionOpen', 'Topics');
                        AnalyticsTracker.event(this.props, {
                          category: 'Call Insights',
                          action: 'Tab: Topics',
                          label: 'Tab Opened',
                        });
                      }}
                    >
                      Topics
                    </div>
                    <div
                      className={classNames(styles.btnGroup_btn, {
                        active: isTranscriptOpen === 'Competitors',
                      })}
                      type="button"
                      onClick={() => {
                        updateState('isTranscriptionOpen', 'Competitors');
                        AnalyticsTracker.event(this.props, {
                          category: 'Call Insights',
                          action: 'Tab: Competitors',
                          label: 'Tab Opened',
                        });
                      }}
                    >
                      Competitors
                    </div>
                  </div>
                </div>
                {isTranscriptOpen === 'Transcript' && (
                  <Checkbox
                    label="Autoscroll"
                    className="callTranscript_autoscroll_checkbox"
                    onChange={this.changeAutoScroll}
                    checked={isAutoScroll}
                  />
                )}
              </div>
            </div>
            {isTranscriptOpen === 'Transcript' && (
              <div
                id="table_transcription"
                className="callTranscript__table-transcription"
              >
                <TranscriptDetails isAutoScroll={isAutoScroll} />
              </div>
            )}
            {isTranscriptOpen === 'Competitors' && (
              <div className="callTranscript__table">
                <ul className="callTranscript__table-header">
                  <li
                    style={{ paddingLeft: '45px' }}
                    className="callTranscript__table-header-item one"
                  >
                    TOPIC/PHRASE
                  </li>
                  <li className="callTranscript__table-header-item two">
                    # OF MENTIONS
                  </li>
                  <li className="callTranscript__table-header-item two">
                    INSIGHTS
                  </li>
                  <li className="callTranscript__table-header-item three">
                    KEYWORDS
                  </li>
                </ul>
                {call.competitors_discussed &&
                  sortedCompetitorsDiscussed.map((t) => {
                    const playingProgress =
                      (currentTime * 282.6) / call.duration;

                    return (
                      <ul
                        className={classNames({
                          'callTranscript__table-body': true,
                          active: t.text === activeTopic,
                        })}
                        key={t.text}
                      >
                        <li className="callTranscript__table-body-item one width">
                          <div>
                            {activeTopic === t.text && isPlaying === true ? (
                              <div
                                className="player-circle-box"
                                onClick={this.clickOnPause}
                                role="presentation"
                              >
                                <div className="player-circle">
                                  <svg
                                    className="circles"
                                    viewBox="0 0 110 110"
                                  >
                                    <circle
                                      r="45"
                                      cx="50%"
                                      cy="50%"
                                      fill="none"
                                      className="circle__first"
                                    />
                                    <circle
                                      r="45"
                                      cx="50%"
                                      cy="50%"
                                      transform="rotate(-90 55 55)"
                                      className="circle__second"
                                      style={{
                                        strokeDasharray: `${playingProgress} 282.6`,
                                      }}
                                    />
                                  </svg>
                                  {!isPlaying ? (
                                    <span className="circles--play" />
                                  ) : (
                                    <span className="circles--pause" />
                                  )}
                                </div>
                              </div>
                            ) : (
                              <button
                                type="button"
                                className="callTranscript__table-body-btn_new"
                                onClick={() => {
                                  this.handleSelectTopic(t.start_times, t.text);
                                  AnalyticsTracker.event(this.props, {
                                    category: 'Call Insights',
                                    action: 'Competitor Playing',
                                    label: `Topic name: ${t.text}`,
                                  });
                                }}
                              >
                                <span className="circles--play" />
                              </button>
                            )}
                          </div>

                          <span className="callTranscript__table-body-item-name-topic">
                            {t.text}
                          </span>
                        </li>
                        <li className="callTranscript__table-body-item column two">
                          <span className="table-body-item-indicator-title">
                            {t.mentions}
                            {' mentions'}
                          </span>
                        </li>
                        <li className="callTranscript__table-body-item two width">
                          <span className="table-body-item-Insights">
                            {t.insights}
                          </span>
                        </li>
                        <li className="callTranscript__table-body-item row three">
                          {t.keywords.map((k) => (
                            <span className="table-body-item-keywords" key={k}>
                              {k}
                            </span>
                          ))}
                        </li>
                      </ul>
                    );
                  })}
                <div className="table-body-btn">
                  {topicsPage * 10 < call.competitors_discussed.length && (
                    <button
                      type="button"
                      className="table-body-viewRest"
                      onClick={() =>
                        this.setState({
                          topicsPage: topicsPage + 1,
                        })
                      }
                    >
                      {`+ ${
                        call.competitors_discussed.length - topicsPage * 10
                      } more`}
                    </button>
                  )}
                </div>
              </div>
            )}
            {isTranscriptOpen === 'Topics' && (
              <div className="callTranscript__table">
                <ul className="callTranscript__table-header">
                  <li
                    style={{ paddingLeft: '47px' }}
                    className="callTranscript__table-header-item three"
                  >
                    TOPIC
                  </li>
                  <li className="callTranscript__table-header-item three">
                    # OF MENTIONS
                  </li>
                  {/* <li className="callTranscript__table-header-item two">INSIGHTS</li> */}
                  <li className="callTranscript__table-header-item three">
                    KEYWORDS
                  </li>
                </ul>
                {call.topics_discussed &&
                  sortedTopicsDiscussed.map((t) => {
                    const playingProgress =
                      (currentTime * 282.6) / call.duration;

                    return (
                      <ul
                        className={classNames({
                          'callTranscript__table-body': true,
                          active: t.text === activeTopic,
                        })}
                        key={t.text}
                      >
                        <li className="callTranscript__table-body-item three width">
                          {activeTopic === t.text && isPlaying === true ? (
                            <div
                              role="presentation"
                              className="player-circle-box"
                              onClick={this.clickOnPause}
                            >
                              <div className="player-circle">
                                <svg className="circles" viewBox="0 0 110 110">
                                  <circle
                                    r="45"
                                    cx="50%"
                                    cy="50%"
                                    fill="none"
                                    className="circle__first"
                                  />
                                  <circle
                                    r="45"
                                    cx="50%"
                                    cy="50%"
                                    transform="rotate(-90 55 55)"
                                    className="circle__second"
                                    style={{
                                      strokeDasharray: `${playingProgress} 282.6`,
                                    }}
                                  />
                                </svg>
                                {!isPlaying ? (
                                  <span className="circles--play" />
                                ) : (
                                  <span className="circles--pause" />
                                )}
                              </div>
                            </div>
                          ) : (
                            <button
                              type="button"
                              className="callTranscript__table-body-btn_new"
                              onClick={() => {
                                this.handleSelectTopic(t.start_times, t.text);
                                AnalyticsTracker.event(this.props, {
                                  category: 'Call Insights',
                                  action: 'Topic Playing',
                                  label: `Topic name: ${t.text}`,
                                });
                              }}
                            >
                              <span className="circles--play" />
                            </button>
                          )}

                          <span className="callTranscript__table-body-item-name-topic">
                            {t.text}
                          </span>
                        </li>
                        <li className="callTranscript__table-body-item column three">
                          <span className="table-body-item-indicator-title">
                            {t.mentions}
                            {' mentions'}
                          </span>
                        </li>
                        <li className="callTranscript__table-body-item row three">
                          {t.keywords.map((k) => (
                            <span className="table-body-item-keywords" key={k}>
                              {k}
                            </span>
                          ))}
                        </li>
                      </ul>
                    );
                  })}
                <div className="table-body-btn">
                  {topicsPage * 50 < call.topics_discussed.length && (
                    <button
                      type="button"
                      className="table-body-viewRest"
                      onClick={() =>
                        this.setState({
                          topicsPage: topicsPage + 1,
                        })
                      }
                    >
                      {`+ ${
                        call.topics_discussed.length - topicsPage * 50
                      } more`}
                    </button>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        <ShareCall
          isOpen={isShareOpen}
          callDetail={call}
          handleCloseModal={() => this.setState({ isShareOpen: false })}
          callId={R.path(['props', 'match', 'params', 'id'], this)}
        />
      </>
    );
  }
}

Call.defaultProps = {
  activeTopic: PropTypes.string,
  call: PropTypes.object,
  location: PropTypes.object,
  setSearchText: PropTypes.func,
  doSearch: PropTypes.func,
  updateState: PropTypes.func,
  user: PropTypes.object,
  addTag: PropTypes.func,
  match: PropTypes.object,
  removeTag: PropTypes.func,
  previousRoute: PropTypes.symbol,
  goBack: PropTypes.symbol,
  currentTime: PropTypes.number,
  isTranscriptOpen: PropTypes.bool,
  isPlaying: PropTypes.bool,
  previousSection: PropTypes.string,
};

Call.propTypes = {
  activeTopic: PropTypes.string,
  call: PropTypes.shape({
    attendees: PropTypes.array,
    audio_url: PropTypes.string,
    competitors_discussed: PropTypes.array,
    description: PropTypes.string,
    duration: PropTypes.number,
    id: PropTypes.string,
    my_tags: PropTypes.array,
    opportunity_name: PropTypes.string,
    other_tags: PropTypes.array,
    segments: PropTypes.array,
    start: PropTypes.string,
    title: PropTypes.string,
    topics_discussed: PropTypes.array,
  }),
  location: PropTypes.shape({
    hash: PropTypes.string,
    key: PropTypes.string,
    pathname: PropTypes.string,
    search: PropTypes.string,
  }),
  setSearchText: PropTypes.func,
  doSearch: PropTypes.func,
  updateState: PropTypes.func,
  user: PropTypes.shape({
    confirmed_password: '',
    email: PropTypes.string,
    name: PropTypes.string,
    password: PropTypes.string,
    registrationToken: PropTypes.string,
    rememberMe: PropTypes.bool,
    role: PropTypes.string,
  }),
  addTag: PropTypes.func,
  match: PropTypes.shape({
    isExact: PropTypes.bool,
    params: PropTypes.object,
    path: PropTypes.string,
    url: PropTypes.string,
  }),
  removeTag: PropTypes.func,
  previousRoute: PropTypes.string,
  goBack: PropTypes.func,
  currentTime: PropTypes.number,
  isTranscriptOpen: PropTypes.string,
  isPlaying: PropTypes.bool,
  previousSection: PropTypes.string,
};

export default Call;
