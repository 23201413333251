import styled from '@emotion/styled';
import { css } from 'emotion';
import * as R from 'ramda';
import React, { useCallback, useState, useMemo } from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';

import BuButton from 'components/UI/BuButton';
import BuCheckbox from 'components/UI/BuCheckbox';
import TableControls from 'components/UI/TableConfig/TableControls';
import {
  ACTIONS_COLUMN_DEFAULT_WIDTH,
  isActionsColumn,
} from 'components/UI/TableConfig/column-helper';
import {
  TableOwnProps,
  ShowOrHideColumn,
} from 'components/UI/TableConfig/types';
import Scrolling from 'components/UI/common/Scrolling/Scrolling';
import TypedTable, {
  BorderType,
  TableDetails,
  IColumn,
} from 'components/UI/common/TypedTable/TypedTable';

const TABLE_CONTROLS_HEIGHT = 60;

const formatDisplayedColumn = (
  key: number,
  el: IColumn,
  id: string,
  getShowOrHideColumns: ShowOrHideColumn[]
) => {
  const item = getShowOrHideColumns.find((i) => i.id === id);

  return {
    label: el.label,
    id,
    show: item ? item.show : true,
    fixed_order: key === 0,
    field: el.field,
    advance: el.advance,
  };
};

const buttonsContainer = css`
  display: flex;
  justify-content: flex-end;
  margin: 20px 20px 20px 0;
`;

const FlexBoxFixHeightContainer = styled.div`
  height: 100%;
  min-height: 0;
`;

const TableMessage = styled.div`
  font-size: 16px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
TableMessage.defaultProps = {
  className: 'bu-font-default',
};

export const Table: React.FC<TableOwnProps> = ({
  columns = [],
  columnsDnD = false,
  controls,
  leftControls,
  currentPage,
  data = [],
  dataType,
  downloadButton,
  draftData = [],
  emptyTableMessage,
  extraHeader,
  fixColumns,
  fullscreen = false,
  hidePaginationEnd = false,
  hidePaginationStart = false,
  hideSearch = false,
  innerScrollRef,
  isIncludedDeals,
  isModal = false,
  loading = false,
  onChange = () => {},
  onDraftChange = () => {},
  onIncludeAllDeals = () => {},
  onIncludedDealsChange,
  onPaginationChange = () => {},
  onRender = () => {},
  onSearchChange = () => {},
  onSelect,
  onSort = () => {},
  onCloseModal,
  onRefresh = () => {},
  onlyBorderTop = false,
  paginationOptions,
  renderTitleExtra,
  rowClassName,
  rowsPerPage,
  searchPlaceholder,
  selectable,
  selectedCount,
  selectedRowsButtons,
  showActionButtons,
  showTotalResultsNextToPagination,
  showColumnsVisibilityToggle,
  showOrHideColumns = [],
  sortOrder = '',
  filterIncluded,
  filterIncludedOnChange,
  statuses = {},
  title,
  totalAmount,
  showTotalAmount,
  totalCount = 0,
  unfilteredTotalCount = 0,
  enableReorderingRows,
  stickyColumn = true,
  hideColumnHeader = false,
  canDragRow,
  onDrop,
  stickyColumnRollUps,
  showOppSplitToggle = false,
  hasActionsColumn,
  hasRefreshButton = false,
  width,
  className,
}: TableOwnProps) => {
  const [shadowTopOffset, setShadowTopOffset] = useState(0);
  const [shadowLeftOffset, setShadowLeftOffset] = useState(0);

  const actionColumn = useMemo(
    () =>
      columns instanceof Array
        ? columns.find((c: IColumn) => isActionsColumn(c))
        : R.map(([key, column]) => column, R.toPairs(columns))?.find(
            (c: IColumn) => isActionsColumn(c)
          ),
    [columns]
  );

  const actionColumnWidth =
    actionColumn?.width ??
    actionColumn?.minWidth ??
    ACTIONS_COLUMN_DEFAULT_WIDTH;

  const handleTableDetails = useCallback(
    (details: TableDetails) => {
      if (stickyColumn && details.columns && details.columns[0]) {
        setShadowLeftOffset(
          hasActionsColumn
            ? details.columns[1].width +
                details.columns[0].width +
                (selectable || enableReorderingRows ? 40 : 0)
            : details.columns[0].width
        );
        setShadowTopOffset(
          extraHeader
            ? details.columns[0].height + 36
            : details.columns[0].height
        );
      }

      onRender(details);
    },
    [hasActionsColumn, selectable, enableReorderingRows]
  );

  const tableControlTop =
    !title && hidePaginationStart && hideSearch && !controls
      ? 0
      : TABLE_CONTROLS_HEIGHT;

  const includeAllDealsCheckbox = useMemo(() => {
    const hasDisabledCheckboxes =
      (data[0]?.include_exclude_disabled as boolean) || false;

    const isCheckedAll =
      unfilteredTotalCount === data.length &&
      !data.some((item) => !item.included_in_submission);

    if (data.length && isIncludedDeals && !hasDisabledCheckboxes) {
      return (
        <div
          className={css`
            width: 130px;
          `}
        >
          <BuCheckbox
            label="Include all deals"
            disabled={!data.length || hasDisabledCheckboxes}
            checked={isCheckedAll}
            onChange={() => onIncludeAllDeals(!isCheckedAll)}
          />
        </div>
      );
    }

    return null;
  }, [JSON.stringify(data), unfilteredTotalCount]);

  const handleIncludedDealsSave = () => {
    if (onIncludedDealsChange) {
      const excludedDealsIds = data
        .filter((item) => !item.included_in_submission)
        .map((item) => item.id);

      const includedDealsIds = data
        .filter((item) => item.included_in_submission)
        .map((item) => item.id);

      onIncludedDealsChange(includedDealsIds, excludedDealsIds);
    }

    if (onCloseModal) {
      onCloseModal();
    }
  };

  const tableControlBottom = hidePaginationEnd
    ? 0
    : TABLE_CONTROLS_HEIGHT + 10; /* margin top */

  const displayedColumns = useMemo(
    () =>
      columns instanceof Array
        ? columns.map((el, key) =>
            formatDisplayedColumn(key, el, el.id as string, showOrHideColumns)
          )
        : R.map(
            ([key, value]) =>
              formatDisplayedColumn(
                parseInt(key),
                value,
                (value.id || key) as string,
                showOrHideColumns
              ),
            R.toPairs(columns)
          ),
    [JSON.stringify(columns), JSON.stringify(showOrHideColumns)]
  );

  return (
    <FlexBoxFixHeightContainer data-testing="table-height-container">
      {tableControlTop > 0 && (
        <TableControls
          isModal={isModal}
          totalAmount={totalAmount}
          showTotalAmount={showTotalAmount}
          dataType={dataType}
          fullscreen={fullscreen}
          includeAllDealsCheckbox={includeAllDealsCheckbox}
          hidePagination={hidePaginationStart}
          hideSearch={hideSearch}
          onPageChange={onPaginationChange}
          onSearch={onSearchChange}
          pageNumber={currentPage}
          pageSize={rowsPerPage}
          onRefresh={onRefresh}
          paginationOptions={paginationOptions}
          searchPlaceholder={searchPlaceholder}
          title={title}
          renderTitleExtra={renderTitleExtra}
          totalCount={totalCount}
          downloadButton={downloadButton}
          controls={controls}
          selectedRowsCount={
            selectable
              ? selectedCount || data.filter((item) => item[selectable]).length
              : false
          }
          selectedRowsButtons={selectedRowsButtons}
          displayedColumns={displayedColumns}
          showColumnsVisibilityToggle={showColumnsVisibilityToggle}
          isIncludedDealsTable={isIncludedDeals}
          filterIncluded={filterIncluded}
          filterIncludedOnChange={filterIncludedOnChange}
          leftControls={leftControls}
          showOppSplitToggle={showOppSplitToggle}
          isTableDataLoading={loading}
          hasRefreshButton={hasRefreshButton}
          showTotalResultsNextToPagination={showTotalResultsNextToPagination}
        />
      )}

      <TypedTable.Border
        height={`calc(100% - ${tableControlTop + tableControlBottom}px)`}
        borders={fullscreen || onlyBorderTop ? BorderType.TOP : BorderType.ALL}
      >
        <Dimmer.Dimmable
          dimmed={loading}
          style={{ height: '100%', overflowY: 'visible' }}
        >
          <Dimmer active={loading} inverted>
            <Loader id="loading_spinner" />
          </Dimmer>
          <Scrolling
            alwaysShow={fullscreen}
            height="100%"
            shadowLeftOffset={shadowLeftOffset}
            shadowTopOffset={shadowTopOffset}
            shadows
            width
            innerScrollRef={innerScrollRef}
          >
            <TypedTable
              columns={columns}
              columnsDnD={columnsDnD}
              data={data}
              draftData={draftData}
              emptyTableMessage={emptyTableMessage}
              extraHeader={extraHeader}
              fixColumns={fixColumns}
              fullscreen={fullscreen}
              isModal={isModal}
              loading={loading}
              width={width}
              minWidth="100%"
              onChange={onChange}
              onDraftChange={onDraftChange}
              onRender={handleTableDetails}
              onSelect={onSelect}
              onSort={onSort}
              rowClassName={rowClassName}
              rowStatus={statuses}
              selectable={selectable}
              sorting={sortOrder}
              stickyColumn={stickyColumn}
              stickyColumnRollUps={stickyColumnRollUps}
              stickyHeader
              hideColumnHeader={hideColumnHeader}
              enableReorderingRows={enableReorderingRows}
              canDragRow={canDragRow}
              onDrop={onDrop}
              hasActionsColumn={hasActionsColumn}
              actionColumnWidth={actionColumnWidth}
              className={className}
            />
            {displayedColumns.filter((item) => item.show).length === 0 &&
              !loading &&
              data.length > 0 && (
                <TableMessage>Please pick at least one column</TableMessage>
              )}
          </Scrolling>
        </Dimmer.Dimmable>
      </TypedTable.Border>

      {!hidePaginationEnd && (
        <TableControls
          fullscreen={fullscreen}
          hideSearch
          isModal={isModal}
          onPageChange={onPaginationChange}
          pageNumber={currentPage}
          pageSize={rowsPerPage}
          paginationOptions={paginationOptions}
          totalAmount={totalAmount}
          totalCount={totalCount}
          onRefresh={onRefresh}
        />
      )}
      {isIncludedDeals && (
        <div className={buttonsContainer}>
          {showActionButtons && (
            <>
              <BuButton
                secondary
                className={css`
                  margin-right: 20px !important;
                `}
                onClick={onCloseModal}
              >
                Cancel
              </BuButton>
              <BuButton onClick={handleIncludedDealsSave}>Done</BuButton>
            </>
          )}
        </div>
      )}
    </FlexBoxFixHeightContainer>
  );
};

export default Table;
