import axios from 'axios';

import {
  DataDescriptor,
  BIMetricsFilter,
  BIWidget,
  MetricDisplayInfo,
  BIWidgetMetadata,
} from 'components/dashboard/Metrics/metrics.types';

const WIDGET_DATA_URL = `/rev_bi/v3/widgets`;

/**
 * Combines the BIWidget configuration with additional properties
 * to manage the data fetching strategy for a widget that supports lazy loading of grouped / pivoted data.
 */
export type GetWidgetDataBody = BIWidget & {
  /**
   * Specifies the columns used to group the data. If you want lazy loading applied, initially, this list
   * should contain only the first pivot. As users expand a pivot, the item should be
   * updated to reflect the next level of data grouping to fetch.
   */
  group_by: DataDescriptor[];

  /**
   * These filters are used to narrow down the results when fetching data for expanded pivots,
   * ensuring that only relevant data is retrieved.
   */
  pivot_filters?: BIMetricsFilter[];

  /**
   * Determines the next pivot to expand. It includes the metadata required to render
   * the expansion option in the UI and to fetch the corresponding grouped data.
   * This property signals to the front end which plus icon should be displayed for expansion.
   */
  pivot_to_expand?: DataDescriptor;
};

type HierarchicalWidgetNodeMetadata = {
  /**
   * This property is specifically used when the node represents a username pivot,
   * and it is expected to contain the full hierarchy when asked as it does not support batching.
   */
  children?: HierarchicalWidgetNode[];

  /**
   * A boolean flag indicating whether the current node can be expanded to show sub-pivots.
   * This should be true if there are additional levels of data beneath the node that can be
   * lazily loaded on user request.
   */
  pivot_is_expandible?: boolean;

  /**
   * An array of HierarchicalWidgetNode that contains the children of the current node for any pivot type.
   * This array is used for on-demand batch loading of nodes, meaning it will be empty if batch loading is enabled
   */
  pivots?: HierarchicalWidgetNode[];

  /**
   * A boolean flag indicating whether the current node represent a loading state for its parent
   */
  isLoadingNode?: boolean;
};

// Actually the values can be only 'string | number' but
// if we type it this way, then the known properties won't
// satisfy the type, and produce an error
type HierarchicalWidgetNodeValues = Record<string, any>;

export type HierarchicalWidgetNode = HierarchicalWidgetNodeValues &
  HierarchicalWidgetNodeMetadata;

export interface WidgetColumnConfiguration {
  column_highlight_color?: string;
  display_name: string;
  field_name: string;
  type: string;
}

// this extension is because i need to map the table name to its icon
// in the future we need to think to remove this and use only eh PivotDescriptor
export interface WidgetPivotConfiguration extends WidgetColumnConfiguration {
  table_name: string;
}

export interface WidgetMetricConfiguration extends WidgetColumnConfiguration {
  displayConfig?: MetricDisplayInfo;
  metadata?: BIWidgetMetadata;
}

export interface ErrorDataStructure {
  field_name: string;
  message: string;
}
export interface WidgetColumnErrorConfiguration {
  [key: string]: ErrorDataStructure[];
}

export interface GetWidgetResponse {
  data: HierarchicalWidgetNode[];
  // TODO evaluate if this property still makes sense
  // We only need this property to know the field_name for the pivot in the data
  // But we already have the info on the widget group_by with a slightly different name
  // for example it changes from user.name to user$name
  pivot_columns: WidgetColumnConfiguration[];
  metric_columns: WidgetColumnConfiguration[];
  errors: WidgetColumnErrorConfiguration;
  // date of the cache creation
  updated_at: string;
}

export type WidgetType = 'table' | 'chart';
export type WidgetAction = 'published' | 'edition';

const getURL = (type: WidgetType, action: WidgetAction) => {
  const statusSegment = action === 'edition' ? '/editor' : '';
  return `${WIDGET_DATA_URL}${statusSegment}/${type}/data`;
};

export const getWidgetData = async (
  payload: GetWidgetDataBody,
  urlQuery?: Record<string, string | undefined>,
  widgetType: WidgetType = 'table',
  widgetAction: WidgetAction = 'edition'
) => {
  const response = await axios.post<GetWidgetResponse>(
    getURL(widgetType, widgetAction),
    payload,
    {
      params: urlQuery,
    }
  );

  return response.data;
};

export const cleanWidgetCache = async (
  widgetId: string,
  urlQuery?: Record<string, string | undefined>
) => {
  const response = await axios.delete<GetWidgetResponse>(
    `${WIDGET_DATA_URL}/refresh/${widgetId}`,
    {
      params: urlQuery,
    }
  );

  return response.data;
};
