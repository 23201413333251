import classnames from 'classnames';
import { push } from 'connected-react-router';
import { css } from 'emotion';
import React from 'react';

import TooltipWrapper from 'components/UI/common/TypedTable/renderers/common/TooltipWrapper';
import {
  tabs_item,
  tabs_item_wpar,
  tab_content_wrapper,
  oversizing_tab_item,
  oversizing_tab_tooltip_wrapper,
  oversizing_tab_tooltip_item,
} from 'components/dashboard/Tabs/styles';
import { useClickOutside } from 'components/hooks/useClickOutside';
import { Link } from 'navigation/Link';
import { Tabs } from 'navigation/tabs';

const oversizingTabsDropDownWrapper = css`
  margin-top: 10px;
  padding: 16px 6px;

  &::before {
    content: none !important;
  }
`;

const oversizingTabsWrapper = css`
  top: 12px;
  position: relative;
`;

interface IOversizingTabProps {
  oversizingTabs: Tabs;
  matchUrl: string;
}

const OversizingTab: React.FC<IOversizingTabProps> = ({
  oversizingTabs,
  matchUrl,
}) => {
  const { isOpen, setIsOpen, refElement } = useClickOutside();

  const isOversizingTabActive = oversizingTabs.some(
    ({ scheme }) => matchUrl == scheme
  );

  const tabs = (
    <div className={oversizing_tab_tooltip_wrapper}>
      {oversizingTabs.map(({ scheme, title }) => (
        <Link url={{ scheme }} key={scheme}>
          <div
            className={classnames(oversizing_tab_tooltip_item, {
              active: matchUrl === scheme,
            })}
          >
            {title}
          </div>
        </Link>
      ))}
    </div>
  );

  return (
    <TooltipWrapper
      tooltip={tabs}
      position="bottom left"
      popupClassName={oversizingTabsDropDownWrapper}
      isOpen={isOpen}
    >
      <div
        className={classnames(tabs_item_wpar, oversizingTabsWrapper)}
        ref={refElement}
      >
        <button
          className={classnames([tabs_item], oversizing_tab_item, {
            active: isOpen || isOversizingTabActive,
          })}
          onClick={() => setIsOpen(!isOpen)}
        >
          <div className={tab_content_wrapper}>
            {`${oversizingTabs.length} More`}
          </div>
        </button>
      </div>
    </TooltipWrapper>
  );
};

export default OversizingTab;
