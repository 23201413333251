import classNames from 'classnames';
import { css } from 'emotion';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from 'semantic-ui-react';

import { actions } from 'actions';
import InfoIcon from 'assets/fonts/boostup-icons/badge_info_outline.svg';
import {
  CHANGE_INTERVAL_OPTIONS,
  FILTER_NAME,
  TABLE_NAME,
} from 'common/constants';
import DealsTable from 'components/UI/DealsFlatTableConfig/DealsTable.container';
import OpenFiltersPanel from 'components/UI/OpenFiltersPanel';
import TooltipWrapper from 'components/UI/common/TypedTable/renderers/common/TooltipWrapper';
import { formatLabel } from 'components/charts/TrendsWaterfall/TrendsWaterfall.helper';
import { useLocalStorage } from 'components/hooks/useLocalStorage';
import { IProps } from 'components/modals/DealsModal/types';
import {
  getInheritedFilterValuesForModal,
  haveFiltersConfigurated,
} from 'reducers/filters/helpers';
import { getIsFiltersOnModalsEnabled, getUser } from 'selectors';
import { history } from 'store/configureStore';

const headerInfoIcon = css`
  display: inline-block;
  margin-left: 8px;

  img {
    width: 16px;
  }
`;

const timeIntervalContainer = css`
  color: #666666;
  margin: -2px 0 0 10px;
  font-size: 12px;
  line-height: 14px;
  font-family: var(--bu-font-regular);
`;

const tableWrapper = css`
  margin-left: -20px;
  margin-right: -20px;
  height: 80vh;
`;

const includedDealsTableWrapper = css`
  margin-left: -20px;
  margin-right: -20px;
`;

export const filtersContainer = css`
  margin-left: 20px;
  margin-right: 20px;
`;

export const modalFiltersContainer = css`
  height: 50px;
  background-color: var(--bu-gray-200);
  padding: 0 20px;
  display: contents;
`;

const tableContainerWithFilters = css`
  .container .container-dashboard {
    height: calc(80vh - 43px);
  }
`;

const tableContainerWithoutFilters = css`
  .container .container-dashboard {
    height: 80vh;
  }
`;

const DealsModal: React.FC<IProps> = ({
  modalProps,
  modalOptions,
  isIncludedDeals,
  closeModal,
  modalFilters,
}) => {
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const isFiltersOnModalsEnabled = useSelector(getIsFiltersOnModalsEnabled);
  const localStorageKey = modalOptions.localStorageKeyPrefix
    ? `${modalOptions.localStorageKeyPrefix}SortColumn:${user.email}`
    : '';

  const showFilters = modalProps.showFilters && isFiltersOnModalsEnabled;
  const hasFiltersConfigured = haveFiltersConfigurated(modalFilters || {});

  const [
    sortColumnLocalStorage,
    setSortColumnLocalStorage,
    getAndSetSortColumnLocalStorage,
  ] = useLocalStorage(localStorageKey, '');

  const parsedFilters = JSON.parse(modalProps.filters || '{}');

  const [filters, setFilters] = useState({
    ...parsedFilters,
    page_size: 50,
    page_number: 0,
  });
  const [sortColumn, setSortColum] = useState(sortColumnLocalStorage);

  const {
    apiUrl,
    context,
    id,
    isHistoricalSubmissionsModal,
    title,
    filterIncludedTogglerInitialState,
    isDealsDelta,
    overrideModalAmount,
    submissionSetting,
    showTotalAmount,
    onIncludedDealsChange,
    filterKeyForModal: filterKeyForModalOrUndefined,
    filtersHaveToInheritFilterValues: modalFilterHasToInheritFilterValues,
    haveToInheritUsersFilterValues: modalFilterHasToInheritUsersFilterValues,
    modalHeaderTooltip,
    openedFromWaterfall,
    origin,
  } = modalProps;

  const filterKeyForModal =
    filterKeyForModalOrUndefined || FILTER_NAME.ForecastOpportunitiesModal;

  const isAllDealsStages =
    modalFilters &&
    !!modalFilters.opportunity_stages &&
    modalFilters.opportunity_stages.length === 0;

  useEffect(() => {
    const value = getAndSetSortColumnLocalStorage(localStorageKey);
    setSortColum(value);
  }, [localStorageKey]);

  const inheritedFilterValuesForModal = useMemo(
    () =>
      modalFilters && modalFilterHasToInheritUsersFilterValues
        ? getInheritedFilterValuesForModal(modalFilters, {
            users: parsedFilters.users,
          })
        : modalFilters && modalFilterHasToInheritFilterValues
        ? getInheritedFilterValuesForModal(modalFilters, parsedFilters)
        : [],
    [modalProps.filters]
  );

  useEffect(() => {
    if (showFilters) {
      dispatch(
        actions.ui.filters.setForecastModalFilters({
          filters: inheritedFilterValuesForModal,
          tab: filterKeyForModal,
        })
      );
    }
  }, [inheritedFilterValuesForModal, showFilters]);

  useEffect(() => {
    if (showFilters) {
      setFilters({
        ...modalFilters,
        ids: parsedFilters.ids,
        ...(parsedFilters.business_type_name
          ? {
              business_type_name: parsedFilters.business_type_name,
            }
          : {}),
        ...(parsedFilters.opportunity_types
          ? {
              opportunity_types: parsedFilters.opportunity_types,
            }
          : {}),
        page_size: isIncludedDeals ? 0 : 50,
        page_number: 0,
        ...(isAllDealsStages && {
          opportunity_stages: ['__all_with_hidden__'],
        }),
        is_forecast: parsedFilters.is_forecast,
        change_interval: parsedFilters.change_interval,
        forecast_series: parsedFilters.forecast_series,
        now: parsedFilters.now,
        rollup_by: parsedFilters.rollup_by,
        skip_business_validation: parsedFilters.skip_business_validation,
        time_span: parsedFilters.time_span,
        use_beginning_of_period: parsedFilters.use_beginning_of_period,
        split_view: parsedFilters.split_view,
        include_disabled: parsedFilters.include_disabled,
        users: modalFilterHasToInheritUsersFilterValues
          ? modalFilters?.users || parsedFilters.users
          : modalFilters?.users,
      });
    } else {
      setFilters({
        ...parsedFilters,
        page_size: 50,
        page_number: 0,
      });
    }
  }, [modalFilters, showFilters, modalProps.filters]);
  const countFiltersParams = Object.keys(filters).length;
  if (isEmpty(modalProps) || (countFiltersParams <= 2 && !isIncludedDeals)) {
    return <Loader active />;
  }

  const handleRequestChange = (newRequest: Object) => {
    setFilters({
      ...newRequest,
      page_size: isIncludedDeals ? (newRequest as any).page_size : 50,
    });
  };

  let timeInterval = '';
  // Show time interval and badge only in forecast sections.
  if (
    (history.location.pathname.includes('/forecast') ||
      history.location.pathname.includes('/pipeline') ||
      history.location.pathname.includes('/dashboard')) &&
    filters.change_interval &&
    filters.change_interval.split
  ) {
    timeInterval = filters.change_interval
      ? CHANGE_INTERVAL_OPTIONS[filters.change_interval] ||
        moment(filters.change_interval.split(',')[0]).format('MM/DD/YYYY')
      : '';
  }

  const persistLocallyColumnSort = (column: string): void => {
    setSortColum(column);
    if (localStorageKey) {
      setSortColumnLocalStorage(column);
    }
  };

  const isDealsTable = openedFromWaterfall || isDealsDelta;
  const fromSankey =
    history.location.pathname.includes('pipeline/deals-progression') ||
    history.location.pathname.includes('dashboard/deals-progression');

  const extendedCSVDownload = !openedFromWaterfall && !fromSankey;
  const replacedTitle = formatLabel(String(title));

  // If you modify the layout remember to leave the data-testing attribute for automation
  return (
    <div
      className={isIncludedDeals ? includedDealsTableWrapper : tableWrapper}
      data-testing="deals-modal"
    >
      {showFilters && hasFiltersConfigured && (
        <div className={filtersContainer}>
          <OpenFiltersPanel tab={filterKeyForModal} isModal />
        </div>
      )}

      <div
        className={
          showFilters && hasFiltersConfigured
            ? tableContainerWithFilters
            : tableContainerWithoutFilters
        }
      >
        <DealsTable
          apiUrl={apiUrl}
          inModal
          initialRequest={{
            ...filters,
            table_name: isDealsTable // only for trends and for first chart
              ? TABLE_NAME.DealsDelta
              : undefined,
            sort: sortColumn,
          }}
          forecastTable={filters.is_forecast}
          persistName={`deals_modal_${apiUrl + title! + id}`}
          tableConfigCollection="opportunity"
          submissionSetting={submissionSetting}
          tableConfigName={
            isIncludedDeals
              ? TABLE_NAME.ForecastSubmissionSettings
              : isDealsTable // only for trends and for first chart
              ? TABLE_NAME.DealsDelta
              : TABLE_NAME.Opportunities
          }
          title={replacedTitle}
          dataType="Deals"
          isIncludedDeals={isIncludedDeals}
          filterIncludedTogglerInitialState={filterIncludedTogglerInitialState}
          isHistoricalSubmissionsModal={isHistoricalSubmissionsModal}
          onIncludedDealsChange={onIncludedDealsChange}
          onRequestChange={handleRequestChange}
          renderTitleExtra={() => (
            <>
              {modalHeaderTooltip && (
                <div className={classNames(headerInfoIcon)}>
                  <TooltipWrapper
                    tooltip={
                      <div style={{ width: '200px', textAlign: 'center' }}>
                        {modalHeaderTooltip}
                      </div>
                    }
                    position="top center"
                  >
                    <img src={InfoIcon} alt="info" />
                  </TooltipWrapper>
                </div>
              )}
              {timeInterval ? (
                <div className={classNames(timeIntervalContainer)}>
                  {context
                    ? `${context} changes over ${timeInterval}`
                    : `Changes since ${timeInterval}`}
                </div>
              ) : null}
            </>
          )}
          onCloseModal={closeModal}
          styleFirstColumn={false}
          forceBasicFilters={showFilters && hasFiltersConfigured}
          overrideAmount={overrideModalAmount}
          localStorageSortColumn={sortColumn}
          onSortChangePersistLocally={persistLocallyColumnSort}
          showTotalAmount={showTotalAmount}
          extendedCSVDownload={extendedCSVDownload}
          origin={origin}
        />
      </div>
    </div>
  );
};

export default DealsModal;
