import debounce from 'lodash/debounce';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Layout } from 'react-grid-layout';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Loader } from 'semantic-ui-react';

import * as metricActions from 'actions/revbi/metrics';
import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuButton from 'components/UI/BuButton';
import BuConfirmationPopup from 'components/UI/BuConfirmationPopup';
import BuDropdown, { BuDropdownItemContainer } from 'components/UI/BuDropdown';
import BuGroupButton from 'components/UI/BuGroupButton';
import BuIcon from 'components/UI/BuIcon';
import BuInput from 'components/UI/BuInput';
import { DashboardWidget } from 'components/dashboard/Metrics/Dashboard/DashboardWidget/DashboardWidget';
import { DashboardPlaceholder } from 'components/dashboard/Metrics/Dashboard/Placeholder/DashboardPlaceholder';
import {
  DashboardHeader,
  DashboardHeaderRow,
  DashboardHeaderRowControls,
  DashboardHeaderRowTitle,
  DashboardWidgetsContainer,
  LoaderContainer,
  MainContainer,
  TitleNotEditing,
  WidgetModalComponent,
  dashboardSettingContainer,
} from 'components/dashboard/Metrics/Dashboard/styles';
import { RevBiQuickView } from 'components/dashboard/Metrics/QuickView/RevBiQuickView';
import InteractiveGrid from 'components/dashboard/Metrics/common/InteractiveGrid/InteractiveGrid';
import { InteractiveLayout } from 'components/dashboard/Metrics/common/InteractiveGrid/InteractiveGrid.types';
import { AnalysisType } from 'components/dashboard/Metrics/constants';
import { RevBISettingsContext } from 'components/dashboard/Metrics/contexts/RevBISettingsContext';
import { SidebarType } from 'components/dashboard/Metrics/enums';
import {
  formatDashboardForSave,
  getLayoutForWidget,
  parseWidget,
} from 'components/dashboard/Metrics/metrics.helpers';
import {
  BIDashboard,
  BIDashboardSettings,
  BIMetricCreated,
  BIMetricsFilter,
  BIWidget,
} from 'components/dashboard/Metrics/metrics.types';
import {
  getMetricsList,
  getMetricsTSList,
  getWidgetsList,
  getWidgetsListStatus,
} from 'selectors/revbi/metrics';
import { QueryStatus, fetchApi } from 'utils/network';
import { DashboardFilters } from './DashboardFilters';

type InteractiveBIWidget = BIWidget &
  InteractiveLayout & { metric_list: BIMetricCreated[] };

const DASHBOARD_ROW_HEIGHT = 200;
const DASHBOARD_NUMBER_COLUMNS = 4;

interface Props {
  loadDashboardStatus: QueryStatus;
  selectedDashboard?: BIDashboard | undefined;
  selectedDashboardIdRef: { current: string };
  readOnly?: boolean;
  setSelectedDashboard: React.Dispatch<
    React.SetStateAction<BIDashboard | undefined>
  >;
  setDashboardList?: React.Dispatch<React.SetStateAction<BIDashboard[]>>;
  onRemove?: (id: string) => void;
  notClickableName?: boolean;
}

export const MetricsDashboard: React.FC<Props> = ({
  loadDashboardStatus,
  selectedDashboard,
  selectedDashboardIdRef,
  readOnly = false,
  setSelectedDashboard,
  setDashboardList,
  onRemove,
  notClickableName = false,
}) => {
  const dispatch = useDispatch();
  const { hideWidgetExploratoryView } = useContext(RevBISettingsContext);

  const match = useRouteMatch<{ dashboardId: string }>();
  const history = useHistory();

  const modalRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const debounceTime = useRef<ReturnType<typeof setTimeout>>();

  const [widgetToShow, setWidgetToShow] = useState<any>(undefined);

  const [isQuickViewOpen, setIsQuickViewOpen] = useState<boolean>(false);
  const [isEditingTitle, setEditingTitle] = useState<boolean>(false);

  const [deleteDashboardStatus, setDeleteDashboardStatus] =
    useState<QueryStatus>('notAsked');
  const [saveStatus, setSaveStatus] = useState<QueryStatus>('notAsked');

  const [isDelConfirmationOpen, setIsDelConfirmationOpen] =
    useState<boolean>(false);

  const [isModalWidgetShown, setIsModalWidgetShown] = useState<boolean>(false);

  const [inputName, setInputName] = useState<string>(
    selectedDashboard?.name || ''
  );

  // We will use a flag here to avoid refreshing the widgets on every filter change
  // but still save the dashboard on every change
  // this flag will change when the user clicks on the apply button and thus reloading
  // the widgets list useMemo. Not the best solution but working for now, could be refactored
  const [forceUpdate, setForceUpdate] = useState<boolean>(false);

  // selectors
  const liveMetrics = useSelector(getMetricsList);
  const historicalMetrics = useSelector(getMetricsTSList);
  const widgetList = useSelector(getWidgetsList);
  const loadWidgetListStatus = useSelector(getWidgetsListStatus);
  // selectors end

  const inputRef = useRef<HTMLInputElement>(null);
  const bottomRef = useRef<null | HTMLDivElement>(null);
  const widgetAddedRef = useRef<boolean>(false);

  const dashboardFilters = selectedDashboard?.dashboard_filters ?? [];

  useEffect(() => {
    if (widgetToShow) {
      dispatch(metricActions.updateWidget(parseWidget(widgetToShow)));
    }
  }, [JSON.stringify(widgetToShow)]);

  useEffect(() => {
    // switching dashboard cause the this effect to run. Checking the
    // selectedDashboardIdRef to prevent save being call when user is
    // just switching dashboard
    if (
      selectedDashboardIdRef &&
      selectedDashboardIdRef.current !== selectedDashboard?.id
    ) {
      selectedDashboardIdRef.current = selectedDashboard?.id ?? '';
    } else {
      if (selectedDashboard && !readOnly) {
        const abortController = new AbortController();
        fetchApi<BIDashboard, any>({
          url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/dashboards/${selectedDashboard?.id}`,
          queryMethod: 'put',
          queryParams: formatDashboardForSave(selectedDashboard),
          setError: (error: string | null) => {
            toast.error(`Failed to save dashboard: ${error}`);
          },
          setStatus: setSaveStatus,
          signal: abortController.signal,
        });

        return () => {
          abortController.abort();
        };
      }
    }
  }, [selectedDashboard, selectedDashboard?.widget_list]);

  useEffect(() => {
    if (!selectedDashboard?.name) {
      inputRef.current?.focus();
    }
  }, [selectedDashboard?.name]);

  useEffect(() => {
    if (widgetAddedRef.current && selectedDashboard?.widget_list?.length) {
      const [firstWidget, secondWidget] = selectedDashboard.widget_list;
      if (
        selectedDashboard.widget_list.length > 2 ||
        (selectedDashboard.widget_list.length > 1 &&
          ((firstWidget as BIWidget)?.analysis_type === AnalysisType.REPORT ||
            (secondWidget as BIWidget)?.analysis_type === AnalysisType.REPORT))
      ) {
        // scroll to bottom when widget is added
        bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
        widgetAddedRef.current = false;
      }
    }
  }, [selectedDashboard?.widget_list, widgetAddedRef.current]);

  const updateSelectedDashboardWithNewWidget = (newWidget: BIWidget): void => {
    const newWidgets = selectedDashboard?.widget_list
      ? [...selectedDashboard?.widget_list, newWidget]
      : [newWidget];

    setSelectedDashboard((selectedDashboard) => ({
      ...selectedDashboard,
      name: selectedDashboard?.name ?? '',
      widget_list: newWidgets,
      properties: {
        ...(selectedDashboard?.properties ?? {}),
      },
    }));
    widgetAddedRef.current = true;
  };

  const handleAddWidget = (widget: BIWidget): void => {
    // On add we need to load report view widget since it is missing metric info
    if (widget.analysis_type === AnalysisType.REPORT) {
      fetchApi<string, BIWidget>({
        url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/widgets/${widget._id}?expand=true`,
        queryMethod: 'get',
        setData: (result) => {
          updateSelectedDashboardWithNewWidget(result);
        },
        setError: (error: string | null) => {
          toast.error(`Failed to load widget ${widget._id}: ${error}`);
        },
      });
    } else if (widget.analysis_type === AnalysisType.FUNNEL) {
      updateSelectedDashboardWithNewWidget(widget);
    } else {
      const mList =
        widget.analysis_type === AnalysisType.LIVE
          ? liveMetrics
          : widget.analysis_type === AnalysisType.HISTORICAL
          ? historicalMetrics
          : [];

      const newMetricList =
        widget.metric_list?.reduce(
          (results: BIMetricCreated[], metricId: string) => {
            const m = mList.find((metric) => metricId === metric._id);
            if (m) {
              results.push(m);
            }

            return results;
          },
          []
        ) ?? [];

      const newWidget = {
        ...widget,
        metric_list: newMetricList,
      };

      updateSelectedDashboardWithNewWidget(newWidget);
    }
  };

  const handleRemoveWidget = (widgetId: BIWidget['id']): void => {
    const newWidgets = (selectedDashboard?.widget_list ?? []).filter(
      (widget: any) => widget._id !== widgetId
    );
    setSelectedDashboard((selectedDashboard) => ({
      ...selectedDashboard,
      name: selectedDashboard?.name ?? '',
      widget_list: newWidgets,
      properties: {
        ...(selectedDashboard?.properties ?? {}),
      },
    }));
  };

  const handleCloneWidget = (widgetId: string): void => {
    fetchApi<void, BIWidget>({
      url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/widgets/${widgetId}/clone`,
      queryMethod: 'get',
      setData: (result) => {
        const widget = parseWidget(result);
        dispatch(metricActions.addWidget(widget));
        handleAddWidget(widget);
        toast.success(`Metric "${widget?.name}" has been cloned`);
      },
      setError: (error: string | null) => {
        const widget = widgetList.find((widget) => widget?.id === widgetId);
        toast.error(`Failed to clone metric "${widget?.name}": ${error}`);
      },
    });
  };

  const handleCreateNewWidget = (): void => {
    const url = new URL(document.location.href);
    history.push(
      `/revbi/widgets/create?saveRedirect=${url.pathname}&addWidgetToDashboard=${selectedDashboard?.id}`
    );
  };

  const handleEditWidget = (widgetId: string): void => {
    history.push(
      `/revbi/widgets/edit/${widgetId}?saveRedirect=${history.location.pathname}`
    );
  };

  const handleDeleteDashboard = (): void => {
    const dashboardId = match.params.dashboardId;

    if (dashboardId) {
      fetchApi<void, any>({
        url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/dashboards/${dashboardId}`,
        queryMethod: 'delete',
        setData: () => {
          onRemove && onRemove(dashboardId);
          setSelectedDashboard(undefined);
          history.push('/revbi/dashboards/list');
        },
        setError: (error: string | null) => {
          console.error(`Failed to delete dashboard: ${error}`);
          toast.error(`Failed to delete dashboard: ${error}`);
        },
        setStatus: setDeleteDashboardStatus,
      });
    }
  };

  const handleSettingChanges = (settings: BIDashboardSettings): void => {
    if (selectedDashboard) {
      selectedDashboard.properties.settings = settings;
    }

    setSelectedDashboard((selectedDashboard) => ({
      ...selectedDashboard,
      name: selectedDashboard?.name ?? '',
      widget_list: selectedDashboard?.widget_list ?? [],
      properties: {
        ...(selectedDashboard?.properties ?? {}),
        settings,
      },
    }));
  };
  // TODO - why widget_list in this case can be a list of strings?
  // Refactor to use BIWidget type as is assumed as BIWidget on this component
  const widgetsForRender = (selectedDashboard?.widget_list || []) as BIWidget[];

  const addedWidgetIds: string[] = useMemo(
    () =>
      Array.from(
        new Set(
          (selectedDashboard?.widget_list as BIWidget[])?.reduce(
            (results: string[], item) => {
              if (item?._id) results.push(item._id);
              return results;
            },
            []
          )
        )
      ),
    [selectedDashboard?.widget_list]
  );

  const dashboardSettings = useMemo(
    () =>
      selectedDashboard?.properties?.settings || {
        userFilter: 'active',
      },
    [selectedDashboard?.properties?.settings]
  );

  const showWidgetModal = (widget: Partial<BIWidget>): void => {
    if (
      debounceTime.current ||
      (notClickableName && hideWidgetExploratoryView)
    ) {
      return;
    }
    containerRef.current?.classList.add('no-scroll');

    const widgetElement = widgetsForRender?.find(
      (widgetElement: BIWidget) => widget._id === widgetElement._id
    );
    const newWidgetToShow = {
      ...widget,
      metricsFromList: widgetElement?.metric_list ?? [],
    };
    applyModalAnimation('opened');
    setWidgetToShow(newWidgetToShow);
    setIsModalWidgetShown(true);
  };

  const closeWidgetModal = (): void => {
    modalRef.current?.classList.replace('opened', 'closing');
    applyModalAnimation('closing', 1450);
    setIsModalWidgetShown(false);
  };

  const applyModalAnimation = (actionClass: string, timer = 450): void => {
    if (debounceTime.current) {
      clearTimeout(debounceTime.current);
    }

    debounceTime.current = setTimeout(() => {
      modalRef.current?.classList.toggle(actionClass);
      if (actionClass === 'closing') {
        setWidgetToShow(undefined);
        debounceTime.current = undefined;
        containerRef.current?.classList.remove('no-scroll');
      }
    }, timer);
  };

  const isLegacyDashboard =
    selectedDashboard &&
    selectedDashboard?.properties.widgetLayout === undefined;

  const widgetsFetched = loadWidgetListStatus === 'success';

  const widgetsList = useMemo(() => {
    if (isLegacyDashboard === undefined || !widgetsFetched) {
      return [];
    }

    return widgetsForRender
      ?.map((widgetElement: BIWidget) => {
        const widget =
          widgetElement?.analysis_type === AnalysisType.REPORT
            ? widgetElement
            : widgetList.find(
                (widgetItem) => widgetItem._id === widgetElement._id
              );

        if (widget) {
          const layoutConfiguration = getLayoutForWidget(
            widget,
            selectedDashboard?.properties.widgetLayout || []
          );

          return {
            ...widget,
            metric_list: widgetElement.metric_list,
            isBounded: true,
            ...layoutConfiguration,
            dashboard_filters: dashboardFilters,
          };
        }
        return null;
      })
      .filter((widget) => !!widget) as InteractiveBIWidget[];
  }, [
    widgetList,
    widgetsForRender,
    selectedDashboard?.properties.widgetLayout,
    selectedDashboard?.properties?.settings?.userFilter,
    widgetsFetched,
    isLegacyDashboard,
    forceUpdate,
  ]);

  const onDashboardLayoutChange = (layout: Layout[]): void => {
    setSelectedDashboard((selectedDashboard) => {
      if (selectedDashboard) {
        return {
          ...selectedDashboard,
          name: selectedDashboard?.name ?? '',
          widget_list: selectedDashboard?.widget_list ?? [],
          properties: {
            ...(selectedDashboard?.properties ?? {}),
            widgetLayout: layout,
          },
        };
      }
    });
  };

  const updateDashboardName = useCallback(
    (value: string): void => {
      setSelectedDashboard((prev) =>
        prev ? { ...prev, name: value } : undefined
      );

      setDashboardList &&
        setDashboardList((prev) => {
          const dashboardList = [...prev];
          dashboardList.map((dashboard) => {
            if (dashboard.id === match.params.dashboardId) {
              dashboard.name = value;
            }
          });
          return dashboardList;
        });
    },
    [match.params.dashboardId]
  );

  const triggerChange = useCallback(
    debounce((value: string) => updateDashboardName(value), 750),
    [updateDashboardName]
  );

  const handleChangeDashboardName = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setInputName(e.target.value);
    triggerChange(e.target.value);
  };

  const [firstWidget] = widgetsList;
  const shouldRenderFullscreen = readOnly && widgetsList.length === 1;

  const someDataLoading =
    loadDashboardStatus === 'loading' || loadWidgetListStatus === 'loading';

  return (
    <MainContainer ref={containerRef} className={'main-dashboard'}>
      <DashboardHeader>
        {!shouldRenderFullscreen && (
          <DashboardHeaderRow>
            <DashboardHeaderRowTitle onClick={() => setEditingTitle(true)}>
              {(isEditingTitle || !inputName) && !readOnly ? (
                <BuInput
                  innerRef={inputRef}
                  autoFocus
                  disabled={!selectedDashboard}
                  placeholder="Dashboard Name"
                  type="text"
                  value={inputName}
                  onChange={handleChangeDashboardName}
                  onBlur={() => setEditingTitle(false)}
                  onFocus={() => setEditingTitle(true)}
                />
              ) : (
                <>
                  <TitleNotEditing>
                    <p>{selectedDashboard?.name}</p>
                    {!readOnly && (
                      <div className="icon-button">
                        <BuIcon name={BoostUpIcons.Pencil} />
                      </div>
                    )}
                  </TitleNotEditing>
                </>
              )}
            </DashboardHeaderRowTitle>
            <DashboardHeaderRowControls>
              {!readOnly && (
                <>
                  <BuButton
                    icon
                    secondary
                    borderless
                    tooltip="Delete dashboard"
                    disabled={
                      !selectedDashboard?.id ||
                      deleteDashboardStatus === 'loading'
                    }
                    onClick={() => {
                      setIsDelConfirmationOpen(true);
                    }}
                  >
                    <BuIcon name={BoostUpIcons.Trash} />
                  </BuButton>

                  <BuDropdown
                    label={<BuIcon name={BoostUpIcons.Settings} />}
                    secondary
                    borderless
                    icon={true}
                    noDropdownIcon
                  >
                    {(hide) => (
                      <BuDropdownItemContainer>
                        <div className={dashboardSettingContainer}>
                          <h5 className={'bu-font-sub-heading'}>
                            Filter Users
                          </h5>
                          <BuGroupButton
                            options={[
                              { id: 'active', text: 'Active users only' },
                              { id: 'all', text: 'All users' },
                            ]}
                            selectedOption={dashboardSettings.userFilter}
                            onSelect={(value) => {
                              handleSettingChanges({ userFilter: value });
                            }}
                          />
                        </div>
                      </BuDropdownItemContainer>
                    )}
                  </BuDropdown>

                  <BuButton
                    disabled={!selectedDashboard}
                    onClick={() => {
                      setIsQuickViewOpen(true);
                    }}
                  >
                    + Add Widget
                  </BuButton>
                </>
              )}
            </DashboardHeaderRowControls>
          </DashboardHeaderRow>
        )}

        <DashboardFilters
          dashboardId={selectedDashboard?.id}
          widgetsList={widgetsList}
          readOnly={readOnly}
          dashboardFilters={dashboardFilters}
          setDashboardList={(filters) => {
            setSelectedDashboard((prev) =>
              prev ? { ...prev, dashboard_filters: filters } : undefined
            );
          }}
          updateFilter={(filter: BIMetricsFilter) => {
            setSelectedDashboard((prev) => {
              const dashboardList = prev?.dashboard_filters || [];
              return prev
                ? {
                    ...prev,
                    dashboard_filters: dashboardList.map((elem) =>
                      elem.column.name === filter.column.name ? filter : elem
                    ),
                  }
                : undefined;
            });
          }}
          onApply={() => {
            setForceUpdate(!forceUpdate);
          }}
        />
      </DashboardHeader>

      <DashboardWidgetsContainer
        shouldRenderFullscreen={shouldRenderFullscreen}
      >
        {someDataLoading && (
          <LoaderContainer>
            <Loader active />
          </LoaderContainer>
        )}

        {!someDataLoading && widgetsList?.length > 0 && (
          <>
            {shouldRenderFullscreen ? (
              <div
                data-testing="interactive-item-0"
                style={{ width: '100%', height: '100%' }}
              >
                <DashboardWidget
                  key={firstWidget._id}
                  dashboardId={selectedDashboard?.id}
                  dashboardName={selectedDashboard?.name}
                  dashboardSettings={dashboardSettings}
                  widget={firstWidget}
                  onCloneWidget={readOnly ? undefined : handleCloneWidget}
                  onRemoveWidget={readOnly ? undefined : handleRemoveWidget}
                  onEditWidget={readOnly ? undefined : handleEditWidget}
                  onNameClicked={showWidgetModal}
                  setWidgetToShow={() => {}}
                />
              </div>
            ) : (
              <InteractiveGrid
                rowHeight={DASHBOARD_ROW_HEIGHT}
                columns={DASHBOARD_NUMBER_COLUMNS}
                items={widgetsList}
                compactType="vertical"
                onLayoutChange={onDashboardLayoutChange}
                isInteractive={!readOnly}
                renderItem={(widget) => {
                  return (
                    <DashboardWidget
                      key={widget._id}
                      dashboardId={selectedDashboard?.id}
                      dashboardName={selectedDashboard?.name}
                      dashboardSettings={dashboardSettings}
                      widget={widget}
                      onCloneWidget={readOnly ? undefined : handleCloneWidget}
                      onRemoveWidget={readOnly ? undefined : handleRemoveWidget}
                      onEditWidget={readOnly ? undefined : handleEditWidget}
                      setWidgetToShow={() => {}}
                      onNameClicked={showWidgetModal}
                    />
                  );
                }}
              />
            )}
          </>
        )}

        {loadDashboardStatus !== 'loading' &&
          (!selectedDashboard || selectedDashboard.widget_list.length === 0) &&
          !readOnly && (
            <DashboardPlaceholder
              buttonDisabled={!selectedDashboard}
              onAddWidgetClick={() => {
                setIsQuickViewOpen(true);
              }}
            />
          )}

        <div ref={bottomRef} />
      </DashboardWidgetsContainer>

      {isQuickViewOpen && (
        <RevBiQuickView
          isOpen={isQuickViewOpen}
          list={widgetList}
          selectedIds={addedWidgetIds}
          sidebarType={SidebarType.WIDGETS}
          onClose={() => setIsQuickViewOpen(false)}
          onAdd={handleAddWidget}
          onCreateNew={handleCreateNewWidget}
        />
      )}

      <BuConfirmationPopup
        cancelText="No"
        confirmText="Yes"
        headerText="Confirmation Required!"
        isOpen={isDelConfirmationOpen}
        onClose={() => {
          setIsDelConfirmationOpen(false);
        }}
        onConfirm={() => {
          handleDeleteDashboard();
          setIsDelConfirmationOpen(false);
        }}
      >
        {`Are you sure you want to delete "${selectedDashboard?.name}"? It will be permanently removed.`}
      </BuConfirmationPopup>

      {isModalWidgetShown && (
        <WidgetModalComponent
          scrollDistance={containerRef.current?.scrollTop}
          heightView={containerRef.current?.clientHeight}
          ref={modalRef}
        >
          <DashboardWidget
            key={`modalId_${widgetToShow._id}`}
            isReadOnly={readOnly}
            isDashboardModal
            dashboardId={selectedDashboard?.id}
            dashboardName={selectedDashboard?.name}
            dashboardSettings={dashboardSettings}
            widget={widgetToShow}
            setWidgetToShow={setWidgetToShow}
            onCloseWidgetModal={closeWidgetModal}
          />
        </WidgetModalComponent>
      )}
    </MainContainer>
  );
};
