import React, { FC, useMemo } from 'react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuIcon from 'components/UI/BuIcon';
import BuSelect from 'components/UI/BuSelect';
import { ISelectOption } from 'components/UI/BuSelect/types';
import {
  FilterColumnDropdownContainer,
  RemoveIconContainer,
} from 'components/dashboard/Metrics/Create/Conditions/Condition/ColumnSelector/styles';
import { FlexRow } from 'components/dashboard/Metrics/metrics.styles';
import { DataDescriptor } from 'components/dashboard/Metrics/metrics.types';

type Props = {
  column: DataDescriptor | undefined;
  filterColumns: DataDescriptor[];
  dataTesting?: string;
  onChangeColumn: (values: string[]) => void;
  onRemove: () => void;
};

export const ColumnSelector: FC<Props> = ({
  column,
  filterColumns,
  dataTesting = 'column',
  onChangeColumn,
  onRemove,
}) => {
  const options: ISelectOption[] = useMemo(
    () =>
      filterColumns
        .map((option) => {
          const fieldStructure = option.name.split('.');
          const entityName = fieldStructure.length > 1 ? fieldStructure[0] : '';
          const groupStructure = option.label.split(' - ');
          const text =
            groupStructure.length > 1 ? groupStructure[1] : groupStructure[0];
          const group = groupStructure.length > 1 ? groupStructure[0] : '';

          return {
            text,
            value: option.name,
            group,
            entityName,
          };
        })
        .sort(
          (a, b) =>
            (a.group?.localeCompare(b.group ?? '') ?? 0) ||
            a.text.localeCompare(b.text)
        ),
    [filterColumns]
  );

  return (
    <FlexRow>
      <FilterColumnDropdownContainer>
        <BuSelect
          fullWidth
          isLargePlaceholder
          searchable
          secondary
          className="select"
          defaults={[column?.name ?? '']}
          options={options}
          placeholder="Select a column"
          testingLabel={dataTesting}
          onChange={onChangeColumn}
        />
      </FilterColumnDropdownContainer>
      <RemoveIconContainer onClick={onRemove}>
        <BuIcon name={BoostUpIcons.Trash} />
      </RemoveIconContainer>
    </FlexRow>
  );
};
