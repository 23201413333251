import React, { Dispatch } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Action } from 'typescript-fsa';

import { actions } from 'actions';
import { formatMoney, formatNumber } from 'common/numbers';
import {
  IColumn,
  IRow,
  SortOrder,
} from 'components/UI/common/TypedTable/TypedTable';
import { ColumnTypes } from 'components/UI/common/TypedTable/renderers';
import { ClickableCellConfig } from 'components/UI/common/TypedTable/renderers/ClickableCell';

import { OpenRevBiDrilldownModal } from 'components/dashboard/Metrics/Widget/hooks/useRevBiDrilldownModal';
import {
  getDrilldownUrlQueryParams,
  handleOpenCustomObjectModal,
} from 'components/dashboard/Metrics/Widget/metrics.widget.helpers';
import { OPPORTUNITY_SPLIT } from 'components/dashboard/Metrics/constants';
import {
  BIMetricSimple,
  DrillDownParams,
  FormulaMetricValuesAsChartValue,
} from 'components/dashboard/Metrics/metrics.types';
import { PersistQueryParams } from 'navigation/types';
import { openModal } from 'navigation/utils';
import * as selectors from 'selectors';
import { fetchApi } from 'utils/network';

export const useTableColumnBuilder = (
  drilldownTableSettings: any,
  clickHandler: (id: IRow, drilldownTableSettings: any) => void
) => {
  const columns = React.useMemo(
    () => [
      {
        id: 'variable',
        label: 'Metric',
        field: 'metricName',
        type: ColumnTypes.TEXT,
        sort_order: SortOrder.ASCENDING,
        config: {},
      },
      {
        id: 'value',
        label: 'Value',
        field: 'value',
        type: ColumnTypes.CLICKABLE,
        sort_order: SortOrder.ASCENDING,
        drilldownTableSettings,
        config: {
          click(column: IColumn, row: IRow) {
            clickHandler(row, column.drilldownTableSettings);
          },
        } as ClickableCellConfig,
        align: 'right' as 'right',
        format: 'number',
      },
    ],
    [drilldownTableSettings, clickHandler]
  );

  return { columns };
};

const getFormattedValue = (
  value: string | number | undefined,
  type: string | undefined,
  companyCurrencyCode: string
) => {
  if (value === undefined) {
    return '-';
  }
  switch (type) {
    case 'money':
      return formatMoney(companyCurrencyCode, value as number);
    case 'number':
      return formatNumber(value as number);
    default:
      return value;
  }
};

export const useTableData = (
  metricValues: FormulaMetricValuesAsChartValue[],
  metrics: BIMetricSimple[],
  drilldownParams: DrillDownParams
) => {
  const companyCurrencyCode = useSelector(selectors.getUserLocalCurrency);

  const tableData = React.useMemo(() => {
    if (typeof metricValues !== 'undefined' && metrics.length > 0) {
      return metricValues.map((metric) => {
        const metricData = metrics.find((m) => m._id === metric.id);

        const metricValue = metric.value;
        const formattedValue = getFormattedValue(
          metricValue,
          metricData?.column?.type,
          companyCurrencyCode
        );

        return {
          id: metric.id,
          value: formattedValue,
          metricName: metric.metricName || metricData?.name,
          object: metricData?.object,
          drilldownParams: { ...drilldownParams, ...metricData }, // we need to pass the filters of the metric.
        };
      });
    } else {
      return [];
    }
  }, [metricValues, metrics, drilldownParams, companyCurrencyCode]);

  return { tableData };
};

export const useFetchMetrics = (
  metricValues: FormulaMetricValuesAsChartValue[]
) => {
  const [simpleMetricsUsed, setSimpleMetricsUsed] = React.useState<
    BIMetricSimple[]
  >([]);

  React.useEffect(() => {
    const simpleMetrics: BIMetricSimple[] = [];
    if (typeof metricValues === 'undefined') {
      return;
    }

    const metricsDataAsArray = metricValues.map(async (metric) => {
      if (typeof metric.id === 'string') {
        return await fetchApi<string, BIMetricSimple>({
          url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/metrics/${metric.id}`,
          queryMethod: 'get',
          setData: (metricData: BIMetricSimple) => {
            simpleMetrics.push(metricData);
          },
          setError: (error: string | null) => {
            toast.error(
              `Failed to load metric data ${metric.metricName}: ${error}`
            );
          },
        });
      }
    });

    Promise.all(metricsDataAsArray).then(() =>
      setSimpleMetricsUsed(simpleMetrics)
    );
  }, [metricValues]);

  return { simpleMetricsUsed };
};

const getApiPath = (object: string) => {
  switch (object) {
    case 'opportunity':
      return 'deals';
    case 'account':
      return 'accounts';
    case 'calendar_event':
      return 'events';
    case 'email':
      return 'emails';
    default:
      return null;
  }
};

const getModalPath = (object: string) => {
  switch (object) {
    case 'opportunity':
      return '/deals';
    case 'account':
      return '/accounts';
    case 'calendar_event':
      return '/events';
    case 'email':
      return '/emails';
    default:
      return '/deals';
  }
};

export const useOpenModalByMetricCallback = (
  dispatch: Dispatch<Action<PersistQueryParams>>,
  openRevBiDrilldownModal?: OpenRevBiDrilldownModal
) => {
  const handleMetricClick = React.useCallback(
    (row: any, drilldownTableConfig: any) => {
      if (
        Object.keys(drilldownTableConfig ?? {}).includes(row.object) &&
        openRevBiDrilldownModal &&
        drilldownTableConfig
      ) {
        const tableConfig = drilldownTableConfig[row.object];

        handleOpenCustomObjectModal(
          row.drilldownParams,
          row.object,
          row.metricName,
          tableConfig,
          openRevBiDrilldownModal
        );

        return;
      }

      if (row.object === OPPORTUNITY_SPLIT) {
        openModal({
          scheme: '/opportunity-split',
          persistParams: {
            apiUrl: `/api/settings/tables/opportunity_split/RevbiOpportunitySplit`,
            filters: [],
            title: 'metricName',
            showTotalAmount: false,
            metricObject: row.object,
            metricName: row.metricName,
            drilldownParams: row.drilldownParams,
          },
          persistor: (params: PersistQueryParams) => {
            dispatch(actions.ui.modal.persist(params));
          },
        });

        return;
      }

      const drilldownUrlQueryParams = getDrilldownUrlQueryParams(
        row.drilldownParams
      );

      fetchApi<any, { data: string[] }>({
        url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/drill_down/${row.object}${drilldownUrlQueryParams}`,
        queryMethod: 'post',
        queryParams: row.drilldownParams,
        setData: (result) => {
          const data = result.data ?? [];

          const apiPath = getApiPath(row.object as string);

          if (data.length && apiPath) {
            const popupTableParams = {
              apiUrl: `/api/data/${apiPath}/`,
              context: '',
              deltaOverPeriod: 0,
              filters: JSON.stringify({
                ids: data,
                skip_business_validation: true,
              }),
              title: '',
              origin: 'revbi',
            };

            openModal({
              scheme: getModalPath(row.object as string),
              persistParams: popupTableParams,
              persistor: (params: PersistQueryParams) => {
                dispatch(actions.ui.modal.persist(params));
              },
            });
          }
        },
        setError: (error: string | null) => {
          toast.error('Failed to load table data:' + error);
        },
      });
    },
    []
  );

  return { handleMetricClick };
};
