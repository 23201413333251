import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import * as R from 'ramda';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useContext,
} from 'react';
import { connect, useSelector } from 'react-redux';
import { toast, ToastOptions } from 'react-toastify';
import { Dimmer, Loader } from 'semantic-ui-react';

import { actions } from 'actions';
import * as cacheActions from 'actions/cacheActions';
import * as dealsActions from 'actions/dealsActions';
import * as forecastActions from 'actions/forecastActions';
import { mapChangesSinceDataForDeals } from 'common/forecastSubmission';
import { formatMoney } from 'common/numbers';
import ActiveUsersToggle from 'components/UI/ActiveUsersToggle';
import BuToggle from 'components/UI/BuToggle';
import BusinessTypesPanel from 'components/UI/BusinessTypesPanel';
import { getDeltaValue } from 'components/UI/DealsFlatTableTS/Table/Row/DeltaValue';
import { QueryParams } from 'components/UI/DealsFlatTableTS/Table/types';
import { DownloadButtonProps } from 'components/UI/DownloadButton/types';
import OpenFiltersPanel from 'components/UI/OpenFiltersPanel';
import Table from 'components/UI/TableConfig';
import { CUSTOM_EXTRA_TYPE } from 'components/UI/TableConfig/CustomDealNameCell';
import {
  ColumnTypesCallback,
  handleColumnClick,
} from 'components/UI/TableConfig/column-helper';
import {
  isColumnConfigSalesProcess,
  onReadyCallback,
  TableConfigurationData,
  TableConfigurationColumn,
} from 'components/UI/TableConfig/types';
import TwoColumnsDashboard from 'components/UI/TwoColumnsDashboard';
import { useHeader } from 'components/UI/Wrapper/Header/header.context';
import {
  IColumn,
  IRow,
  onChangeCallback,
  RowStatusType,
  ValueProp,
} from 'components/UI/common/TypedTable/TypedTable';
import { ColumnTypes } from 'components/UI/common/TypedTable/renderers';
import { getFieldValue } from 'components/UI/common/TypedTable/renderers/custom/common';
import { AnalyticsTracker } from 'components/common/analyticsUtils';
import SubmitForecast from 'components/dashboard/Forecast/Dashboard/SubmitForecast';
import ForecastTotalsPanel from 'components/dashboard/Forecast/ForecastTotalsPanel';
import MeddicSidePanel, {
  StepWithValue,
} from 'components/dashboard/Forecast/MeddicSidePanel/MeddicSidePanel';
import SidePanel from 'components/dashboard/Forecast/SidePanel';
import * as styles from 'components/dashboard/ForecastRollUps/styles';
import { IReduxState } from 'reducers/types';
import * as selectors from 'selectors';
import { FiltersForAPI, isFiltersLoaded } from 'selectors/openFilters';
import { getFeatureFlags, OVERALL_BUSINESS_TYPE } from 'selectors/settings';
import { history } from 'store/configureStore';
import { fetchApi, QueryStatus } from 'utils/network';

const apiPoint = `${process.env.REACT_APP_BACKEND_URL}/api/data/deals/`;
const toastOptions: ToastOptions = { position: 'bottom-left' };

const emptyTableMessage = [
  'For your filters there are 0 opportunities found that satisfy filter conditions.',
  'Change your filter selection or reset to default above to see opportunities.',
].join('\n');

type StateProps = {
  selectedBusinessType: string;
  companyCurrency: string;
  draftData?: IRow[];
  filters: FiltersForAPI;
  isAdmin: boolean;
  isMetricsExpanded: boolean;
  isSubmissionAvailable: boolean;
  persistedState?: QueryParams;
  selectedItem: string;
  settings: { crm_type?: string };
  statuses: RowStatusType;
  isAdminSFDCUpdateAllowed: boolean;
  isSubmissionLoaded: boolean;
  isOppSplitEnabled: boolean;
  isOverallEnabled: boolean;
  isReadOnlyUser: boolean;
  allDealDetailed: any;
  isRequiredDataReady: boolean;
  isMetricsBarEnabled: boolean;
  dealOutcomeEnabled: boolean;
  settingsLoaded: boolean;
};

type DispatchProps = {
  clearOnExit: Function;
  dispatch: Function;
  fetchForecastSubmission: Function;
  toggleFilters: Function;
  updateDraftData: Function;
  syncDeal(id: string): void;
};

type ChangeObject = Record<string, ValueProp>;

type MergeProps = {
  changeDeal(id: string, changes: ChangeObject, callback?: () => void): void;
};

type IProps = StateProps & Omit<DispatchProps, 'dispatch'> & MergeProps;

type Data = {
  count: number;
  deals: Deals.Deal[];
  changes_since?: string;
  sn: number;
  total_amount: number;
};
type ApiResponse = { data: Data };
type Panel = 'meddic' | 'submissions' | '';

const ALLOWED_PREDICTION_FILTER_VALUES = ['TWE', 'TQU', 'TMO'];

const NewForecast: React.FC<IProps> = ({
  selectedBusinessType,
  clearOnExit,
  companyCurrency,
  draftData,
  fetchForecastSubmission,
  filters,
  isAdmin,
  isSubmissionAvailable,
  isOppSplitEnabled,
  selectedItem,
  settings,
  statuses,
  updateDraftData,
  isSubmissionLoaded,
  isOverallEnabled,
  isReadOnlyUser,
  allDealDetailed,
  isRequiredDataReady,
  isMetricsBarEnabled,
  changeDeal,
  syncDeal,
  settingsLoaded,
  dealOutcomeEnabled,
}) => {
  const {
    clearContext,
    setPartition,
    isSubmitForecastPanelOpen,
    setShowSubmitForecastButton,
    setIsSubmitForecastPanelOpen,
  } = useHeader();

  const changedDealSelector = (state: IReduxState): Deals.Deal | null =>
    selectors.getChangedOpportunityDeal(state);
  const changedDeal = useSelector(changedDealSelector);
  const filtersLoaded = useSelector(isFiltersLoaded);

  const [dataStatus, setDataStatus] = useState<QueryStatus>('notAsked');
  const [isTableInitialized, setTableInitiliazed] = useState(false);
  const [salesProcessName, setSalesProcessName] = useState<string>('');
  const [salesProcessVersion, setSalesProcessVersion] = useState<number>(0);
  const [medicSteps, setMeddicSteps] = useState<StepWithValue[]>([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [searchText, setSearchText] = useState('');
  const [sidePanelDeal, setSidePanelDeal] = useState<Deals.Deal>();
  const [sidePanelTitle, setSidePanelTitle] = useState('');
  const [sortOrder, setSortOrder] = useState('');
  const [usedFilter, setFilter] = useState(filters);
  const [visiblePanel, setVisiblePanel] = useState<Panel>('');
  const [data, setData] = useState<Data>({
    count: 0,
    deals: [],
    sn: 0,
    total_amount: 0,
  });

  const includeDisabledUsersDefault = useSelector(
    selectors.getIncludeDisabledUsersByDefaut
  );
  const isMetricsExpanded = useSelector(selectors.isMetricsExpanded);
  const [includeDisabledUsers, setIncludeDisabledUsers] = useState(
    includeDisabledUsersDefault
  );
  const [showingMetrics, setShowingMetrics] = useState(isMetricsExpanded);

  const isLoading = ['notAsked', 'loading'].includes(dataStatus);

  const deals = useMemo(() => data.deals as unknown as IRow[], [data.deals]);

  const businessTypesForFilters =
    selectedBusinessType === OVERALL_BUSINESS_TYPE
      ? null
      : selectedBusinessType;

  const hideFilters = useMemo(() => {
    const hidden = [];
    if (
      filters.close_date_interval &&
      !ALLOWED_PREDICTION_FILTER_VALUES.includes(filters.close_date_interval[0])
    ) {
      hidden.push('deal_outcome_prediction');
    }
    return hidden;
  }, [dealOutcomeEnabled, filters.close_date_interval]);

  const splitView = useSelector(selectors.getForecastOppSplitViewFlag);
  const serializedQueryParams = JSON.stringify({
    is_forecast: true,
    opportunity_name: searchText,
    include_disabled: includeDisabledUsers,
    page_number: pageNumber - 1,
    page_size: pageSize,
    sort: sortOrder,
    business_type_name: businessTypesForFilters,
    split_view: isOppSplitEnabled && splitView,
    ...usedFilter,
    deal_outcome_prediction: hideFilters.includes('deal_outcome_prediction')
      ? []
      : usedFilter.deal_outcome_prediction,
  });

  const downloadButton: DownloadButtonProps = {
    serializedQueryParams,
    queryMethod: 'post',
    url: `${apiPoint}csv`,
    extendedOptions: true,
  };

  const currencyValueFormatter = (value: number) =>
    formatMoney(companyCurrency, value, 0);

  const forecastTotalsPanelFilters = useMemo(
    () =>
      isRequiredDataReady
        ? ({
            ...filters,
            ...(!isEmpty(filters) && { split_view: splitView }),
            ...(!isEmpty(filters) &&
              businessTypesForFilters && {
                business_type_name: businessTypesForFilters,
              }),
            include_disabled: includeDisabledUsers,
          } as FiltersForAPI & {
            business_type_name?: string;
            split_view: boolean;
            include_disabled: boolean;
          })
        : {},
    [
      JSON.stringify(filters),
      businessTypesForFilters,
      splitView,
      isRequiredDataReady,
      includeDisabledUsers,
    ]
  );

  useEffect(() => {
    setPartition('opportunities');

    AnalyticsTracker.event(
      {
        filters,
        isAdmin,
      },
      {
        category: 'Forecast Dashboard',
        action: 'Tab: Forecast',
        label: 'Tab Opened',
      }
    );

    if (!isSubmissionLoaded) {
      fetchForecastSubmission();
    }

    return () => {
      clearContext();
      clearOnExit();
    };
  }, []);

  useEffect(() => {
    if (settings.crm_type && settings.crm_type === 'no-crm') {
      history.push('/accounts/all');
    }
  });

  useEffect(() => {
    if (!isEqual(filters, usedFilter)) {
      setFilter(filters);
    }
    setPageNumber(1);
  }, [JSON.stringify(filters)]);

  useEffect(() => {
    let abortController: AbortController;

    if (isTableInitialized && isRequiredDataReady && !isEqual(usedFilter, {})) {
      abortController = new AbortController();

      const setForecastData = ({ data: forecastData }: ApiResponse) => {
        const forecastDeals = forecastData.deals || [];
        const parsedDeals = forecastDeals.map((deal) => ({
          ...deal,
          id: `${deal._id}`,
          crm_metadata:
            deal.crm_metadata && deal.crm_metadata.next_step === null
              ? { ...deal.crm_metadata, next_step: '' }
              : deal.crm_metadata,
        }));

        setData({
          ...forecastData,
          deals: parsedDeals,
          sn: Date.now(),
          total_amount: forecastData.total_amount,
        });
      };

      const setError = (error: string | null) => {
        toast.error(`Fetching data failed: ${error}`, toastOptions);
      };

      fetchApi<string, ApiResponse>({
        queryParams: serializedQueryParams,
        setData: setForecastData,
        setError,
        setStatus: setDataStatus,
        signal: abortController.signal,
        url: apiPoint,
      });
    }

    return () => {
      if (abortController) {
        abortController.abort();
      }
    };
  }, [isTableInitialized, serializedQueryParams, isRequiredDataReady]);

  useEffect(() => {
    const updatedDeals = data.deals.map((deal) => {
      const cellStatus = statuses[deal._id] ?? {};
      const newDataDeal = allDealDetailed[deal._id];
      if (cellStatus.status === 'success' && newDataDeal) {
        return { ...deal, ...newDataDeal.data };
      }
      return deal;
    });
    setData({
      ...data,
      deals: updatedDeals,
      sn: Date.now(),
    });
  }, [JSON.stringify(statuses)]);

  useEffect(() => {
    const updatedDeal = data?.deals?.find(
      (deal) => deal._id === sidePanelDeal?._id
    );

    if (updatedDeal) {
      setSidePanelDeal(updatedDeal);
      setMeddicSteps((prevMeddicSteps) =>
        prevMeddicSteps.map((step) => ({
          ...step,
          value: getDeltaValue(
            getFieldValue(step.object_field, updatedDeal as unknown as IRow)
          ),
        }))
      );
    }
  }, [data?.deals, sidePanelDeal?._id]);

  useEffect(() => {
    if (changedDeal) {
      setData({
        ...data,
        deals: data.deals.map((deal) =>
          deal._id !== changedDeal!._id
            ? deal
            : updateDealData(changedDeal as Deals.Deal)
        ),
      });
    }
  }, [JSON.stringify(changedDeal)]);

  const handleSort = useCallback((sortOrder?: string) => {
    setPageNumber(1);
    setSortOrder(sortOrder || '');
  }, []);

  const handleChange = useCallback(
    (column: IColumn, row: IRow, newValue: ValueProp) => {
      const newDeals = data.deals.map((item) =>
        item.id === row.id
          ? R.assocPath(column.field.split('.'), newValue, item)
          : item
      );

      changeDeal(
        row.id as string,
        {
          [column.field]: newValue,
          business_type_name:
            selectedBusinessType && selectedBusinessType !== 'Overall'
              ? selectedBusinessType
              : null,
        },
        () => {
          setData({
            ...data,
            deals: newDeals,
            sn: Date.now(),
          });

          AnalyticsTracker.event(
            {
              filters,
              isAdmin,
              column: column.label,
              rowId: row.id,
              newValue,
            },
            {
              category: 'Forecast Dashboard',
              action: 'Tab: Forecast',
              label: 'Change Table Cell',
            }
          );
        }
      );
    },
    [data]
  );

  const handleReady: onReadyCallback = useCallback(
    ({ order: { object_field, direction } }) => {
      setSortOrder(
        object_field ? `${direction === -1 ? '-' : ''}${object_field}` : ''
      );

      setTableInitiliazed(true);
    },
    []
  );

  const handlePageChange = useCallback(
    (pageNumber: number, pageSize: number) => {
      setPageNumber(pageNumber);
      setPageSize(pageSize);
    },
    []
  );

  const handleSearch = useCallback((text: string) => {
    setPageNumber(1);
    setSearchText(text);
  }, []);

  const handleDraftChange: onChangeCallback = (column, row, newValue) => {
    const draftItem = draftData?.find((item) => item.id === row.id) || {
      id: row.id,
    };
    const draftListWithoutItem =
      draftData?.filter((item) => item.id !== row.id) || [];

    updateDraftData([
      ...draftListWithoutItem,
      R.assocPath(column.field.split('.'), newValue, draftItem),
    ]);
  };

  const updateDealData = (deal: Deals.Deal) => ({
    ...deal,
    id: `${deal._id}`,
    crm_metadata:
      deal.crm_metadata && deal.crm_metadata.next_step === null
        ? { ...deal.crm_metadata, next_step: '' }
        : deal.crm_metadata,
  });

  const handleRefresh = () => {
    let abortController: AbortController;

    abortController = new AbortController();

    const setForecastData = ({ data: forecastData }: ApiResponse) => {
      const forecastDeals = forecastData.deals || [];

      const parsedDeals = forecastDeals.map(updateDealData);

      setData({
        ...forecastData,
        deals: parsedDeals,
        sn: Date.now(),
      });
    };

    const setError = (error: string | null) => {
      toast.error(`Fetching data failed: ${error}`, toastOptions);
    };

    fetchApi<string, ApiResponse>({
      queryParams: serializedQueryParams,
      setData: setForecastData,
      setError,
      setStatus: setDataStatus,
      signal: abortController.signal,
      url: apiPoint,
    });
  };

  const handleSpecialClick: ColumnTypesCallback = (props) => {
    const { column, columnConfig, row, extraData } = props;

    if (column.type === ColumnTypes.SALES_PROCESS) {
      if (isColumnConfigSalesProcess(columnConfig)) {
        const { steps } = columnConfig.meta;
        const stepsWithValue = steps.map((step) => ({
          ...step,
          value: getDeltaValue(getFieldValue(step.object_field, row)),
        }));
        setSalesProcessName(columnConfig.display_name);
        setSalesProcessVersion(columnConfig.meta.version);
        setMeddicSteps(stepsWithValue);
        setSidePanelTitle(
          `${columnConfig.display_name} (${
            stepsWithValue.filter(({ value }) => !!value && !isEmpty(value))
              .length
          }/${steps.length})`
        );
        setSidePanelDeal(row as unknown as Deals.Deal);
        setVisiblePanel('meddic');
      }
    } else if (
      column.type === ColumnTypes.CUSTOM &&
      extraData?.type === CUSTOM_EXTRA_TYPE
    ) {
      syncDeal(row.id as string);
    } else {
      handleColumnClick(props);
    }
  };

  const panel =
    visiblePanel === 'submissions' ? (
      <SubmitForecast
        onClose={() => {
          setVisiblePanel('');
          setIsSubmitForecastPanelOpen(false);
        }}
        formatter={currencyValueFormatter}
      />
    ) : (
      <SidePanel
        title={sidePanelTitle}
        visible
        onClose={() => setVisiblePanel('')}
      >
        <MeddicSidePanel
          data={medicSteps}
          onCancel={() => setVisiblePanel('')}
          salesProcessVersion={salesProcessVersion}
          sidePanelDeal={sidePanelDeal}
          salesProcessName={salesProcessName}
        />
      </SidePanel>
    );

  useEffect(() => {
    if (isSubmitForecastPanelOpen) {
      setVisiblePanel('submissions');
    }
  }, [isSubmitForecastPanelOpen]);

  const onModifyColumnConfiguration = useCallback(
    (configuration: TableConfigurationData) => {
      // Specific behaviour for prediction cell VPD-13043 and 'close_date_interval' filter
      const columns =
        dealOutcomeEnabled &&
        filters.close_date_interval &&
        !ALLOWED_PREDICTION_FILTER_VALUES.includes(
          filters.close_date_interval[0]
        )
          ? configuration.columns.filter(
              (column) => column.meta.type !== 'prediction'
            )
          : configuration.columns;

      return {
        ...configuration,
        columns: columns.reduce(
          (acc: TableConfigurationColumn[], col: TableConfigurationColumn) => {
            acc.push({
              ...col,
              editable: !isReadOnlyUser && col.editable,
            });
            return acc;
          },
          []
        ),
      };
    },
    [isReadOnlyUser, dealOutcomeEnabled, filters.close_date_interval]
  );

  useEffect(() => {
    setShowSubmitForecastButton(
      visiblePanel !== 'submissions' && isSubmissionAvailable && !isReadOnlyUser
    );
  }, [visiblePanel, isSubmissionAvailable, isReadOnlyUser]);

  const changesSinceDate = data.changes_since;
  const changeSince = filters.change_interval?.[0];

  const dealsWithChangeDate =
    (changesSinceDate &&
      mapChangesSinceDataForDeals(deals, changesSinceDate, changeSince)) ||
    deals;

  return (
    <div className={classNames('wrapper', styles.wrapper)}>
      <TwoColumnsDashboard isVisiblePanel={!!visiblePanel} panel={panel}>
        <div className={styles.header}>
          <OpenFiltersPanel
            tab="forecast_opportunities"
            hideFilters={hideFilters}
          />

          {filtersLoaded && (
            <div className={styles.btActiveUsers}>
              <BusinessTypesPanel
                tab="forecast_opportunities"
                isOverallEnabled={isOverallEnabled}
                onSelect={() => {
                  setPageNumber(1);
                }}
              />
              <div className={styles.activeUsersMetricsWrapper}>
                <ActiveUsersToggle
                  checked={!includeDisabledUsers}
                  onChange={setIncludeDisabledUsers}
                />
                {isMetricsBarEnabled && (
                  <>
                    <div className={styles.activeUsersMetricsDivider} />
                    <BuToggle
                      checked={showingMetrics}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setShowingMetrics(e.target.checked)
                      }
                      testingLabel="roll-ups-metrics-toggle"
                      leftLabel
                    >
                      Metrics
                    </BuToggle>
                  </>
                )}
              </div>
            </div>
          )}

          {isMetricsBarEnabled && filtersLoaded ? (
            <ForecastTotalsPanel
              selectedBusinessType={selectedBusinessType}
              filters={forecastTotalsPanelFilters}
              tab="forecast_opportunities"
              showingMetrics={showingMetrics}
            />
          ) : null}
        </div>

        <div className={styles.content}>
          {!settingsLoaded ? (
            <Dimmer inverted active>
              <Loader content="Loading" />
            </Dimmer>
          ) : (
            <Table
              currentPage={pageNumber}
              data={dealsWithChangeDate}
              downloadButton={downloadButton}
              draftData={draftData}
              emptyTableMessage={emptyTableMessage}
              fullscreen
              hidePaginationEnd
              loading={isLoading}
              onChange={handleChange}
              onDraftChange={handleDraftChange}
              onPaginationChange={handlePageChange}
              onReady={handleReady}
              onSearchChange={handleSearch}
              onSort={handleSort}
              onSpecialClick={handleSpecialClick}
              onRefresh={handleRefresh}
              rowsPerPage={pageSize}
              searchPlaceholder="Search opportunities"
              showColumnsVisibilityToggle
              sortOrder={sortOrder}
              specialTableConfig={{
                getButtonLabel: (field: string) => `View ${field} Insights`,
                showSyncDealButton: true,
                valueAsNotAvailableFor: ['forecast_category_name'],
              }}
              statuses={statuses}
              tableConfigCollection="opportunity"
              tableConfigName="Opportunities"
              title="Opportunities"
              totalCount={data.count}
              showTotalAmount
              totalAmount={data.total_amount}
              showOppSplitToggle={isOppSplitEnabled}
              onModifyColumnConfiguration={onModifyColumnConfiguration}
              hasRefreshButton
            />
          )}
        </div>
      </TwoColumnsDashboard>
    </div>
  );
};

const mapStateToProps = (state: IReduxState): StateProps => {
  const {
    deals_overall_view_enabled,
    opportunity_metrics_bar_enabled,
    deal_outcome_enabled,
  } = getFeatureFlags(state);

  return {
    selectedBusinessType: selectors.getSelectedBusinessType(
      state,
      'forecast_opportunities',
      deals_overall_view_enabled
    ),
    companyCurrency: selectors.getUserLocalCurrency(state),
    draftData: selectors.getDraftTableRows('opportunities', state),
    filters: selectors.getFiltersForAPI(state, 'forecast_opportunities'),
    isAdmin: selectors.isAdmin(state),
    isMetricsExpanded: selectors.isMetricsExpanded(state),
    isSubmissionAvailable: selectors.isForecastSubmissionAvailable(state),
    selectedItem: selectors.getSelectedItem(state),
    settings: selectors.getCompanySettings(state),
    statuses: selectors.getDealStatuses(state),
    isAdminSFDCUpdateAllowed: selectors.getIsAdminSFDCUpdateAllowed(state),
    isSubmissionLoaded: selectors.isForecastSubmissionLoaded(state),
    isOppSplitEnabled: selectors.isOppSplitEnabled(state),
    isOverallEnabled: deals_overall_view_enabled,
    isReadOnlyUser: selectors.isReadOnlyUser(state),
    allDealDetailed: selectors.getAllDealDetailed(state),
    isMetricsBarEnabled: opportunity_metrics_bar_enabled,
    dealOutcomeEnabled: deal_outcome_enabled,
    isRequiredDataReady: selectors.isBusinessTypesConfigReady(state),
    settingsLoaded: selectors.getCompanySettingsLoaded(state),
  };
};

const mapDispatchToProps = (dispatch: Function): DispatchProps => ({
  clearOnExit: () => dispatch(actions.ui.manageForecast.clearOnRouteChange()),
  fetchForecastSubmission: () =>
    dispatch(forecastActions.fetchForecastSubmissionsSettingsAvailable()),
  toggleFilters: (shouldClose: boolean) => {
    dispatch(dealsActions.toogleFiltersBlock('forecast', shouldClose));
  },
  updateDraftData: (draftTableRows: IRow[]) =>
    dispatch(
      cacheActions.updateDraftTableData('opportunities', draftTableRows)
    ),
  syncDeal: (id: string) => dispatch(dealsActions.syncDeal(id)),
  dispatch,
});

const mergeProps = (stateProps: StateProps, dispatchProps: DispatchProps) => {
  const { isAdmin, isAdminSFDCUpdateAllowed } = stateProps;
  const { dispatch, ...passedDispatchProps } = dispatchProps;

  return {
    ...stateProps,
    ...passedDispatchProps,
    changeDeal: (
      id: string,
      changes: Record<string, any>,
      callback?: () => void
    ) => {
      if (isAdmin && !isAdminSFDCUpdateAllowed) {
        toast.error('Admin not allowed.', { position: 'bottom-left' });
        dispatch(dealsActions.refreshDeals());
      } else {
        if (callback) {
          callback();
        }

        dispatch(dealsActions.changeDeal(id, changes));
      }
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(NewForecast);
