import React, { MutableRefObject } from 'react';
import { PopupProps } from 'semantic-ui-react';

import { DownloadButtonProps } from 'components/UI/DownloadButton/types';
import {
  IProps as TableControlsProps,
  DisplayedColumns,
} from 'components/UI/TableConfig/TableControls';
import { ColumnTypesCallback } from 'components/UI/TableConfig/column-helper';
import {
  ColumnsType,
  IdType,
  IRow,
  IDataCellProps,
  onChangeCallback,
  RowStatusType,
  TableDetails,
  TooltipConfig,
  GetFromRowCallback,
  ValueProp,
  IColumn,
  OnSelect,
} from 'components/UI/common/TypedTable/TypedTable';
import { RiskScoreCellConfig } from 'components/UI/common/TypedTable/renderers/RiskScoreCell';
import { Step } from 'components/UI/common/TypedTable/renderers/SalesProcessCell';

interface DefaultColumnMeta {
  delta?: boolean;
  decimals?: number;
  aggregation?: string;
  class_name?: string;
  get_class_name?: (value: ValueProp, row: IRow) => string;
  include_in_header?: 'tree' | 'team';
  forecast_category_names?: string[];
  forecast_settings_id?: { [key: string]: string };
  progress_bar?: {
    relative_field: string;
  };
  sub_value?: {
    aggregation?: string;
    class_name?: string;
    relative_field: string;
    object?: string;
    template?: string;
    arrow?: {
      type: string;
      relative_fields: string[];
    };
    badge: {
      type: string;
      relative_fields: string[];
      template?: string;
    };
  };
  tooltip?: {
    key?: string;
    name?: string;
    is_html?: boolean;
    relative_field?: string;
    relative_fields?: string[];
    hoverable?: boolean;
    position?: PopupProps['position'];
    getTooltip?: TooltipConfig['getTooltip'];
  };
  header?: {
    tooltip?: {
      hoverable?: boolean;
      renderer?: (column: IColumn) => React.ReactNode;
    };
    icon?: React.ReactNode;
  };
  pipeline_gap?: { [key: string]: string };
  pipeline_coverage?: { [key: string]: string };
  request_submissions?: { [key: string]: string };
  expression?: string;
  type?: string;
  close_date_interval?: string;
  close_date_period?: string;
  forecast_name?: string;
  business_type?: string;
  period_type?: string;
  period_delta?: number;
  getPrefix?: TooltipConfig['getTooltip'];
}

interface ClickableColumnMeta extends DefaultColumnMeta {
  type: 'clickable';
  object_id: string;
  new_flag?: string;
}

interface ProgressBarColumnMeta extends DefaultColumnMeta {
  type: 'progress_bar';
  relative_field?: string;
}

interface DateColumnMeta extends DefaultColumnMeta {
  type: 'local';
}

interface RelativeDateColumnMeta extends DefaultColumnMeta {
  type: 'relative';
}

interface QueryColumnMeta extends DefaultColumnMeta {
  type: 'query';
  object_id: string;
  params: {
    [key: string]: string | number | boolean;
  };
}

interface RiskColumnMeta extends DefaultColumnMeta {
  type: 'risk';
  object_id: string;
  risk_factors_field: RiskScoreCellConfig['risk_factors_field'];
  positive_indicators_field: RiskScoreCellConfig['positive_indicators_field'];
}

interface PopupTableColumnMeta extends DefaultColumnMeta {
  type: 'popup_table';
  columns: Pick<
    TableConfigurationColumn,
    'display_name' | 'object_field' | 'type'
  >[];
  data_path: string;
}

interface ChangeColumnMeta extends DefaultColumnMeta {
  type: 'change';
}

interface SalesProcessColumnMeta extends DefaultColumnMeta {
  type: 'sales_process';
  version: number;
  steps: Step[];
}

interface CustomColumnConfigWithRenderMeta extends DefaultColumnMeta {
  type: 'with_render';
  renderer: React.FC<IDataCellProps>;
  onClick?: Function;
}

interface CustomColumnConfigPredictionMeta extends DefaultColumnMeta {
  type: 'prediction';
  renderer: React.FC<IDataCellProps>;
}

interface CustomSummaryColumnConfig extends DefaultColumnConfig {
  type: 'custom';
  meta: SubmissionSummaryColumnMeta;
}

interface EmptyColumnMeta extends DefaultColumnMeta {
  type: 'none';
}

interface SubmissionSummaryColumnMeta extends DefaultColumnMeta {
  type: 'include_summary';
}

export interface FormulaColumnMeta extends DefaultColumnMeta {
  type: 'formula';
  expression: string;
  evaluation_order: number;
}

interface FormulaColumnConfig extends DefaultColumnConfig {
  type: 'text' | 'money' | 'number' | 'currency';
  meta: FormulaColumnMeta;
}

interface DefaultColumnConfig {
  object_field: string;
  field_name: string;
  editable: boolean;
  sortable: boolean;
  display_name: string;
  type: string;
  meta: DefaultColumnMeta;
  protected: boolean;
  hidden?: boolean;
  width?: number;
  min_width?: number;
  disable?: GetFromRowCallback<boolean> | boolean | string;
  max_width?: number;
  show_forecast_reminder?: boolean;
  request_filters?: {
    custom_filters?: Record<string, ValueProp>;
    request_custom_filters?: Record<string, ValueProp>;
    [key: string]: ValueProp;
  };
  amount_field?: string;
  crm_field?: string;
  fieldHeaderHighlight?: boolean;
  length?: number;
  advance?: boolean;
}
export interface DealsInsightsColumnConfig extends DefaultColumnConfig {
  crm_missing: boolean;
  crm_type: string;
  use_as_filter: boolean;
  is_role_field: boolean;
  object_field: string;
  custom_filter_settings: {
    is_automatically_generated?: boolean;
    custom_date_widget_enabled?: boolean;
    values?: {
      value: string | boolean;
      display_name: string;
    }[];
    ranges?: number[];
  };
}

interface DateColumnConfig extends DefaultColumnConfig {
  type: 'date' | 'change';
  meta: DateColumnMeta | RelativeDateColumnMeta | ChangeColumnMeta;
}

interface TextColumnConfig extends DefaultColumnConfig {
  type:
    | 'text'
    | 'number'
    | 'phone'
    | 'time'
    | 'checkbox'
    | 'percentage'
    | 'money'
    | 'multiplier'
    | 'clickable'
    | 'corporate_currency';
  meta: ClickableColumnMeta | ProgressBarColumnMeta | ChangeColumnMeta;
}

interface TextClickableColumnConfig extends TextColumnConfig {
  type:
    | 'text'
    | 'number'
    | 'percentage'
    | 'money'
    | 'multiplier'
    | 'clickable'
    | 'corporate_currency';
  meta: ClickableColumnMeta;
}

interface BoolColumnConfig extends DefaultColumnConfig {
  type: 'bool';
}

interface NoteColumnConfig extends DefaultColumnConfig {
  type: 'note';
}

export interface PicklistColumnConfig extends DefaultColumnConfig {
  type: 'picklist';
  choices: string[];
}

export interface SelectColumnConfig extends DefaultColumnConfig {
  type: 'picklist' | 'multipicklist';
  choices: string[];
  multiselect: boolean;
  secondary: boolean;
}
interface CRMLookupColumnConfig extends DefaultColumnConfig {
  type: 'CRM Lookup';
}

interface CustomQueryColumnConfig extends DefaultColumnConfig {
  type: 'custom';
  meta: QueryColumnMeta;
}

interface CustomRiskColumnConfig extends DefaultColumnConfig {
  type: 'custom';
  meta: RiskColumnMeta;
}

interface CustomPopupTableColumnConfig extends DefaultColumnConfig {
  type: 'custom';
  meta: PopupTableColumnMeta | EmptyColumnMeta;
}

interface CustomSalesProcessColumnConfig extends DefaultColumnConfig {
  type: 'custom';
  meta: SalesProcessColumnMeta;
}

interface CustomColumnConfigWithRender extends DefaultColumnConfig {
  type: 'custom';
  meta: CustomColumnConfigWithRenderMeta;
}

interface CustomPredictionColumnConfig extends DefaultColumnConfig {
  type: 'custom';
  meta: CustomColumnConfigPredictionMeta;
}

interface EmptyColumnConfig extends DefaultColumnConfig {
  meta: EmptyColumnMeta;
}

type CustomColumnConfigs =
  | CustomQueryColumnConfig
  | CustomRiskColumnConfig
  | CustomPopupTableColumnConfig
  | CustomSalesProcessColumnConfig
  | CustomSummaryColumnConfig
  | CustomColumnConfigWithRender
  | CustomPredictionColumnConfig;

export type RecognizedTableConfigurationTypes =
  | TextColumnConfig
  | DateColumnConfig
  | BoolColumnConfig
  | NoteColumnConfig
  | PicklistColumnConfig
  | CRMLookupColumnConfig
  | CustomColumnConfigs
  | FormulaColumnConfig
  | SelectColumnConfig;

export type TableConfigurationColumn =
  | EmptyColumnConfig
  | RecognizedTableConfigurationTypes;

export const isColumnConfigClickable = (
  columnConfig: TableConfigurationColumn
): columnConfig is TextClickableColumnConfig =>
  (columnConfig.meta as ClickableColumnMeta).type === 'clickable';

export const isColumnConfigQuery = (
  columnConfig: TableConfigurationColumn
): columnConfig is CustomQueryColumnConfig =>
  columnConfig.type === 'custom' &&
  (columnConfig.meta as QueryColumnMeta).type === 'query';

export const isColumnConfigRisk = (
  columnConfig: TableConfigurationColumn
): columnConfig is CustomRiskColumnConfig =>
  columnConfig.type === 'custom' &&
  (columnConfig.meta as RiskColumnMeta).type === 'risk';

export const isColumnConfigSalesProcess = (
  columnConfig: TableConfigurationColumn
): columnConfig is CustomSalesProcessColumnConfig =>
  columnConfig.type === 'custom' &&
  (columnConfig.meta as SalesProcessColumnMeta).type === 'sales_process';

export type TableConfigurationOrder = {
  direction?: 1 | -1;
  column?: string;
  object_field?: string;
};

export type TableConfigurationData = {
  order: TableConfigurationOrder;
  columns: TableConfigurationColumn[];
};

export type AddButtonOption = {
  key: number;
  id: number;
  text: string;
  value: string;
  onClick: Function;
};

export interface TableOwnProps
  extends Pick<TableControlsProps, 'selectedRowsButtons' | 'title'> {
  //title: string;
  totalCount?: number;
  totalAmount?: number;
  dataType?: string;

  columns: ColumnsType;
  extraHeader?: ColumnsType;
  data: IRow[];
  draftData?: IRow[];
  statuses?: RowStatusType;

  onRender?: (details: TableDetails) => void;
  onChange?: onChangeCallback;
  onDraftChange?: onChangeCallback;
  onRefresh?: () => void;

  loading?: boolean;
  fullscreen?: boolean;
  onlyBorderTop?: boolean;
  showTotalAmount?: boolean;
  hasRefreshButton?: boolean;

  sortOrder?: string;
  onSort?: (sortOrderString?: string) => void;

  hideSearch?: boolean;
  searchPlaceholder?: string;
  onSearchChange?: (value: string) => void;
  onIncludeAllDeals?: (checkAll: boolean) => void;
  onIncludedDealsChange?: (
    includedDealsIds: IdType[],
    excludedDealsIds: IdType[]
  ) => void;

  hidePaginationEnd?: boolean;
  hidePaginationStart?: boolean;
  rowsPerPage: number;
  currentPage: number;
  paginationOptions?: number[];
  onPaginationChange?: (page: number, pageSize: number) => void;
  isIncludedDeals?: boolean;
  showActionButtons?: boolean;
  showTotalResultsNextToPagination?: boolean;

  isModal?: boolean;

  downloadButton?: DownloadButtonProps;

  onAddClick?: () => void;
  controls?: React.ReactNode;
  leftControls?: React.ReactNode;
  addButtonOptions?: AddButtonOption[];

  rowClassName?(row: IRow): string;

  renderTitleExtra?: (displayedColumns: DisplayedColumns) => JSX.Element;

  selectable?: false | string;
  selectedCount?: number;
  onSelect?: OnSelect;

  emptyTableMessage?: string;

  innerScrollRef?: MutableRefObject<HTMLDivElement | null>;

  customTableColumnConfiguration?: TableConfigurationData;
  styleFirstColumn?: boolean;
  showOrHideColumns?: { label: string; id: string; show: boolean }[];
  columnsDnD?: boolean;
  showColumnsVisibilityToggle?: boolean;
  fixColumns?: boolean;
  onCloseModal?: () => void;
  filterIncluded?: string;
  filterIncludedOnChange?: () => void;
  unfilteredTotalCount?: number;
  highlightText?: string;
  enableReorderingRows?: boolean;
  canDragRow?(row: IRow): boolean;
  onDrop?(rows: IRow[], row: IRow): void;
  showOppSplitToggle?: boolean;
  stickyColumn?: boolean;
  stickyColumnRollUps?: boolean;
  hideColumnHeader?: boolean;

  hiddenColumns?: string[];
  hasActionsColumn?: boolean;

  width?: string;
  className?: string;
}

export type onReadyCallback = (configuration: TableConfigurationData) => void;

export type OwnProps = {
  onIncludedDealsChange?: (
    includedDealsIds: IdType[],
    excludedDealsIds: IdType[]
  ) => void;
  onReady?: onReadyCallback;
  onSpecialClick?: ColumnTypesCallback;
  specialTableConfig?: {
    riskMoreButtonLabel?: string;
    showSyncDealButton?: boolean;
    valueAsNotAvailableFor?: string[];
    getButtonLabel?: Function;
  };
  onCloseModal?: () => void;
};

export type TableConfigOwnProps = OwnProps & {
  tableConfigCollection?: string;
  tableConfigName?: string;
  onModifyColumnConfiguration?: (
    configuration: TableConfigurationData
  ) => TableConfigurationData;
  showOrHideColumns?: { label: string; id: string; show: boolean }[];
  isModal?: boolean;
};

export type StateProps = {
  companyCurrency: string;
  isMulticurrencyEnabled: boolean;
  tableColumnConfiguration: TableConfigurationData;
  sequence: { [key: string]: string | number }[];
  showOrHideColumns?: { label: string; id: string; show: boolean }[];
  sfBaseUrl: string;
  oppSplitViewToggle: boolean;
};

export type DispatchProps = {
  getTableColumnConfiguration: () => Function;
};

export type ShowOrHideColumn = {
  label: string;
  id: string;
  show: boolean;
};
