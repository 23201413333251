import { useQuery, useQueryClient } from '@tanstack/react-query';
import { GetDealsParams, getDeals } from 'api/Deals';
import { DownloadButtonProps } from 'components/UI/DownloadButton/types';
import { useUpdateDealMutation } from 'components/hooks/useDeals';
import { useGetSettingsForTable } from 'components/hooks/useSettingsForTable';
import { useEffect, useState } from 'react';

const DEALS_TABLE_SETTINGS = {
  collectionName: 'opportunity',
  tableName: 'Opportunities',
};

export type UseManageRevBiDealsModalParams = Omit<
  GetDealsParams,
  'page_number' | 'sort'
>;

export interface UseManageRevBiDealsModalOptions {
  doNotInvalidateOnSettled?: boolean;
}

export const useManageRevBiDealsModal = (
  partialParams: UseManageRevBiDealsModalParams,
  { doNotInvalidateOnSettled }: UseManageRevBiDealsModalOptions
) => {
  const { data: tableConfig, isLoading: isTableSettingsLoading } =
    useGetSettingsForTable(DEALS_TABLE_SETTINGS);

  const queryClient = useQueryClient();

  const [pageNumber, setPageNumber] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const handlePaginationChange = (page: number) => {
    setPageNumber(page);
  };

  const defaultSortOrder = tableConfig?.order.object_field
    ? `${tableConfig?.order.direction === -1 ? '-' : ''}${
        tableConfig?.order.object_field
      }`
    : '';
  const [userSortOrder, setUserSortOrder] = useState<string>();

  const sortOrder = userSortOrder || defaultSortOrder;

  const handleSort = (sort?: string) => {
    setUserSortOrder(sort);
  };

  const params: GetDealsParams = {
    ...partialParams,
    page_number: pageNumber - 1,
    sort: sortOrder,
  };
  const { data: dealsData, isLoading: isDealsLoading } = useQuery({
    queryKey: ['deals_modal', params],
    queryFn: () => getDeals(params),
    enabled: !!tableConfig,
    onSuccess: (data) => {
      if (!totalCount) {
        setTotalCount(data.count ?? 0);
      }
    },
    staleTime: 5 * 60 * 1000,
    cacheTime: 5 * 60 * 1000,
  });

  const updateDealMutation = useUpdateDealMutation(['deals_modal', params], {
    doNotInvalidateOnSettled,
  });

  // On unmount invalidate the query to avoid stale data
  // if the user opens the modal again
  useEffect(() => {
    return () => {
      queryClient.invalidateQueries(['deals_modal']);
    };
  }, []);

  return {
    dealsCount: totalCount,
    totalDealsAmount: dealsData?.total_amount,
    deals: dealsData?.deals,
    isLoading: isDealsLoading || isTableSettingsLoading,
    dealsColumns: tableConfig?.columns,
    updateDeal: updateDealMutation.mutateAsync,
    pageNumber,
    handlePaginationChange,
    sortOrder,
    handleSort,
  };
};

export const getDownloadButtonProps = (
  params: UseManageRevBiDealsModalParams
): DownloadButtonProps => ({
  url: '/api/data/deals/csv',
  queryMethod: 'post',
  serializedQueryParams: JSON.stringify(params),
  extendedOptions: true,
});
