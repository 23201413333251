import * as R from 'ramda';
import React from 'react';

import { IRow } from 'components/UI/common/TypedTable/TypedTable';
import { highlightText as highlightTextStyle } from 'components/UI/common/TypedTable/styles';
import { IRowType } from 'components/settings/SalesProcess/types';

export type IEditableRow = {
  isEditing: boolean;
  __backup: IRow | null;
} & IRow;

export type IAddableRow = {
  isNew: boolean;
} & IRow;

let newIdCounter = 0;
export const EditingRow = {
  useEditingRow: <T extends IRow>(row: T): IEditableRow & T => ({
    ...row,
    isEditing: false,
    __backup: null,
  }),

  startEditing: <T extends IEditableRow>(row: T): T => ({
    ...row,
    isEditing: true,
    __backup: R.clone(row),
  }),

  cancelEditing: <T extends IEditableRow>(row: T): T => ({
    ...row,
    ...row.__backup,
    isEditing: false,
    __backup: null,
  }),

  getOriginal: <T extends IEditableRow>(row: T): T => row.__backup as T,

  canEditOnlyOne: (row: IRow, rows: IRow[]) =>
    (rows as IRowType[]).some((item) => item.isEditing),

  mapStartEditingCallback:
    <T extends IEditableRow>(row: IRow) =>
    (item: T): T =>
      item.id === row.id ? EditingRow.startEditing(item) : item,

  mapCancelEditingCallback:
    <T extends IEditableRow>(row: IRow) =>
    (item: T): T =>
      item.id === row.id ? EditingRow.cancelEditing(item) : item,

  useAddingRow: <T extends IRow>(row: T): IAddableRow & T => ({
    ...row,
    isNew: false,
  }),

  createNewRow: <T extends IRow>(row: T): T & IAddableRow => ({
    ...row,
    id: row.id ? row.id : `**new-row-${newIdCounter++}`,
    isNew: true,
  }),

  filterCancelEditingNewRowsCallback:
    <T extends IAddableRow>(row: IRow) =>
    (item: T): boolean =>
      !(item.isNew && item.id === row.id),
};

/**
 * Expands/Collapse children rows
 *
 * @param source
 * @param level which level should be expanded/collapsed
 * @param expand
 * @param bottomTop does expands parents also
 */
export const setRowExpand = <T extends IRow>(
  source: T[],
  level: number,
  expand: boolean,
  bottomTop: boolean = false
): T[] =>
  level >= 0
    ? source.map((item) => ({
        ...item,
        isChildrenVisible:
          level === 0 || bottomTop ? expand : item.isChildrenVisible,
        children: item.children
          ? setRowExpand(item.children, level - 1, expand, bottomTop)
          : undefined,
      }))
    : source;

export const highlightText = (text: string, highlight: string) => {
  const expr = new RegExp(`(${highlight})`, 'gi');
  return text.split(expr).map((x, i) =>
    x.match(expr) ? (
      <mark key={i} className={highlightTextStyle}>
        {x}
      </mark>
    ) : (
      <span key={i}>{x}</span>
    )
  );
};
