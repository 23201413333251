import {
  statusOptions,
  accessModeOptions,
  scopeOptions,
  scopeLabels,
  tokenStatusToConfig,
  FULL_SCOPES_USER_COUNT,
  HALF_SCOPES_USER_COUNT,
} from './constants';
import { columns, tableMinWidth } from './tableConfig';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import moment from 'moment';
import React, {
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import UserAvatar from 'react-user-avatar';
import { Dimmer, Dropdown, Input, Loader } from 'semantic-ui-react';
import { DropdownItemProps } from 'semantic-ui-react/dist/commonjs/modules/Dropdown/DropdownItem';

import * as CompanyUserActions from 'actions/companyUsersActions';
import {
  getCompanyUser as getUser,
  syncCompanyUser as syncUser,
  updateCompanyUser as updateUser,
} from 'actions/companyUsersActions';
import * as userProfileActions from 'actions/userProfilesActions';
import { BoostUpIcons } from 'assets/css/boostup-icons';
import { USER_ROLES } from 'common/constants';
import BuButton, { BuControlSize } from 'components/UI/BuButton';
import BuIcon from 'components/UI/BuIcon';
import BuPopup from 'components/UI/BuPopup';
import BuToggle from 'components/UI/BuToggle';
import Scrolling from 'components/UI/common/Scrolling/Scrolling';
import Tooltip from 'components/UI/common/Tooltip';
import TypedTable, {
  BorderType,
  IRow,
  onChangeCallback,
  TableDetails,
} from 'components/UI/common/TypedTable/TypedTable';
import EmailValidationIcon from 'components/settings/ManageUsers/UserDetails/EmailValidationIcon/EmailValidationIcon';
import SyncCheckbox, {
  switchLabel,
} from 'components/settings/ManageUsers/UserDetails/SyncCheckbox';
import {
  checkEmailAvailability,
  getDomainByEmail,
  regexValidateEmail,
  splitEmail,
} from 'components/settings/ManageUsers/UserDetails/helpers';
import * as s from 'components/settings/ManageUsers/UserDetails/styles';
import { UserProfile } from 'components/settings/UserProfiles/types';
import { avatarColors } from 'components/settings/styles';
import {
  CompanyUserState,
  USER_STATUS,
  UserResponse,
  AccessMode,
  Scopes,
  IImpersonation,
} from 'reducers/companyUsersReducer';
import { IReduxState } from 'reducers/types';
import * as selectors from 'selectors';
import { isImpersonate } from 'selectors';
import {
  getCompanyManagerList,
  getCompanyUserDetails,
  getCompanyUserIsLoading,
  getCompanyUserDisabledFields,
} from 'selectors/companyUsers';
import { fetchApiPromise } from 'utils/network';

const NotFoundLabel: React.FC<{}> = ({ children }) => (
  <span className={s.notFound}>{children}</span>
);

type ImpersonationUser = {
  has_team?: boolean;
  id: string;
  is_idle: boolean;
  name: string;
};

const UserDetails: React.FC<{ id: string; onClose?(): void }> = ({
  id,
  onClose = () => {},
}) => {
  const dispatch = useDispatch();

  const [user, disabledFields, isLoading, managers]: [
    CompanyUserState,
    string[],
    boolean,
    UserResponse[]
  ] = useSelector(
    (state: IReduxState) => [
      getCompanyUserDetails(state),
      getCompanyUserDisabledFields(state),
      getCompanyUserIsLoading(state),
      getCompanyManagerList(state),
    ],
    shallowEqual
  );
  const profiles: UserProfile[] = useSelector(selectors.getUserProfilesList());
  const isAdminEnabled: boolean = useSelector(selectors.isAdminEnabled);
  const {
    user_edit_email_enabled: isUserEditEmailEnabled,
  }: { user_edit_email_enabled: boolean } = useSelector(
    selectors.getFeatureFlags
  );

  const [role, setRole] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [emailUsername, setEmailUsername] = useState<string>('');
  const [emailDomain, setEmailDomain] = useState<string>('');
  const [isEmailValid, setEmailValid] = useState<boolean | null>(null);
  const [isEmailAvailable, setEmailAvailable] = useState<boolean>(false);
  const [manager, setManager] = useState<string>('');
  const [status, setStatus] = useState<USER_STATUS>('disabled');
  const [currentUser, setCurrentUser] = useState<string>('');
  const [usersList, setUsersList] = useState<ImpersonationUser[]>([]);
  const [accessMode, setAccessMode] = useState<AccessMode>(AccessMode.EDITOR);
  const [scope, setScope] = useState<Scopes>(Scopes.ALL);
  const [salesforceId, setSalesforceId] = useState<string>('');
  const [salesforceIdOptions, setSalesforceIdOptions] = useState<
    DropdownItemProps[]
  >([]);
  const [emailSync, setEmailSync] = useState<boolean>(false);
  const [calendarSync, setCalendarSync] = useState<boolean>(false);
  const [callsSync, setCallsSync] = useState<boolean>(false);
  const [isTouched, setTouched] = useState<boolean>(false);
  const [allowSalesforceUpdate, setAllowSalesforceUpdate] =
    useState<boolean>(false);
  const isImpersonationAllowed = useSelector((state: IReduxState) =>
    isImpersonate(state)
  );

  const [profileId, setProfileId] = useState<string>(
    user.profile ? user.profile.id : 'notAssigned'
  );
  const [managersOptions, setManagersOptions] = useState<DropdownItemProps[]>(
    []
  );
  const [impersonation_settings, setImpersonation_settings] = useState<
    IImpersonation[]
  >(user.impersonation_settings);
  const [rows, setRows] = useState<IRow[]>([]);

  const getRows = () => {
    const data: IRow[] = impersonation_settings.map(
      (impersonation: IImpersonation) => {
        const user = usersList.find(
          (user: ImpersonationUser) => user.id === impersonation.user
        );

        const scope = scopeLabels[impersonation.scope];
        return {
          ...impersonation,
          id: impersonation.user,
          scope: scope,
          user: user ? user.name : 'All Users',
        } as IRow;
      }
    );
    return data;
  };

  useEffect(() => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/settings/company/users/metadata`;

    if (user.email) {
      const domain = getDomainByEmail(user.email);

      fetchApiPromise<object, any>({
        queryParams: {
          email_domain: [domain],
        },
        queryMethod: 'get',
        url,
      }).then(({ result, error }) => {
        if (!error) {
          setUsersList(result.users);
        }
      });
    }
  }, [user.email]);

  useEffect(() => {
    setImpersonation_settings(user.impersonation_settings);
  }, [user.impersonation_settings]);

  useEffect(() => {
    setRows(getRows());
  }, [impersonation_settings, usersList]);

  const onAddUserToImpersonation = () => {
    const settingBase = { scope, access_mode: accessMode };

    const newSetting =
      scope === Scopes.ALL
        ? settingBase
        : { ...settingBase, user: currentUser };

    setTouched(true);
    setCurrentUser('');
    setImpersonation_settings([...impersonation_settings, newSetting]);
  };

  const emailChanged = useMemo(
    () => user?.email !== `${emailUsername}@${emailDomain}`,
    [user?.email, emailUsername, emailDomain]
  );
  const showEmailCalendarSyncModalPopup = useMemo(
    () => (emailSync || calendarSync) && emailChanged,
    [emailSync, calendarSync, emailChanged]
  );
  const profileOptions = useMemo(
    () => [
      { key: 'notAssigned', text: 'not assigned', value: 'notAssigned' },
      ...profiles
        .map(({ id, name }) => ({
          key: id,
          text: name,
          value: id,
        }))
        .sort((a, b) => a.text.localeCompare(b.text)),
    ],
    [profiles]
  );

  const emailEditDisabled =
    disabledFields.includes('email') || !isUserEditEmailEnabled;

  useEffect(() => {
    dispatch(userProfileActions.getUserProfilesList());
  }, []);

  useEffect(() => {
    dispatch(getUser({ user: id }));

    setTouched(false);
  }, [id]);

  useEffect(() => {
    reset(user);
  }, [user]);

  useEffect(() => {
    const activeManagersWithoutSelfUser = managers
      .filter(
        (item) =>
          item.status !== 'disabled' || item.email === user.manager?.email
      )
      .map<DropdownItemProps>((item) => ({
        key: item.email,
        text: item.name || item.email,
        value: item.email,
        description: item.email,
        disabled: item.status === 'disabled' || item.email === user.email,
      }));

    const managerOptions: DropdownItemProps[] = [
      {
        key: 'none',
        text: 'None',
        value: '',
      },
      ...activeManagersWithoutSelfUser,
    ];

    if (managers.some((item) => item.email === user.manager?.email)) {
      managerOptions.push({
        key: user.manager?.email || '',
        text: user.manager?.name || user.manager?.email,
        value: user.manager?.email,
        description: user.manager?.email,
        disabled: true,
      });
    }

    setManagersOptions(
      managerOptions.map((item, index) => ({ ...item, key: item.key + index }))
    );
  }, [JSON.stringify(managers), user]);

  const reset = (user: UserResponse) => {
    setTouched(false);
    setName(user.name || '');
    const [username = '', domain = ''] = splitEmail(user.email);
    setEmailUsername(username);
    setEmailDomain(domain);
    setRole(user.role);
    setProfileId(user.profile?.id || 'notAssigned');
    setManager(user.manager?.email || '');
    setStatus(user.status);
    if (Boolean(user.sfdc_user_id)) {
      setSalesforceId(user.sfdc_user_id.find((el) => el.selected)?.id ?? '');
      setSalesforceIdOptions(
        [
          ...user.sfdc_user_id.map(
            (el) =>
              ({ text: el.id, value: el.id, key: el.id } as DropdownItemProps)
          ),
        ] || []
      );
    }
    setEmailSync(user.user_settings?.processing?.emails || false);
    setCalendarSync(user.user_settings?.processing?.calendar || false);
    setCallsSync(user.user_settings?.processing?.calls || false);
    setAllowSalesforceUpdate(user.allow_salesforce_update || false);
  };

  const handleCancel = () => {
    reset(user);
    onClose();
  };

  const handleSave = useCallback(() => {
    dispatch(
      updateUser({
        name: name,
        email: user.email,
        ...(emailChanged && { new_email: `${emailUsername}@${emailDomain}` }),
        role: role,
        status: status,
        manager: manager || null,
        profile: profileId === 'notAssigned' ? null : profileId,
        impersonation_settings: impersonation_settings,
        allow_salesforce_update: allowSalesforceUpdate,
        sfdc_user_id: salesforceId,
        user_settings: {
          processing: {
            calendar: calendarSync,
            calls: callsSync,
            emails: emailSync,
          },
        },
      })
    );

    setTouched(false);
    onClose();

    if (showEmailCalendarSyncModalPopup) {
      dispatch(
        CompanyUserActions.showEmailCalendarSyncPopup(calendarSync, emailSync)
      );
    }
  }, [
    user,
    name,
    role,
    status,
    manager,
    profileId,
    impersonation_settings,
    calendarSync,
    callsSync,
    emailSync,
    showEmailCalendarSyncModalPopup,
    salesforceId,
    allowSalesforceUpdate,
    emailChanged,
    emailUsername,
    emailDomain,
  ]);

  const handleEmailSyncClick = () => {
    dispatch(syncUser(user.id, 'email'));
  };

  const handleCalendarSyncClick = () => {
    dispatch(syncUser(user.id, 'calendar'));
  };

  const handleProfileChange = (
    e: SyntheticEvent<HTMLElement>,
    {
      value,
    }: { value?: string | number | boolean | (number | string | boolean)[] }
  ) => {
    if (value) {
      setProfileId(value as string);
      setTouched(true);
    }
  };

  const token = user.access_token;
  const expiry = token?.expiry || undefined;

  const dateFormat =
    moment(expiry).year() === new Date().getFullYear()
      ? 'MMMM Do, h:mm a'
      : 'MMMM Do YYYY, h:mm a';

  const fetchAndCheckEmailAvailability = async (
    email: string
  ): Promise<void> => {
    const available = await checkEmailAvailability(email);
    setEmailValid(available);
    setEmailAvailable(available || false);
    setTouched(available || false);
  };

  const validateEmail = (email: string, initialEmail: string): void => {
    setTouched(false);
    setEmailValid(null);

    if (!email || !regexValidateEmail(email)) {
      setEmailValid(false);
      setEmailAvailable(true);
      return;
    }

    if (initialEmail === email) {
      setEmailValid(null);
      setEmailAvailable(true);
    } else {
      fetchAndCheckEmailAvailability(email);
    }
  };

  const debouncedEmailValidation = useCallback(
    debounce((email: string) => {
      validateEmail(email, user.email);
    }, 400),
    [user.email]
  );

  const [shadowLeftOffset, setShadowLeftOffset] = useState<number>(0);
  const [shadowTopOffset, setShadowTopOffset] = useState<number>(0);

  const handleTableDetails = useCallback((details: TableDetails) => {
    if (details.columns && details.columns[0]) {
      setShadowLeftOffset(details.columns[0].width);
      setShadowTopOffset(details.columns[0].height);
    }
  }, []);

  const handleChange: onChangeCallback = (column, row, newValue) => {
    const editedRow = row as any;
    if (newValue === 'remove_access') {
      setImpersonation_settings(
        impersonation_settings.filter((setting: IImpersonation) => {
          if (editedRow.id && editedRow.scope === scopeLabels[setting.scope]) {
            return setting.user !== editedRow.id;
          }

          if (!editedRow.id) {
            return setting.scope !== Scopes.ALL;
          }

          return true;
        })
      );
    } else {
      setImpersonation_settings(
        impersonation_settings.map((setting: IImpersonation) => {
          if (
            editedRow.id &&
            editedRow.id === setting.user &&
            editedRow.scope === scopeLabels[setting.scope]
          ) {
            return {
              ...setting,
              access_mode: newValue,
            } as IImpersonation;
          }

          if (editedRow.scope === 'All' && setting.scope === Scopes.ALL) {
            return {
              ...setting,
              access_mode: newValue,
            } as IImpersonation;
          }

          return {
            ...setting,
          } as IImpersonation;
        })
      );
    }
    setTouched(true);
  };

  const userDropdownOptions = usersList
    .filter((user: ImpersonationUser) => !user.is_idle)
    .map((user: ImpersonationUser) => {
      return {
        text: user.name,
        value: user.id,
        key: user.id,
        hasteam: user.has_team ? 1 : 0,
        id: user.id,
      };
    })
    .filter((user) => {
      const addedUser = impersonation_settings.filter(
        (item) => item.user === user.value
      );

      let includeUser = true;

      if (addedUser.length === FULL_SCOPES_USER_COUNT) {
        includeUser = false;
      } else if (addedUser.length === HALF_SCOPES_USER_COUNT) {
        if (addedUser[0].scope === scope) {
          includeUser = false;
        }
      }
      return includeUser;
    })
    .filter(
      (usr) =>
        (scope === Scopes.TEAM ? usr?.hasteam : usr) &&
        (scope === Scopes.SINLGE_USER ? usr.id !== user.id : usr)
    );

  const addImpersonationBtnDisabled =
    (scope === Scopes.ALL &&
      !!impersonation_settings.find(
        (setting) => setting.scope === Scopes.ALL
      )) ||
    (scope !== Scopes.ALL &&
      (!currentUser ||
        !!impersonation_settings.find(
          (setting) => setting.user === currentUser && setting.scope === scope
        )));

  const userSelectComponent = (
    <Dropdown
      options={userDropdownOptions}
      onChange={(e, { value }) => {
        setCurrentUser(value as USER_STATUS);
        setTouched(true);
      }}
      value={currentUser}
      placeholder="Select user/team"
      disabled={scope === Scopes.ALL}
      selection
      search
      className={classNames({ [s.dropdownWidth240]: scope !== Scopes.ALL })}
    />
  );

  return (
    <div className={s.container}>
      <div className={s.header}>User Details</div>
      <div className={s.mainContent}>
        <Dimmer.Dimmable dimmed={isLoading}>
          {token ? (
            <BuPopup
              trigger={(ref) => (
                <div
                  className={classNames(s.userHeaderContainer, 'hasTooltip')}
                  ref={ref}
                >
                  <div className="avatar">
                    <UserAvatar
                      size={50}
                      name={user.name || user.email || 'NA'}
                      colors={avatarColors}
                    />
                  </div>
                  <div className="details">
                    <div className="header">
                      {user.name || user.email}
                      <BuIcon
                        name={
                          token.status === 'valid'
                            ? BoostUpIcons.CheckCircle
                            : BoostUpIcons.DangerCircle
                        }
                        className={classNames(
                          s.icon,
                          tokenStatusToConfig[token.status]?.className
                        )}
                      />
                    </div>
                    <div>{user.email}</div>
                  </div>
                </div>
              )}
            >
              <div className={s.tooltipContent}>
                <p
                  className={classNames(
                    'tooltipHeader',
                    tokenStatusToConfig[token.status]?.className
                  )}
                >
                  {tokenStatusToConfig[token.status]?.text}
                </p>

                {expiry && (
                  <p className="tooltipText">
                    Since {moment(expiry).format(dateFormat)}
                  </p>
                )}
              </div>
            </BuPopup>
          ) : (
            <div className={s.userHeaderContainer}>
              <div className="avatar">
                <UserAvatar
                  size={50}
                  name={user.name || user.email || 'NA'}
                  colors={avatarColors}
                />
              </div>
              <div className="details">
                <div className="header">{user.name || user.email}</div>
                <div>{user.email}</div>
              </div>
            </div>
          )}

          <div className={s.userDialContainer}>
            <div className="header">Dial Settings:</div>
            <div>
              Phone:{' '}
              {user.user_settings?.dial_info?.dial_phone_number || (
                <NotFoundLabel>No phone number</NotFoundLabel>
              )}
            </div>
            <div>
              URL:{' '}
              {user.user_settings?.dial_info?.dial_meeting_url ? (
                <a
                  href={user.user_settings.dial_info.dial_meeting_url}
                  target="_blank"
                >
                  {user.user_settings.dial_info.dial_meeting_url}
                </a>
              ) : (
                <NotFoundLabel>No meeting url</NotFoundLabel>
              )}
            </div>
            <div>
              Access Code:{' '}
              {user.user_settings?.dial_info?.dial_access_code || (
                <NotFoundLabel>No access code</NotFoundLabel>
              )}
            </div>
          </div>

          <div className={s.userDetailsContainer}>
            <div className="item">
              <div className="lab">Name:</div>
              <div className="control">
                <Input
                  value={name}
                  onChange={(e, { value }) => {
                    setName(value);
                    setTouched(true);
                  }}
                  placeholder="Set Name"
                  disabled={disabledFields.includes('name')}
                  fluid
                  data-testing="txt_field"
                />
              </div>
            </div>
            <div className="item">
              <div className="lab">Email:</div>
              <div className="half-size-control">
                <Input
                  type="text"
                  className={`${isEmailValid === false && 'error'}`}
                  value={emailUsername}
                  maxLength={64}
                  onChange={(e, { value }) => {
                    setEmailUsername(value as string);
                    debouncedEmailValidation(`${value}@${emailDomain}`);
                  }}
                  disabled={emailEditDisabled}
                  data-testing="txt_field"
                />
                <Dropdown
                  className={`${isEmailValid === false && 'error'}`}
                  options={Object.values(user?.email_domains || []).map(
                    (domain) => ({
                      key: `@${domain}`,
                      text: `@${domain}`,
                      value: domain,
                    })
                  )}
                  onChange={(e, { value }) => {
                    setEmailDomain(value as string);
                    debouncedEmailValidation(`${emailUsername}@${value}`);
                  }}
                  value={emailDomain}
                  disabled={emailEditDisabled}
                  selection
                />
                <EmailValidationIcon
                  isValid={isEmailValid}
                  isAvailable={isEmailAvailable}
                />
              </div>
            </div>
            <div className="item">
              <div className="lab">Role:</div>
              <div className="control">
                <Dropdown
                  options={Object.values(USER_ROLES).map((role) => ({
                    key: role,
                    text: role,
                    value: role,
                  }))}
                  onChange={(e, { value }) => {
                    setRole(value as string);
                    setTouched(true);
                  }}
                  value={role}
                  placeholder="Select Role"
                  disabled={disabledFields.includes('role')}
                  selection
                />
              </div>
            </div>
            <div className="item">
              <div className="lab">User Profile:</div>
              <div className="control">
                <Dropdown
                  options={profileOptions}
                  onChange={handleProfileChange}
                  value={profileId}
                  placeholder="Select Profile"
                  selection
                />
              </div>
            </div>
            <div className="item">
              <div className="lab">Manager:</div>
              <div className="control">
                <Dropdown
                  options={managersOptions}
                  onChange={(e, { value }) => {
                    setManager(value as string);
                    setTouched(true);
                  }}
                  value={manager}
                  placeholder="Select Manager"
                  disabled={disabledFields.includes('manager')}
                  selection
                />
              </div>
            </div>
            <div className="item">
              <div className="lab">Status:</div>
              <div className="control">
                <Dropdown
                  options={statusOptions}
                  onChange={(e, { value }) => {
                    setStatus(value as USER_STATUS);
                    setTouched(true);
                  }}
                  value={status}
                  placeholder="Select Status"
                  disabled={disabledFields.includes('status')}
                  selection
                />
              </div>
            </div>
            <div className="item">
              <div className="lab">CRM ID:</div>
              <div className="control">
                <Dropdown
                  options={salesforceIdOptions}
                  onChange={(e, { value }) => {
                    setSalesforceId(value as string);
                    setTouched(true);
                  }}
                  value={salesforceId}
                  placeholder="Select Id"
                  selection
                />
              </div>
            </div>
            <div className="item">
              <div className="lab">Email Sync:</div>
              <div className="control">
                <SyncCheckbox
                  checked={emailSync}
                  onChange={(checked) => {
                    setEmailSync(checked);
                    setTouched(true);
                  }}
                  lastSyncDate={user.email_watcher_last_processed_at}
                  lastWatcherDate={user.email_watcher_refreshed_at}
                  lastWatcherFailedDate={
                    user.email_watcher_refreshed_errored_at
                  }
                  lastSyncFailedDate={user.email_watcher_last_errored_at}
                  hasWatcherFailed={user.email_watcher_refreshed_failed}
                  hasSyncFailed={user.email_watcher_last_processed_failed}
                  onSync={handleEmailSyncClick}
                  isAdminEnabled={isAdminEnabled}
                  disabled={disabledFields.includes('calendar')}
                />
              </div>
            </div>
            <div className="item">
              <div className="lab">Calendar Sync:</div>
              <div className="control">
                <SyncCheckbox
                  checked={calendarSync}
                  onChange={(checked) => {
                    setCalendarSync(checked);
                    setTouched(true);
                  }}
                  lastSyncDate={user.calendar_watcher_last_processed_at}
                  lastSyncFailedDate={user.calendar_watcher_last_errored_at}
                  lastWatcherFailedDate={
                    user.calendar_watcher_refreshed_errored_at
                  }
                  hasWatcherFailed={user.calendar_watcher_refreshed_failed}
                  hasSyncFailed={user.calendar_watcher_last_processed_failed}
                  onSync={handleCalendarSyncClick}
                  isAdminEnabled={isAdminEnabled}
                  disabled={disabledFields.includes('emails')}
                />
              </div>
            </div>
            <div className="item">
              <div className="lab">Call Sync:</div>
              <div className="control">
                <BuToggle
                  checked={callsSync}
                  onChange={(event) => {
                    setCallsSync(event.target.checked);
                    setTouched(true);
                  }}
                  disabled={disabledFields.includes('calls')}
                >
                  {switchLabel(callsSync)}
                </BuToggle>
              </div>
            </div>
            {!isImpersonationAllowed && (
              <div className="item">
                <div className="lab" style={{ lineHeight: '16px' }}>
                  Impersonation Settings:
                </div>
                <div className={s.impersonationContent}>
                  <div className={s.impersonationControls}>
                    <Dropdown
                      options={scopeOptions}
                      onChange={(e, { value }) => {
                        setScope(value as Scopes);
                        setTouched(true);
                      }}
                      value={scope}
                      placeholder="Select"
                      selection
                      className={s.dropdownWidth75}
                    />

                    {scope === Scopes.ALL ? (
                      <Tooltip
                        className={s.tooltipWrapper}
                        open
                        hoverable
                        tooltip={
                          <div>
                            User/team can not be selected when scope is ‘All’{' '}
                          </div>
                        }
                      >
                        {userSelectComponent}
                      </Tooltip>
                    ) : (
                      userSelectComponent
                    )}

                    <Dropdown
                      options={accessModeOptions}
                      onChange={(e, { value }) => {
                        setAccessMode(value as AccessMode);
                        setTouched(true);
                      }}
                      value={accessMode}
                      placeholder="Select Status"
                      selection
                      className={s.dropdownWidth120}
                    />
                    <BuButton
                      onClick={onAddUserToImpersonation}
                      disabled={addImpersonationBtnDisabled}
                      className={s.addButton}
                      size={BuControlSize.SMALL}
                    >
                      Add
                    </BuButton>
                  </div>
                  <div className={s.tableTitleWrapper}>
                    <div className={s.tableTitle}>
                      Users/teams ({impersonation_settings?.length})
                    </div>
                    <BuToggle
                      checked={allowSalesforceUpdate}
                      onChange={(event) => {
                        setAllowSalesforceUpdate(event.target.checked);
                        setTouched(true);
                      }}
                      disabled={disabledFields.includes(
                        'allow_salesforce_update'
                      )}
                    >
                      Update CRM Fields
                    </BuToggle>
                  </div>
                  <div className={s.tableWrapper}>
                    <TypedTable.Border
                      width="100%"
                      borders={BorderType.ALL}
                      height="200px"
                    >
                      <Scrolling
                        alwaysShow
                        height="100%"
                        shadowLeftOffset={shadowLeftOffset}
                        shadowTopOffset={shadowTopOffset}
                        shadows
                      >
                        <TypedTable
                          columns={columns}
                          data={isLoading ? [] : rows}
                          fullscreen
                          minWidth={tableMinWidth}
                          onHeaderHeight={setShadowTopOffset}
                          onRender={handleTableDetails}
                          onChange={handleChange}
                          stickyColumn
                          stickyHeader
                          width="100%"
                          className={s.tableCustomWrapper}
                        />
                      </Scrolling>
                    </TypedTable.Border>
                  </div>
                </div>
              </div>
            )}
          </div>

          <Dimmer active={isLoading} inverted>
            <Loader />
          </Dimmer>
        </Dimmer.Dimmable>
      </div>

      <div className={s.actionButtons}>
        <BuButton secondary size={BuControlSize.REGULAR} onClick={handleCancel}>
          Cancel
        </BuButton>
        <BuButton
          size={BuControlSize.REGULAR}
          disabled={!isTouched}
          onClick={handleSave}
        >
          Save
        </BuButton>
      </div>
    </div>
  );
};

export default UserDetails;
