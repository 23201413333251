import { actions } from 'actions';
import Table from 'components/UI/TableConfig/Table';
import { onChangeCallback } from 'components/UI/common/TypedTable/TypedTable';
import { useRevBiDrilldownModalWithScheme } from 'components/dashboard/Metrics/Widget/hooks/useRevBiDrilldownModal';
import { useManageRowStatuses } from 'components/hooks/useManageRowStatuses';
import { useManageTableConfigurationColumns } from 'components/hooks/useSettingsForTable';
import { css } from 'emotion';
import { PersistQueryParams } from 'navigation/types';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { getAccountHiddenColumns } from 'selectors';
import { handleSpecialAccountClick } from '../AccountsModal/AccountsModal';
import {
  UseManageAccountsModalParams,
  useManageAccountsModal,
} from './RevBiAccountsModal.helper';

export const tableWrapper = css`
  margin-left: -20px;
  margin-right: -20px;
  height: 80vh;
  padding-top: 5px;
`;

const ACCOUNTS_PAGE_SIZE = 50;

const RevBiAccountsModal = ({}) => {
  const { getProps, markShouldReFetchOnClose } =
    useRevBiDrilldownModalWithScheme('/revbi-account-modal');

  const dispatch = useDispatch();
  const legacySpecialClickPersisModal = () => (params: PersistQueryParams) => {
    dispatch(actions.ui.modal.persist(params));
  };

  const {
    rowStatuses,
    setLoadingRowField,
    setSuccessRowField,
    setErrorRowField,
  } = useManageRowStatuses();

  const { parseColumns, sortColumns } = useManageTableConfigurationColumns();

  const showOrHideColumns = useSelector(getAccountHiddenColumns);

  const { ids, title } = getProps();
  const params: UseManageAccountsModalParams = {
    ids,
    page_size: ACCOUNTS_PAGE_SIZE,
  };

  const {
    accountsCount,
    accounts,
    isLoading,
    accountsColumns,
    updateAccount,
    pageNumber,
    handlePaginationChange,
    sortOrder,
    handleSort,
  } = useManageAccountsModal(params);

  const rows =
    accounts?.map((account) => ({
      ...account,
      id: account._id,
    })) || [];

  const columns =
    accountsColumns &&
    parseColumns({
      tableConfigurationColumns: accountsColumns,
      hiddenColumns: [],
      oppSplitViewToggleEnabled: false,
      riskMoreButtonLabel: 'View Account Insights',
      onSpecialClick: (specialClickProps) => {
        // Legacy code from old AccountsModal
        handleSpecialAccountClick(
          specialClickProps,
          legacySpecialClickPersisModal
        );
      },
    });

  const handleChange: onChangeCallback = async (column, row, newValue) => {
    const accountId = row.id.toString();
    const fieldToUpdate = column.field;
    const updateOperation = {
      [fieldToUpdate]: newValue,
    };

    setLoadingRowField(accountId, fieldToUpdate);

    try {
      await updateAccount({
        id: accountId,
        editedFields: updateOperation,
      });

      toast.success('Account updated successfully');
      setSuccessRowField(accountId, fieldToUpdate);
      markShouldReFetchOnClose();
    } catch (e) {
      toast.error('Failed to update Account');
      setErrorRowField(accountId, fieldToUpdate);
    }
  };

  const sortedColumns = columns && sortColumns(columns);

  return (
    <div className={tableWrapper}>
      <Table
        title={title}
        data={rows}
        columns={sortedColumns ?? []}
        loading={isLoading}
        statuses={rowStatuses}
        rowsPerPage={ACCOUNTS_PAGE_SIZE}
        currentPage={pageNumber}
        totalCount={accountsCount}
        onChange={handleChange}
        showOrHideColumns={showOrHideColumns}
        onPaginationChange={handlePaginationChange}
        sortOrder={sortOrder}
        onSort={handleSort}
        dataType="accounts"
        showTotalAmount={false}
        hideSearch
        isModal
        showColumnsVisibilityToggle
        fullscreen
        hidePaginationEnd
      />
    </div>
  );
};

export default RevBiAccountsModal;
