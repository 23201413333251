import { useQuery } from '@tanstack/react-query';
import {
  GetSettingsForTableParams,
  getSettingsForTable,
} from 'api/TableSettings';
import {
  ColumnTypesCallback,
  columnTypes,
} from 'components/UI/TableConfig/column-helper';
import { TableConfigurationColumn } from 'components/UI/TableConfig/types';
import { IColumn, SortOrder } from 'components/UI/common/TypedTable/TypedTable';
import { currencyFormatter } from 'components/UI/common/TypedTable/formatters';
import { ColumnTypes } from 'components/UI/common/TypedTable/renderers';
import { useSelector } from 'react-redux';
import {
  getAllSequenceTypes,
  getForecastOppSplitViewFlag,
  getSfBaseUrl,
  getShowOrHideColumns,
  getUserLocalCurrency,
  isMulticurrencyEnabled,
} from 'selectors';

const TABLE_CONFIG_KEY = 'table_settings';

export const useGetSettingsForTable = (table: GetSettingsForTableParams) => {
  return useQuery({
    queryKey: [TABLE_CONFIG_KEY, table],
    queryFn: () => getSettingsForTable(table),
    staleTime: 1000 * 60 * 60 * 2,
  });
};

export interface ParseTableConfigurationColumnsProps {
  tableConfigurationColumns: TableConfigurationColumn[];
  hiddenColumns: string[];
  oppSplitViewToggleEnabled: boolean;
  riskMoreButtonLabel?: string;
  onSpecialClick: ColumnTypesCallback;
}

interface GetParsedColumnByTypeParams {
  onClick: ColumnTypesCallback;
  riskMoreButtonLabel?: string;
}

export const useManageTableConfigurationColumns = () => {
  const companyCurrency = useSelector(getUserLocalCurrency);
  const isMultiCurrencyEnabled = useSelector(isMulticurrencyEnabled);
  const sfBaseUrl = useSelector(getSfBaseUrl);
  const sequence = useSelector(getAllSequenceTypes);
  const oppSplitViewToggle = useSelector(getForecastOppSplitViewFlag);
  const showOrHideColumns = useSelector(getShowOrHideColumns);

  const currencyFormatterFunction = currencyFormatter(
    companyCurrency,
    0,
    isMultiCurrencyEnabled
  );

  const getParsedColumnByType = ({
    onClick,
    riskMoreButtonLabel,
  }: GetParsedColumnByTypeParams) =>
    columnTypes(
      {
        currencyFormatter: currencyFormatterFunction,
        sequence,
        riskMoreButtonLabel,
      },
      onClick,
      sfBaseUrl,
      isMultiCurrencyEnabled
    );

  const parseColumns = ({
    tableConfigurationColumns,
    hiddenColumns,
    oppSplitViewToggleEnabled,
    riskMoreButtonLabel,
    onSpecialClick,
  }: ParseTableConfigurationColumnsProps) => {
    const thereAreHiddenColumns = hiddenColumns && hiddenColumns.length;
    const parsedColumns: IColumn[] = tableConfigurationColumns.map(
      (tableConfigColumn, index) => {
        const parsedColumnByType = getParsedColumnByType({
          onClick: onSpecialClick,
          riskMoreButtonLabel,
        })(tableConfigColumn, tableConfigurationColumns);

        const partiallyParsed = {
          id: `${tableConfigColumn.field_name}-${index}`,
          hidden: thereAreHiddenColumns
            ? hiddenColumns.includes(tableConfigColumn.object_field)
            : tableConfigColumn.hidden,
          ...mapTableConfigurationColumnToIColumn(tableConfigColumn),
          ...parsedColumnByType,
        };

        const showTooltip = ![
          ColumnTypes.NUMBER,
          ColumnTypes.PERCENT,
          ColumnTypes.MONEY,
        ].includes(partiallyParsed.type);

        const shouldApplySplitViewToggle =
          partiallyParsed.config.isMoney && oppSplitViewToggleEnabled;

        const isEditable = shouldApplySplitViewToggle
          ? !oppSplitViewToggle
          : partiallyParsed.editable;

        return {
          ...partiallyParsed,
          showTooltip,
          editable: isEditable,
        };
      }
    );

    return parsedColumns;
  };

  const sortColumns = (columns: IColumn[]): IColumn[] => {
    if (showOrHideColumns && showOrHideColumns.length) {
      const allColumns = [...columns];
      // create orderedColumns base on the ordering of showOrHideColumns
      const newOrderedColumns = showOrHideColumns.reduce(
        (pre: IColumn[], showOrHideCol) => {
          const idx = allColumns.findIndex(
            (c) => c.label === showOrHideCol.label
          );
          if (idx !== -1) {
            pre = pre.concat(allColumns.splice(idx, 1));
          }
          return pre;
        },
        []
      );
      // in case new column is added, there might be left over items in allColumns
      // those column will be added to the end
      return newOrderedColumns.concat(allColumns);
    } else {
      // no order specified. do nothing
      return columns;
    }
  };

  return {
    parseColumns,
    sortColumns,
  };
};

const mapTableConfigurationColumnToIColumn = (
  tableConfigColumn: TableConfigurationColumn
) => ({
  field: tableConfigColumn.object_field,
  crm_field: tableConfigColumn.crm_field,
  editable: tableConfigColumn.editable,
  sort_order: SortOrder.DESCENDING,
  sortable: tableConfigColumn.sortable,
  advance: tableConfigColumn.advance ?? false,
  label: tableConfigColumn.display_name,
  fieldHeaderHighlight: !!tableConfigColumn.fieldHeaderHighlight,
});
