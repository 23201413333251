import ReactDialog from '../common/reactDialog';
import * as s from './styles';
import moment from 'moment';
import PropTypes from 'prop-types';
import * as _ from 'ramda';
import React, { Component } from 'react';

class ShareDialogContents extends Component {
  onClickCopyToClipboard = () => {
    const range = document.createRange();
    range.selectNode(this.detailsDl);
    window.getSelection().addRange(range);
    document.execCommand('copy');
    window.getSelection().empty();
  };

  render() {
    const { callDetail, handleCloseModal, callId } = this.props;
    return (
      <div id="callDescription">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="close pull-right"
              data-dismiss="modal"
              aria-label="Close"
              onClick={handleCloseModal}
            >
              <span aria-hidden="true">×</span>
            </button>
            <h4 className="modal-title" id="myModalLabel">
              Copy/Paste the call details and link below to share with other
            </h4>
            <div className="clearfix" />
          </div>
          <div className={s.modal_body}>
            <dl
              className="dl-horizontal"
              ref={(node) => {
                this.detailsDl = node;
              }}
            >
              <dt>Name:</dt>
              <dd>{callDetail.title}</dd>
              <dt>Account Executives:</dt>
              <dd>
                {_.join(
                  ', ',
                  _.map((user) => `${user.email}`, callDetail.attendees)
                )}
              </dd>
              <dt>Date:</dt>
              <dd>{moment(callDetail.start_date).format('LLL')}</dd>
              <dt>Duration:</dt>
              <dd>
                {moment
                  .duration(callDetail.duration, 'seconds')
                  .asMinutes()
                  .toFixed(0)}
                {' minutes'}
              </dd>
              <dt>Link to call:</dt>
              <dd>
                <a
                  href={`${process.env.REACT_APP_FRONTEND_URL}/transcript/${callId}`}
                >
                  {`${process.env.REACT_APP_FRONTEND_URL}/transcript/${callId}`}
                </a>
              </dd>
            </dl>
            <div className={s.btn_box}>
              <button
                className={s.btn_copy}
                type="button"
                onClick={this.onClickCopyToClipboard}
              >
                <span className="glyphicon glyphicon-copy" />
                &nbsp;Copy to Clipboard
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class ShareDialog extends Component {
  render() {
    const { handleCloseModal, isOpen, callDetail, pathname, callId } =
      this.props;

    return (
      <ReactDialog handleCloseModal={handleCloseModal} showModal={isOpen}>
        <ShareDialogContents
          callDetail={callDetail}
          pathname={pathname}
          callId={callId}
        />
      </ReactDialog>
    );
  }
}

ShareDialog.defaultProps = {
  callDetail: PropTypes.shape({
    attendees: PropTypes.array,
    audio_url: PropTypes.string,
    competitors_discussed: PropTypes.array,
    description: PropTypes.string,
    duration: PropTypes.number,
    id: PropTypes.string,
    my_tags: PropTypes.array,
    opportunity_name: PropTypes.string,
    other_tags: PropTypes.array,
    segments: PropTypes.array,
    start: PropTypes.string,
    title: PropTypes.string,
    topics_discussed: PropTypes.array,
  }),
  callId: PropTypes.string,
  handleCloseModal: PropTypes.func,
  isOpen: PropTypes.bool,
};

ShareDialog.propTypes = {
  callDetail: PropTypes.shape({
    attendees: PropTypes.array,
    audio_url: PropTypes.string,
    competitors_discussed: PropTypes.array,
    description: PropTypes.string,
    duration: PropTypes.number,
    id: PropTypes.string,
    my_tags: PropTypes.array,
    opportunity_name: PropTypes.string,
    other_tags: PropTypes.array,
    segments: PropTypes.array,
    start: PropTypes.string,
    title: PropTypes.string,
    topics_discussed: PropTypes.array,
  }),
  callId: PropTypes.string,
  handleCloseModal: PropTypes.func,
  isOpen: PropTypes.bool,
};

ShareDialogContents.defaultProps = {
  callDetail: PropTypes.shape({
    attendees: PropTypes.array,
    audio_url: PropTypes.string,
    competitors_discussed: PropTypes.array,
    description: PropTypes.string,
    duration: PropTypes.number,
    id: PropTypes.string,
    my_tags: PropTypes.array,
    opportunity_name: PropTypes.string,
    other_tags: PropTypes.array,
    segments: PropTypes.array,
    start: PropTypes.string,
    title: PropTypes.string,
    topics_discussed: PropTypes.array,
  }),
  callId: PropTypes.string,
  handleCloseModal: PropTypes.func,
};

ShareDialogContents.propTypes = {
  callDetail: PropTypes.shape({
    attendees: PropTypes.array,
    audio_url: PropTypes.string,
    competitors_discussed: PropTypes.array,
    description: PropTypes.string,
    duration: PropTypes.number,
    id: PropTypes.string,
    my_tags: PropTypes.array,
    opportunity_name: PropTypes.string,
    other_tags: PropTypes.array,
    segments: PropTypes.array,
    start: PropTypes.string,
    title: PropTypes.string,
    topics_discussed: PropTypes.array,
  }),
  callId: PropTypes.string,
  handleCloseModal: PropTypes.func,
};

export default ShareDialog;
