import React, { useEffect, useState, ReactNode } from 'react';
import { toast } from 'react-toastify';

import * as styles from 'components/UI/EventsTable/styles';
import ExpandibleItems from 'components/UI/ExpandibleItems';
import Table from 'components/UI/TableConfig/Table';
import Tooltip from 'components/UI/common/Tooltip';
import {
  IColumn,
  IRow,
  SortOrder,
} from 'components/UI/common/TypedTable/TypedTable';
import { ColumnTypes } from 'components/UI/common/TypedTable/renderers';
import { CustomCellConfig } from 'components/UI/common/TypedTable/renderers/CustomCell';
import { IProps } from 'components/modals/EventsModal/types';
import { ModalLink } from 'navigation/Link';
import { fetchApi, QueryStatus } from 'utils/network';

const renderEmailsCell = (data: any) => {
  if (!data) {
    return null;
  }

  const options = typeof data === 'string' ? [data] : data;

  return (
    <ExpandibleItems
      max={2}
      items={(options as any).map((item: ReactNode) => (
        <Tooltip position="bottom center" tooltip={item}>
          <div
            style={{
              padding: '10px 0',
              maxWidth: '250px',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            {item}
            <br />
          </div>
        </Tooltip>
      ))}
    />
  );
};

const renderLinksCell = (rowObject: any, field: 'deal' | 'account') => {
  if (rowObject && rowObject.length) {
    return (
      <div>
        {rowObject.map((item: { _id: string; name: string }, index: number) => (
          <div key={item._id}>
            <ModalLink
              key={item._id}
              url={{
                scheme: `/${field}/:id` as any,
                params: { id: item._id },
              }}
              className={styles.name_field_name}
            >
              {item.name}
            </ModalLink>
            {rowObject.length !== 1 && index !== rowObject.length ? (
              <br />
            ) : null}
          </div>
        ))}
      </div>
    );
  } else {
    return <div>-</div>;
  }
};

const COLUMNS: IColumn[] = [
  {
    id: 'received_at',
    label: 'date',
    field: 'received_at',
    type: ColumnTypes.DATE,
    sort_order: SortOrder.ASCENDING,
    sortable: false,
    config: {
      format: 'DD MMM YYYY, h:mm A',
    },
  },
  {
    id: 'subject',
    label: 'subject',
    field: 'subject',
    width: 250,
    type: ColumnTypes.CUSTOM,
    sort_order: SortOrder.ASCENDING,
    sortable: false,
    config: {
      renderer: ({ row }) => (
        <ModalLink
          key={row._id as string}
          url={{
            scheme: '/email/:emailId',
            params: { emailId: row._id as string },
          }}
          className={styles.name_field_name}
        >
          {row.subject}
        </ModalLink>
      ),
      className: 'actionColumn',
    } as CustomCellConfig,
  },
  {
    id: 'opportunities',
    label: 'Deal Name',
    field: 'opportunities',
    type: ColumnTypes.CUSTOM,
    sort_order: SortOrder.ASCENDING,
    sortable: false,
    config: {
      renderer: ({ row }) => renderLinksCell(row['opportunities'], 'deal'),
      className: 'actionColumn',
    } as CustomCellConfig,
  },
  {
    id: 'accounts',
    label: 'Account',
    field: 'accounts',
    type: ColumnTypes.CUSTOM,
    sort_order: SortOrder.ASCENDING,
    sortable: false,
    config: {
      renderer: ({ row }) => renderLinksCell(row['accounts'], 'account'),
      className: 'actionColumn',
    } as CustomCellConfig,
  },
  {
    id: 'sender',
    label: 'from',
    field: 'sender',
    type: ColumnTypes.CUSTOM,
    sort_order: SortOrder.ASCENDING,
    sortable: false,
    config: {
      renderer: ({ row }) => renderEmailsCell(row['sender']),
    } as CustomCellConfig,
  },
  {
    id: 'recepients',
    label: 'to',
    field: 'recepients',
    type: ColumnTypes.CUSTOM,
    sort_order: SortOrder.ASCENDING,
    sortable: false,
    config: {
      renderer: ({ row }) => renderEmailsCell(row['recepients']),
    } as CustomCellConfig,
  },
];

const ROWS_PER_PAGE = 50;

const EmailsModal = ({ modalProps }: IProps) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [apiStatus, setApiStatus] = useState<QueryStatus>();
  const [data, setData] = useState<{ count: number; rows: IRow[] }>({
    count: 0,
    rows: [],
  });

  useEffect(() => {
    const signalAbort = new AbortController();
    if (modalProps.apiUrl && modalProps.filters) {
      fetchApi({
        url: modalProps.apiUrl,
        queryMethod: 'post',
        queryParams: {
          ...JSON.parse(modalProps.filters),
          page_size: ROWS_PER_PAGE,
          page_number: pageNumber - 1,
        },
        setStatus: setApiStatus,
        setData: ({ data }: any) => {
          setData({
            rows: data.emails,
            count: data.count,
          });
        },
        setError: () => toast.error('Failed to load.'),
        signal: signalAbort.signal,
      });
    }
    return () => signalAbort.abort();
  }, [modalProps.apiUrl, modalProps.filters, pageNumber]);

  const handlePaginationChange = (page: number) => {
    setPageNumber(page);
  };

  return (
    <div style={{ marginBottom: '24px' }}>
      <Table
        title={'Emails'}
        columns={COLUMNS}
        data={data.rows}
        loading={apiStatus === 'loading'}
        currentPage={pageNumber}
        hidePaginationEnd
        rowsPerPage={ROWS_PER_PAGE}
        totalCount={data.count}
        onPaginationChange={handlePaginationChange}
        hideSearch
      />
    </div>
  );
};

export default EmailsModal;
