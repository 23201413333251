import 'abortcontroller-polyfill/dist/abortcontroller-polyfill-only';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { toast, ToastOptions } from 'react-toastify';

import { actions } from 'actions';
import { PersistedTableItem } from 'components/UI/DealsFlatTableTS/Table/types';
import Table from 'components/UI/TableConfig/Table';
import { IColumn, IRow } from 'components/UI/common/TypedTable/TypedTable';
import { AnalyticsTracker } from 'components/common/analyticsUtils';
import { tableConfigs } from 'components/dashboard/RepsRecommendations/configs';
import { rTableContainer } from 'components/dashboard/RepsRecommendations/styles';
import {
  Dismissals,
  RTableApiResponse,
  RTableData,
  RTableOwnProps,
  RTableProps,
  ToastType,
} from 'components/dashboard/RepsRecommendations/types';
import {
  abortControllers,
  selectDismissalMessage,
  selectTableTitle,
} from 'components/dashboard/RepsRecommendations/utils';
import { IReduxState } from 'reducers/types';
import { isAdmin } from 'selectors';
import { getTablePrevState } from 'selectors/table';
import { fetchApi, QueryStatus } from 'utils/network';

const dismissApiPoint = `${process.env.REACT_APP_BACKEND_URL}/api/data/recommendations/dismiss`;
const toastOptions: ToastOptions = { position: 'bottom-left' };

const RTable: React.FC<RTableProps> = ({
  isAdmin,
  queryParams,
  setQueryParams,
  tableName,
}: RTableProps) => {
  const {
    apiPoint,
    defaultQueryParams,
    getColumnsConfig,
    initialState,
    tableMinWidth,
  } = tableConfigs[tableName];

  const {
    page_number = 1,
    page_size = 50,
    sort,
  } = 'filter' in queryParams ? queryParams.filter : defaultQueryParams;

  const serializedQueryParams = JSON.stringify({
    page_number,
    page_size,
    sort,
  });

  const [dismissals, setDismissals] = useState<Dismissals>({ ds: [], sn: 0 });
  const [tableData, setTableData] = useState<RTableData>(initialState);
  const [tableDataStatus, setTableDataStatus] =
    useState<QueryStatus>('notAsked');

  useEffect((): void => {
    if (tableDataStatus === 'notAsked') {
      const controller = new AbortController();

      const setData = ({ data }: RTableApiResponse) => {
        delete abortControllers[tableName].dt;
        setTableData(data);
      };

      const setError = (error: string | null) => {
        delete abortControllers[tableName].dt;
        toast.error(
          `Fetching data for ${tableName} failed: ${error}`,
          toastOptions
        );
      };

      fetchApi<string, RTableApiResponse>({
        queryParams: serializedQueryParams,
        setData,
        setError,
        setStatus: setTableDataStatus,
        signal: controller.signal,
        url: apiPoint,
      });

      abortControllers[tableName].dt = controller;
    }
  }, [apiPoint, serializedQueryParams, tableDataStatus, tableName]);

  const handleDismiss = useCallback(
    ({ id }: IRow) => {
      const controller = new AbortController();

      const getResponseHandler = (status: ToastType) => () => {
        delete abortControllers[tableName][`ds-${id}`];
        setDismissals((prev) => ({
          ds: [...prev.ds, { id, status }],
          sn: Date.now(),
        }));
      };

      fetchApi<string, undefined>({
        queryParams: JSON.stringify({ dismiss_until: 'forever', id }),
        setData: getResponseHandler('success'),
        setError: getResponseHandler('error'),
        signal: controller.signal,
        url: dismissApiPoint,
      });

      abortControllers[tableName][`ds-${id}`] = controller;
    },
    [tableName]
  );

  useEffect(() => {
    const { ds, sn } = dismissals;

    ds.forEach(({ id, status }) => {
      if (status === 'success') {
        const { total_count, recommendations } = tableData;

        setTableData({
          total_count: total_count - 1,
          recommendations: recommendations.filter((r) => r.id !== id),
        });
      }

      toast[status](selectDismissalMessage[status], toastOptions);
    });

    setDismissals({ ds: [], sn });
  }, [dismissals.sn, tableName]);

  useEffect(() => {
    AnalyticsTracker.event(
      { isAdmin, page_number, page_size, sort, tableName },
      {
        action: `Open ${tableName}`,
        category: 'Reps Recommendation',
        label: 'Table opened',
      }
    );

    return () => {
      const controllers = Object.keys(abortControllers[tableName]);

      controllers.forEach((c) => {
        abortControllers[tableName][c].abort();
        delete abortControllers[tableName][c];
      });
    };
  }, [tableName]);

  const columns: IColumn[] = useMemo(
    () =>
      getColumnsConfig({
        compose: () => {},
        dismiss: handleDismiss,
      }),
    [tableName]
  );

  const handleQueryParamsChange = useCallback(
    (pageNumber, pageSize, sortOrder) => {
      const newQueryParams: PersistedTableItem = {
        name: tableName,
        type: 'common',
        filter: {
          page_number: pageNumber,
          page_size: pageSize,
          sort: sortOrder,
        },
      };

      setQueryParams(newQueryParams);
      setTableDataStatus('notAsked');
    },
    [tableName]
  );

  const handleSortChange = useCallback(
    (sortOrder = defaultQueryParams.sort) => {
      handleQueryParamsChange(1, page_size, sortOrder);
    },
    [handleQueryParamsChange, page_size]
  );

  const handlePaginationChange = useCallback(
    (pageNumber: number, pageSize: number) =>
      handleQueryParamsChange(pageNumber, pageSize, sort),
    [handleQueryParamsChange, sort]
  );

  const isError = tableDataStatus === 'error';
  const isLoading = tableDataStatus === 'loading';
  const { total_count } = tableData;

  return (
    <div className={rTableContainer} data-cypress="reps-recommendations-table">
      {!isError && (
        <Table
          fullscreen
          title={selectTableTitle[tableName]}
          columns={columns}
          data={tableData.recommendations}
          loading={isLoading}
          currentPage={page_number}
          rowsPerPage={page_size}
          totalCount={total_count}
          onPaginationChange={handlePaginationChange}
          hidePaginationEnd
          sortOrder={sort}
          onSort={handleSortChange}
          hideSearch
        />
      )}
    </div>
  );
};

const mapDispatchToProps = {
  setQueryParams: actions.ui.table.persist,
};

const mapStateToProps = (state: IReduxState, props: RTableOwnProps) => ({
  isAdmin: isAdmin(state),
  queryParams: getTablePrevState(state, 'common', props.tableName),
});

export default connect(mapStateToProps, mapDispatchToProps)(RTable);
