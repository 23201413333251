import Table from 'components/UI/TableConfig/Table';
import {
  IRow,
  onChangeCallback,
} from 'components/UI/common/TypedTable/TypedTable';
import { useManageRowStatuses } from 'components/hooks/useManageRowStatuses';
import { css } from 'emotion';
import { useSelector } from 'react-redux';
import { getShowOrHideColumns } from 'selectors';
import {
  UseManageRevBiDealsModalParams,
  getDownloadButtonProps,
  useManageRevBiDealsModal,
} from './RevBiDealsModal.helper';

import { isAxiosError } from 'axios';
import { DownloadButtonProps } from 'components/UI/DownloadButton/types';
import { useRevBiDrilldownModalWithScheme } from 'components/dashboard/Metrics/Widget/hooks/useRevBiDrilldownModal';
import { useManageTableConfigurationColumns } from 'components/hooks/useSettingsForTable';
import { toast } from 'react-toastify';

const DEALS_PAGE_SIZE = 50;

const modalHeight = css`
  height: 80vh;
`;

const RevBiDealsModal = () => {
  const { getProps, markShouldReFetchOnClose } =
    useRevBiDrilldownModalWithScheme('/revbi-deals-modal');

  const { ids, skip_business_validation, business_type_name, title } =
    getProps();
  const showOrHideColumns = useSelector(getShowOrHideColumns);
  const {
    rowStatuses,
    setLoadingRowField,
    setSuccessRowField,
    setErrorRowField,
  } = useManageRowStatuses();

  const { parseColumns, sortColumns } = useManageTableConfigurationColumns();

  const params: UseManageRevBiDealsModalParams = {
    page_size: DEALS_PAGE_SIZE,
    only_included_deals: false,
    skip_business_validation,
    ids,
    business_type_name,
  };

  const {
    deals,
    dealsColumns,
    isLoading,
    dealsCount,
    updateDeal,
    pageNumber,
    handlePaginationChange,
    sortOrder,
    handleSort,
  } = useManageRevBiDealsModal(params, {
    doNotInvalidateOnSettled: true,
  });

  const columns =
    dealsColumns &&
    parseColumns({
      tableConfigurationColumns: dealsColumns,
      hiddenColumns: [],
      oppSplitViewToggleEnabled: false,
      onSpecialClick: () => {},
    });

  const sortedColumns = columns && sortColumns(columns);

  const rows: IRow[] =
    deals?.map((d) => ({
      id: d._id,
      ...d,
    })) ?? [];

  const handleChange: onChangeCallback = async (column, row, newValue) => {
    const dealId = row.id.toString();
    const fieldToUpdate = column.field;
    const updateOperation = {
      [fieldToUpdate]: newValue,
    };

    setLoadingRowField(dealId, fieldToUpdate);

    try {
      await updateDeal({
        id: dealId,
        editedFields: updateOperation,
        origin: 'revbi',
      });

      toast.success('Deal updated successfully');
      setSuccessRowField(dealId, fieldToUpdate);
      markShouldReFetchOnClose();
    } catch (e) {
      const errorMessage =
        isAxiosError(e) && e.response?.data?.error?.message?.[0];
      toast.error(errorMessage || 'Failed to update deal');
      setErrorRowField(dealId, fieldToUpdate);
    }
  };

  const downloadButton: DownloadButtonProps = getDownloadButtonProps(params);

  // If you modify the layout remember to leave the data-testing attribute for automation
  return (
    <div className={modalHeight} data-testing="deals-modal">
      <Table
        title={title}
        totalCount={dealsCount}
        columns={sortedColumns || []}
        data={rows}
        loading={isLoading}
        showOrHideColumns={showOrHideColumns}
        onChange={handleChange}
        statuses={rowStatuses}
        rowsPerPage={DEALS_PAGE_SIZE}
        currentPage={pageNumber}
        onPaginationChange={handlePaginationChange}
        onSort={handleSort}
        sortOrder={sortOrder}
        downloadButton={downloadButton}
        showTotalAmount={false}
        dataType="deals"
        showColumnsVisibilityToggle
        hidePaginationEnd
        hideSearch
        fullscreen
        isModal
      />
    </div>
  );
};

export default RevBiDealsModal;
