import axiosInstance from './axiosInstance';

import { TableConfigurationData } from 'components/UI/TableConfig/types';
import { getDrilldownUrlQueryParams } from 'components/dashboard/Metrics/Widget/metrics.widget.helpers';
import { DrilldownQueryParams } from 'components/modals/types';

/**
 * This type represents an item of the 'drilldown' for custom objects feature
 * where we show a list of items (like a table) with the data of the custom object
 * each item has a list of properties that we want to show, according to
 * the columns configured that comes from the be
 */

export interface CustomObjectDrilldownItem {
  id: string;
  [key: string]: any;
}

export interface CustomObjectDrilldownDataResponse {
  data: CustomObjectDrilldownItem[];
  count: number;
}

export interface GetDrilldownColumnsResponse {
  data: TableConfigurationData;
}

export interface GetDrilldownDataForMetricObjectReturn {
  items: CustomObjectDrilldownItem[];
  count: number;
}

const getIdFromItem = (item: CustomObjectDrilldownItem) => {
  const hasIdInUnderScore = !!item._id;
  const hasIdInUpperCase = !!item.Id;
  switch (true) {
    case hasIdInUnderScore:
      return item._id;
    case hasIdInUpperCase:
      return item.Id;
    default:
      return item.id;
  }
};

export type GetDrilldownDataParams = Omit<
  DrilldownQueryParams,
  'point_in_time' | 'target_time_period' | 'time_interval' | 'time_period'
>;

/**
 * For now this is only used for the custom objects, but it could be used for any metric object
 * So we should slowly migrate the usage to this function
 * In case you are migrating it, remember to add the correct types for the response
 * as the structure could be different that the one we are using now (only for custom objects)
 */
export const getDrilldownDataForMetricObject = async (
  metricObject: string,
  params: GetDrilldownDataParams
): Promise<GetDrilldownDataForMetricObjectReturn> => {
  const drilldownUrlQueryParams = getDrilldownUrlQueryParams(params);
  const url = `${process.env.REACT_APP_BACKEND_URL}/rev_bi/drill_down/${metricObject}/data${drilldownUrlQueryParams}`;
  const response = await axiosInstance.post<CustomObjectDrilldownDataResponse>(
    url,
    params
  );

  return {
    items: response.data.data.map((item) => ({
      ...item,
      id: getIdFromItem(item),
    })),
    count: response.data.count,
  };
};

export const getDrilldownColumns = async (apiUrl: string) => {
  const response = await axiosInstance.get<GetDrilldownColumnsResponse>(apiUrl);

  return response.data.data;
};

export type GetDrilldownDataForTablePayload = { [fieldToUpdate: string]: any };

export const updateObjectField = async (
  tableName: string,
  objectId: string,
  updateOperation: GetDrilldownDataForTablePayload
) => {
  const url = `${process.env.REACT_APP_BACKEND_URL}/api/settings/custom_tables/update/${tableName}/${objectId}?origin=revbi`;
  const res = await axiosInstance.post(url, updateOperation);

  return res.data;
};
