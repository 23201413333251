import classNames from 'classnames';
import merge from 'lodash/merge';
import React, { useEffect, useMemo, useState } from 'react';

import BuButton, { BuControlSize } from 'components/UI/BuButton';
import QuickView from 'components/UI/Modal/QuickView';
import Table from 'components/UI/TableConfig/Table';
import {
  IColumn,
  IRow,
  ValueProp,
} from 'components/UI/common/TypedTable/TypedTable';
import { getColumns } from 'components/settings/ManageUsers/UsersAddView/AddUserSteps/UserReviewList/helpers';
import { IProps } from 'components/settings/ManageUsers/UsersAddView/AddUserSteps/UserReviewList/types';
import * as s from 'components/settings/ManageUsers/UsersAddView/styles';
import UsersBulkUpdate from 'components/settings/ManageUsers/UsersBulkUpdate';
import {
  UpdatedFields,
  IProps as BulkProps,
} from 'components/settings/ManageUsers/UsersBulkUpdate/types';
import {
  isUsersMarked,
  typedRow,
  typedRows,
} from 'components/settings/ManageUsers/helpers';
import {
  addButtonColor,
  tableActiveRowColor,
} from 'components/settings/ManageUsers/styles';
import { RowField } from 'components/settings/ManageUsers/types';

const UserReviewList: React.FC<IProps> = ({
  currentUserRole,
  managersOptions,
  data,
  managers,
  onPrevStepClick,
  onNextStepClick,
}) => {
  const [rows, setRows] = useState<IRow[]>([]);
  const [bulkState, setBulkState] = useState<BulkProps['defaultValues']>({
    role: undefined,
    manager: undefined,
    status: undefined,
    profile: undefined,
    emailSync: true,
    calendarSync: true,
    callsSync: true,
    impersonation: undefined,
  });

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(50);

  const [searchText, setSearchText] = useState('');
  const [isBulkChangeVisible, setBulkChangeVisible] = useState(false);

  const canSave = useMemo(
    () => !rows.some((row) => !(row as RowField).role),
    [rows]
  );

  useEffect(() => {
    setRows(data);
  }, [data]);

  const handleChangeRow = (
    column: IColumn,
    row: IRow,
    newValue: null | ValueProp
  ) => {
    const email = typedRow(row).email;
    setRows(
      typedRows(rows).map((item) =>
        item.email === email
          ? {
              ...item,
              [column.field]: newValue,
            }
          : item
      )
    );
  };

  const handlePaginationChange = (page: number, pageSize: number) => {
    setPageNumber(page);
    setPageSize(pageSize);
  };

  const handleSearchChange = (text: string) => {
    setSearchText(text);
    setPageNumber(1);
  };

  const handleBulkClick = () => {
    setBulkChangeVisible(true);
  };

  const handleBulkCloseClick = () => {
    setBulkChangeVisible(false);
  };

  const handleBulkChange = (updatedFields: UpdatedFields) => {
    setBulkChangeVisible(false);

    setBulkState({
      role: updatedFields.role,
      manager: updatedFields.manager,
      status: updatedFields.status,
      profile: updatedFields.profileId
        ? { id: updatedFields.profileId, name: '' }
        : undefined,
      emailSync: updatedFields.user_settings.processing.emails,
      calendarSync: updatedFields.user_settings.processing.calendar,
      callsSync: updatedFields.user_settings.processing.calls,
      impersonation: updatedFields.is_user_impersonation_allowed,
    });

    setRows(typedRows(rows).map((row) => merge({}, row, updatedFields)));
  };

  return (
    <>
      <div
        className={classNames(
          s.mainContent,
          addButtonColor,
          tableActiveRowColor
        )}
      >
        <Table
          columns={getColumns({ currentUserRole, managersOptions })}
          title="Users"
          data={(rows as RowField[])
            .filter(isUsersMarked(managers, searchText))
            .slice((pageNumber - 1) * pageSize, pageNumber * pageSize)}
          onChange={handleChangeRow}
          rowsPerPage={pageSize}
          currentPage={pageNumber}
          totalCount={rows.length}
          hidePaginationEnd
          paginationOptions={[25, 50, 100]}
          onPaginationChange={handlePaginationChange}
          searchPlaceholder="Search"
          onSearchChange={handleSearchChange}
          fullscreen
          controls={
            <BuButton size={BuControlSize.SMALL} onClick={handleBulkClick}>
              Bulk Update
            </BuButton>
          }
        />
      </div>

      <div className={s.actionButtons}>
        <BuButton
          secondary
          size={BuControlSize.REGULAR}
          onClick={onPrevStepClick}
        >
          Back
        </BuButton>
        <BuButton
          disabled={!canSave}
          size={BuControlSize.REGULAR}
          onClick={() => onNextStepClick(rows)}
        >
          Add
        </BuButton>
      </div>

      {isBulkChangeVisible && (
        <QuickView onClose={handleBulkCloseClick} size="small">
          <UsersBulkUpdate
            onSave={handleBulkChange}
            onCancel={handleBulkCloseClick}
            defaultValues={bulkState}
          />
        </QuickView>
      )}
    </>
  );
};

export default UserReviewList;
