import { CUSTOM_EXTRA_TYPE } from '../TableConfig/CustomDealNameCell';
import TwoColumnsDashboard from '../TwoColumnsDashboard';
import { ColumnTypes } from '../common/TypedTable/renderers';
import { getFieldValue } from '../common/TypedTable/renderers/custom/common';
import { updateDealsWithUpdatesInProgress } from './DealsTable.helper';
import * as styles from './styles';
import 'abortcontroller-polyfill/dist/abortcontroller-polyfill-only';
import isFunction from 'lodash/isFunction';
import * as R from 'ramda';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast, ToastOptions } from 'react-toastify';

import { syncDeal } from 'actions/dealsActions';
import {
  mapChangesSinceDataForDeals,
  mapIncludedSummaryForDeal,
} from 'common/forecastSubmission';
import { formatMoney } from 'common/numbers';
import {
  IProps,
  TFilterTogglerState,
  DealsRequestState,
} from 'components/UI/DealsFlatTableConfig/types';
import { getDeltaValue } from 'components/UI/DealsFlatTableTS/Table/Row/DeltaValue';
import { DownloadButtonProps } from 'components/UI/DownloadButton/types';
import Table from 'components/UI/TableConfig';
import { DisplayedColumns } from 'components/UI/TableConfig/TableControls';
import {
  ColumnTypesCallback,
  handleColumnClick,
} from 'components/UI/TableConfig/column-helper';
import {
  isColumnConfigSalesProcess,
  onReadyCallback,
  TableConfigurationData,
  TableConfigurationColumn,
} from 'components/UI/TableConfig/types';
import {
  IColumn,
  IRow,
  onChangeCallback,
  ValueProp,
  CellStatus,
} from 'components/UI/common/TypedTable/TypedTable';
import MeddicSidePanel, {
  StepWithValue,
} from 'components/dashboard/Forecast/MeddicSidePanel/MeddicSidePanel';
import SidePanel from 'components/dashboard/Forecast/SidePanel';
import { useGetIncludeSummaryForSubmissionAndDeals } from 'components/hooks/useGetIncludeSummaryForSubmission';
import { getUserLocalCurrency } from 'selectors';
import { QueryStatus, fetchApi } from 'utils/network';

const { AbortController } = window;
const toastOptions: ToastOptions = { position: 'bottom-left' };

const DealsTable: React.FC<IProps> = ({
  apiUrl = '/api/data/deals/',
  changeDeal,
  dataType,
  describeFields,
  forceRefresh,
  forecastTable,
  hideHeader,
  inModal,
  initialRequest,
  isIncludedDeals,
  isHistoricalSubmissionsModal,
  onPageSizeChange,
  onRequestChange,
  persistName,
  persistTableParams,
  persistedState,
  setDealsCount,
  sortDealsCachedState,
  statuses,
  tableConfigCollection,
  tableConfigName,
  amountFieldName,
  title,
  draftData,
  updateDraftData,
  setDealsIncludedCache,
  dealsIncludedCache,
  filterIncludedTogglerInitialState = 'off',
  controls,
  isReadOnlyUser,
  isTableConfigurationLoading,
  styleFirstColumn = true,
  overrideAmount,
  localStorageSortColumn = '',
  submissionSetting,
  isRealTimeEnabledForTable = false,
  onIncludedDealsChange,
  renderTitleExtra,
  onCloseModal,
  onDealsDataChanged,
  onSortChangePersistLocally,
  forceBasicFilters,
  showTotalAmount,
  extendedCSVDownload,
  origin = '',
}) => {
  const [isReady, setIsReady] = useState(false);
  const [dealsStatus, setDealsStatus] = useState<QueryStatus>('loading'); // we need to wait onReady callback
  const [searchValue, setSearchValue] = useState<string>('');

  const [dealsState, setDeals] = useState<DealsRequestState>({
    deals: [],
    count: 0,
    total_amount: 0,
  });

  const [filterIncluded, setFilterIncluded] = useState<TFilterTogglerState>(
    filterIncludedTogglerInitialState
  );
  const [visiblePanel, setVisiblePanel] = useState<string>('');
  const [salesProcessName, setSalesProcessName] = useState<string>('');
  const [salesProcessVersion, setSalesProcessVersion] = useState<number>(0);
  const [medicSteps, setMeddicSteps] = useState<StepWithValue[]>([]);
  const [sidePanelDeal, setSidePanelDeal] = useState<Deals.Deal>();
  const [sidePanelTitle, setSidePanelTitle] = useState<string>('');

  const companyCurrencyCode = useSelector(getUserLocalCurrency);

  const defaultQueryParams = {
    page_number: 0,
    page_size: isIncludedDeals ? 50 : 10,
    sort: '',
    only_included_deals: filterIncludedTogglerInitialState === 'on',
  };

  const queryParams = {
    ...defaultQueryParams,
    ...initialRequest,
    ...sortDealsCachedState.filter,
    ...persistedState,
    is_forecast: forecastTable,
    sort: localStorageSortColumn,
    force_basic_filters: forceBasicFilters,
  };

  const included_deals = initialRequest.included_deals;
  const submissionId = apiUrl.split('/').pop() || '';

  let {
    page_number = defaultQueryParams.page_number,
    page_size = defaultQueryParams.page_size,
    sort = defaultQueryParams.sort,
  } = queryParams;

  const serializedQueryParams = JSON.stringify(queryParams);
  const isError = dealsStatus === 'error';
  const isLoading = dealsStatus === 'loading';

  const dealsIds = useMemo(
    () => dealsState.deals.map((d) => d._id),
    [dealsState]
  );

  const hasToFetchIncludeSummaryExtraCondition =
    !!isIncludedDeals && !!submissionSetting;

  const { isDealsIncludedSummaryLoading, dealsIncludedSummary } =
    useGetIncludeSummaryForSubmissionAndDeals(
      submissionSetting,
      dealsIds,
      hasToFetchIncludeSummaryExtraCondition
    );

  useEffect(() => {
    describeFields();
  }, []);

  useEffect(() => {
    if (!isReady) {
      return;
    }

    const abortController = new AbortController();

    const setError = (error: string | null) => {
      toast.error(`Fetching data failed: ${error}`, toastOptions);
    };

    fetchApi<string, { data: DealsRequestState }>({
      queryParams: serializedQueryParams,
      setData: ({ data }) => {
        setDeals(data);
      },
      setError,
      setStatus: setDealsStatus,
      signal: abortController.signal,
      url: apiUrl,
    });

    return () => {
      if (abortController) {
        abortController.abort();
      }
    };
  }, [isReady, forceRefresh, serializedQueryParams, apiUrl]);

  useEffect(() => {
    if ((dealsState?.total_amount ?? false) && onDealsDataChanged) {
      onDealsDataChanged(dealsState);
    }
  }, [dealsState?.total_amount]);

  useEffect(() => {
    setDealsIncludedCache(included_deals, submissionId);
  }, []);

  useEffect(() => {
    if (setDealsCount) {
      setDealsCount(title, dealsState.count);
    }
  }, [dealsState.count]);

  useEffect(() => {
    const includedDeals = dealsState.deals.map((deal) => {
      return {
        ...deal,
        included_in_submission: dealsIncludedCache[submissionId]?.includes(
          deal._id
        ),
      };
    });

    setDeals({
      deals: includedDeals,
      count: dealsState.count,
      total_amount: dealsState.total_amount,
    });
  }, [JSON.stringify(dealsIncludedCache[submissionId])]);

  useEffect(() => {
    const dealIds = Object.keys(statuses);
    const updatedDeals = dealsState.deals.map((deal) => {
      if (dealIds.includes(deal._id)) {
        const fieldNames = Object.keys(statuses[deal._id]);
        const cellStatusDetail = statuses?.[deal._id] as CellStatus;

        return fieldNames.reduce((acc, fieldName) => {
          if (cellStatusDetail?.[fieldName]?.status === 'success') {
            return R.assocPath(
              fieldName.split('.'),
              cellStatusDetail[fieldName].value,
              acc
            );
          }
          return acc;
        }, deal);
      }

      return deal;
    });

    setDeals({
      ...dealsState,
      deals: updatedDeals,
    });

    const updatedDeal = updatedDeals.find(
      (deal) => deal._id === sidePanelDeal?._id
    );

    setSidePanelDeal(updatedDeal);

    if (updatedDeal) {
      setMeddicSteps(
        medicSteps.map((step) => ({
          ...step,
          value: getDeltaValue(
            getFieldValue(step.object_field, updatedDeal as unknown as IRow)
          ),
        }))
      );
    }
  }, [JSON.stringify(statuses)]);

  const handleQueryParamsChange = useCallback(
    (
      pageNumber,
      pageSize,
      sortOrder,
      newSearchValue = '',
      onlyIncludedDeals?
    ) => {
      const name = persistName || title;
      const type = persistName ? 'common' : 'deals';
      const only_included_deals = !R.isNil(onlyIncludedDeals)
        ? onlyIncludedDeals
        : filterIncluded === 'on';

      const newQueryParams = {
        ...queryParams,
        page_number: pageNumber,
        page_size: pageSize,
        sort: sortOrder,
        only_included_deals,
        ...(included_deals ? { included_deals } : {}),
      };

      if (isFunction(onRequestChange)) {
        onRequestChange({
          ...newQueryParams,
          opportunity_name: newSearchValue || searchValue,
        });
      } else {
        persistTableParams({
          filter: newQueryParams, // inModal ? { ...queryParams } : newQueryParams,
          name,
          type,
        });
      }

      if (isFunction(onPageSizeChange)) {
        onPageSizeChange(pageSize);
      }

      setDealsStatus('loading');
    },
    [persistName, serializedQueryParams, title, filterIncluded, searchValue]
  );

  const handleReady: onReadyCallback = useCallback(
    ({ order: { object_field, direction } }) => {
      setIsReady(true);
      if (sort) {
        setDealsStatus('loading');
        return;
      }

      const sortOrder = object_field
        ? `${direction === -1 ? '-' : ''}${object_field}`
        : '';

      handleQueryParamsChange(page_number, page_size, sortOrder);
    },
    [page_number, page_size, sort, handleQueryParamsChange]
  );

  const handleSort = useCallback(
    (sortOrder?: string) => {
      if (onSortChangePersistLocally) {
        onSortChangePersistLocally(sortOrder as string);
      }

      handleQueryParamsChange(page_number, page_size, sortOrder);
    },
    [page_number, page_size, handleQueryParamsChange]
  );

  const handleChange = useCallback(
    (column: IColumn, row: IRow, newValue: ValueProp) => {
      if (column.field !== 'included_in_submission') {
        changeDeal(
          row.id as string,
          {
            [column.field]: newValue,
          },
          isRealTimeEnabledForTable,
          origin
        );
      }

      if (
        column.field === 'included_in_submission' &&
        dealsIncludedCache[submissionId]
      ) {
        const newCachedIds = dealsIncludedCache[submissionId];

        if (row.included_in_submission) {
          const index = dealsIncludedCache[submissionId].indexOf(
            row.id as string
          );

          if (index > -1) {
            newCachedIds.splice(index, 1);
          }
        } else {
          newCachedIds.push(row.id as string);
        }
        setDealsIncludedCache(newCachedIds, submissionId);
      }
    },
    [dealsIncludedCache, isRealTimeEnabledForTable]
  );

  const handleIncludeAllDeals = useCallback(
    (checkAll: boolean) => {
      const dealsIdsToInclude = checkAll
        ? dealsState.deals.map(({ _id }) => _id)
        : [];

      setDealsIncludedCache(dealsIdsToInclude, submissionId);

      setDeals((prev) => ({
        ...prev,
        deals: prev.deals.map((item) => ({
          ...item,
          included_in_submission: checkAll,
        })),
      }));
    },
    [JSON.stringify(dealsState.deals)]
  );

  const handlePagination = useCallback(
    (pageNumber: number, pageSize: number) => {
      handleQueryParamsChange(pageNumber - 1, pageSize, sort);
    },
    [sort, handleQueryParamsChange]
  );

  const filterIncludedOnChange = useCallback(() => {
    const newFilterIncluded = filterIncluded === 'on' ? 'off' : 'on';
    setFilterIncluded(newFilterIncluded);
    handleQueryParamsChange(0, page_size, sort, '', newFilterIncluded === 'on');
  }, [filterIncluded, handleQueryParamsChange]);

  const onModifyColumnConfiguration = useCallback(
    (configuration: TableConfigurationData) => {
      return {
        ...configuration,
        columns: configuration.columns.reduce(
          (acc: TableConfigurationColumn[], col: TableConfigurationColumn) => {
            acc.push({
              ...col,
              editable: !isReadOnlyUser && col.editable,
              fieldHeaderHighlight:
                !!amountFieldName && amountFieldName === col.crm_field,
            });
            return acc;
          },
          []
        ),
      };
    },
    [isReadOnlyUser]
  );

  const dealsForTable: IRow[] =
    filterIncluded === 'on' && dealsIncludedCache[submissionId]
      ? dealsState.deals.reduce<IRow[]>((acc, curr) => {
          if (dealsIncludedCache[submissionId].includes(curr._id)) {
            acc.push({ ...curr, id: curr._id });
          }

          return acc;
        }, [])
      : dealsState.deals.map((row) => ({ id: row._id, ...row }));

  const dealsWithIncludedSummary = isIncludedDeals
    ? (dealsForTable.map((d) => ({
        ...d,
        includedSummaryForDeal: mapIncludedSummaryForDeal(
          d._id as string,
          dealsIncludedSummary
        ),
      })) as IRow[])
    : dealsForTable;

  const changesSinceDate = dealsState.changes_since;
  const changeSince = Array.isArray(queryParams.change_interval)
    ? (queryParams.change_interval[0] as string)
    : queryParams.change_interval;

  const dealsWithIncludedSummaryAndChangeDate =
    changesSinceDate && changeSince
      ? mapChangesSinceDataForDeals(
          dealsWithIncludedSummary,
          changesSinceDate,
          changeSince
        )
      : dealsWithIncludedSummary;

  const dealsToDisplay = useMemo(
    () =>
      updateDealsWithUpdatesInProgress(
        dealsWithIncludedSummaryAndChangeDate,
        statuses
      ),
    [dealsWithIncludedSummaryAndChangeDate, statuses]
  );

  if (isError) {
    return null;
  }

  const csvParams = {
    ...queryParams,
    force_basic_filters: forceBasicFilters,
  };

  const csvDownloadUrl = `${apiUrl}csv`;

  const downloadButton: DownloadButtonProps | undefined =
    inModal && !apiUrl.includes('by_metric') // activity dashboard
      ? {
          url: csvDownloadUrl,
          serializedQueryParams: JSON.stringify(csvParams),
          queryMethod: 'post',
          extendedOptions: extendedCSVDownload ?? true,
        }
      : undefined;

  const handleDraftChange: onChangeCallback = (column, row, newValue) => {
    const draftItem = draftData?.find((item) => item.id === row.id) || {
      id: row.id,
    };
    const draftListWithoutItem =
      draftData?.filter((item) => item.id !== row.id) || [];

    updateDraftData([
      ...draftListWithoutItem,
      R.assocPath(column.field.split('.'), newValue, draftItem),
    ]);
  };

  const handleSpecialClick: ColumnTypesCallback = (props) => {
    const { column, columnConfig, row, extraData } = props;

    if (column.type === ColumnTypes.SALES_PROCESS) {
      if (isColumnConfigSalesProcess(columnConfig)) {
        const { steps } = columnConfig.meta;
        const stepsWithValue = steps.map((step) => ({
          ...step,
          value: getDeltaValue(getFieldValue(step.object_field, row)),
        }));
        setSalesProcessName(columnConfig.display_name);
        setSalesProcessVersion(columnConfig.meta.version);
        setMeddicSteps(stepsWithValue);
        setSidePanelTitle(columnConfig.display_name);
        setSidePanelDeal(row as unknown as Deals.Deal);
        setVisiblePanel('meddic');
      }
    } else if (
      column.type === ColumnTypes.CUSTOM &&
      extraData?.type === CUSTOM_EXTRA_TYPE
    ) {
      syncDeal(row.id as string);
    } else {
      handleColumnClick(props);
    }
  };

  const panel = (
    <SidePanel
      title={sidePanelTitle}
      visible
      onClose={() => setVisiblePanel('')}
    >
      <MeddicSidePanel
        data={medicSteps}
        onCancel={() => setVisiblePanel('')}
        salesProcessVersion={salesProcessVersion}
        sidePanelDeal={sidePanelDeal}
        salesProcessName={salesProcessName}
        isRealTime={isRealTimeEnabledForTable}
      />
    </SidePanel>
  );

  const handleSearchChange = (searchValue: string) => {
    setSearchValue(searchValue);
    handleQueryParamsChange(0, page_size, sort, searchValue);
  };

  return (
    <TwoColumnsDashboard
      isVisiblePanel={!!visiblePanel}
      panel={panel}
      isModal={inModal}
    >
      <Table
        currentPage={(queryParams.page_number || 0) + 1}
        data={dealsToDisplay}
        dataType={dataType}
        downloadButton={!isIncludedDeals ? downloadButton : undefined}
        hidePaginationEnd={hideHeader || inModal}
        hidePaginationStart={hideHeader}
        hideSearch={!isIncludedDeals}
        isIncludedDeals={isIncludedDeals}
        loading={
          isLoading ||
          isTableConfigurationLoading ||
          isDealsIncludedSummaryLoading
        }
        onChange={handleChange}
        onIncludeAllDeals={handleIncludeAllDeals}
        onIncludedDealsChange={onIncludedDealsChange}
        onPaginationChange={handlePagination}
        onReady={handleReady}
        onSearchChange={handleSearchChange}
        onSort={handleSort}
        onSpecialClick={handleSpecialClick}
        rowsPerPage={queryParams.page_size || 10}
        searchPlaceholder="Search for deals"
        showActionButtons={isIncludedDeals && !isHistoricalSubmissionsModal}
        showColumnsVisibilityToggle={true}
        sortOrder={queryParams.sort}
        specialTableConfig={{
          getButtonLabel: (field: string) => `View ${field} Insights`,
        }}
        statuses={statuses}
        tableConfigCollection={tableConfigCollection}
        tableConfigName={tableConfigName}
        title={(!hideHeader && title) || ''}
        renderTitleExtra={
          isLoading
            ? undefined
            : (displayedColumns: DisplayedColumns) => (
                <>
                  {typeof overrideAmount === 'number' && (
                    <div className={styles.totalAmountInExtraTitle}>
                      (Total Amount{' '}
                      {formatMoney(
                        companyCurrencyCode,
                        dealsState.total_amount || 0,
                        0
                      )}
                      )
                    </div>
                  )}
                  {renderTitleExtra && renderTitleExtra(displayedColumns)}
                </>
              )
        }
        totalAmount={overrideAmount ?? dealsState.total_amount}
        totalCount={dealsState.count}
        isModal={inModal}
        draftData={draftData}
        onDraftChange={handleDraftChange}
        fullscreen={inModal}
        onCloseModal={onCloseModal}
        filterIncluded={filterIncluded}
        filterIncludedOnChange={filterIncludedOnChange}
        unfilteredTotalCount={dealsState.deals.length}
        leftControls={controls}
        onModifyColumnConfiguration={onModifyColumnConfiguration}
        styleFirstColumn={styleFirstColumn}
        showTotalAmount={showTotalAmount}
      />
    </TwoColumnsDashboard>
  );
};

export default DealsTable;
