import { Dispatch } from 'redux';
import { Action } from 'typescript-fsa';

import { actions } from 'actions';
import { IColumn, IRow } from 'components/UI/common/TypedTable/TypedTable';
import { SUPPORTED_HISTORICAL_DRILLDOWN_OBJECTS } from 'components/dashboard/Metrics/Widget/Historical/constants';
import {
  getMetricsNameAndValueUsedInFormula,
  getSyntheticMetricData,
  getWidgetMetricDisplayNameMap,
  openSyntheticMetricDetailsModal,
  parseFormulaToMetricsIdArray,
} from 'components/dashboard/Metrics/Widget/helper';
import { SyntheticMetricDataWidgetState } from 'components/dashboard/Metrics/Widget/types';
import { MetricType } from 'components/dashboard/Metrics/enums';
import {
  BIMetricCreated,
  BIMetricFormula,
  BIMetricSimple,
  BIWidget,
  BIWidgetDataV2,
  DrillDownFilter,
  DrillDownParams,
  SelectedValue,
  TimeSeriesDrillDownParams,
} from 'components/dashboard/Metrics/metrics.types';
import { PersistQueryParams } from 'navigation/types';
import { openModal } from 'navigation/utils';
import { PIVOT_HIERARCHY_SEPARATOR } from '../hooks/useExpandableWidgetTable/useExpandableWidgetTable.helper';
import {
  HierarchicalWidget,
  findNodeInTree,
} from '../hooks/useHierarchicalWidgetFetching/useHierarchicalWidgetFetching.helper';
import { OpenRevBiDrilldownModal } from '../hooks/useRevBiDrilldownModal';
import { dashboardFilters } from 'components/dashboard/Scorecard/styles';

/**
 * Takes widget pivots e.g. `group_by` and maps them into DrillDownFilter[]
 *
 * @param widget
 * @param pivotValue - value of the applied pivot
 * @returns DrillDownFilter[]
 */
const getDrilldownFilters = (
  widget: Partial<BIWidget>,
  pivotValue: string
): DrillDownFilter[] =>
  (widget.group_by ?? []).map((column) => ({
    column:
      column.type === 'date' && widget.time_field // TODO inspect this with time_field, not used in hists?
        ? widget.time_field
        : column,
    operator: 'eq',
    value: pivotValue,
  }));

/**
 * Opens the drilldown modal for simple metrics.
 *
 * @param drillDownFilters
 * @param drillDownMetric
 * @param pivotValue
 * @param widget
 * @param targetTimePeriod
 * @param businessTypeName
 * @param dispatch
 */
const openDrillDownModal = (
  drillDownFilters: DrillDownFilter[],
  drillDownMetric: BIMetricSimple,
  pivotValue: string | undefined,
  widget: Partial<BIWidget>,
  targetTimePeriod: string,
  businessTypeName: string,
  dispatch: Dispatch<Action<PersistQueryParams>>
): void => {
  if (
    drillDownMetric?.object &&
    SUPPORTED_HISTORICAL_DRILLDOWN_OBJECTS.includes(drillDownMetric.object)
  ) {
    const dashboardFilters =
      widget.dashboard_filters?.filter((elem) => elem.operator !== 'all') ?? [];
    const drilldownParams: TimeSeriesDrillDownParams = {
      filters: drillDownMetric?.filters ?? [],
      template_filters: widget.template_filters ?? [],
      dashboard_filters: dashboardFilters,
      drill_down_filters: drillDownFilters,
      time_interval: widget.time_interval,
      time_period: widget.time_period,
      point_in_time: widget.point_in_time,
      target_time_period: targetTimePeriod,
      business_type_name: businessTypeName,
      manager_aggregation_type: drillDownMetric.manager_aggregation_type,
    };

    openModal({
      scheme: '/historical-drilldown',
      persistParams: {
        drilldownParams: drilldownParams,
        metric: drillDownMetric,
        pivotValue: pivotValue,
        showTotalAmount: false,
      },
      params: {
        title: widget.name || 'Drill Down',
      },
      persistor: (params: PersistQueryParams) => {
        dispatch(actions.ui.modal.persist(params));
      },
    });
  }
};

const getChartDrillDownFilters = (
  selectedValue: any,
  widget: Partial<BIWidget>
) => {
  if (!selectedValue?.pivot2Id) {
    return [];
  }

  return getDrilldownFilters(widget, selectedValue?.pivot2Id);
};

const findDeeperFirstPivot = (
  data: any[] | undefined = [],
  field: string,
  value: string
): any => {
  let rst;
  for (let index = 0; index < data.length && !rst; index++) {
    const element = data[index];

    if (element[field] === value) {
      rst = element;
    } else if (element?.children?.length) {
      rst = findDeeperFirstPivot(element.children, field, value);
    }
  }
  return rst;
};

const findDeeperSecondPivot = (
  data: any[] | undefined = [],
  field1: string,
  value1: string,
  field2: string,
  value2: string
): any => {
  let rst;
  for (let index = 0; index < data.length && !rst; index++) {
    const element = data[index];
    const pivot2Data = findDeeperFirstPivot(element.pivots, field2, value2);
    if (element[field1] === value1 && pivot2Data) {
      rst = pivot2Data;
    } else if (element?.children?.length) {
      rst = findDeeperSecondPivot(
        element.children,
        field1,
        value1,
        field2,
        value2
      );
    }
  }
  return rst;
};

const getDataRow = (
  availablePivots: number,
  treeWidget: HierarchicalWidget | undefined,
  widget: BIWidget,
  pivot1Id: string,
  pivot2Id: string
) => {
  if (availablePivots === 1) {
    const pivot1Key = 'nodePath';

    return findDeeperFirstPivot(treeWidget?.tree, pivot1Key, pivot1Id) || {};
  } else if (availablePivots === 2) {
    const pivot1Key = 'nodePath';
    const pivot2Key = widget?.group_by[0]?.name?.replaceAll('.', '$') || '';

    return (
      (pivot2Id && pivot2Key
        ? findDeeperSecondPivot(
            treeWidget?.tree,
            pivot1Key,
            pivot1Id,
            pivot2Key,
            pivot2Id
          )
        : findDeeperFirstPivot(treeWidget?.tree, pivot1Key, pivot1Id)) || {}
    );
  }
};

const openChartSyntheticMetricDrillDownModal = (
  clickedMetric: BIMetricFormula,
  drillDownFilters: DrillDownFilter[],
  selectedValue: any,
  widget: BIWidget,
  treeWidget: HierarchicalWidget | undefined,
  openRevBiDrilldownModalWithOnClose: OpenRevBiDrilldownModal
): void => {
  const parsedClickedMetricFormulaAsArray: string[] =
    parseFormulaToMetricsIdArray(clickedMetric.synthetic_metric || '');

  const syntheticMetricData: SyntheticMetricDataWidgetState =
    getSyntheticMetricData(parsedClickedMetricFormulaAsArray, clickedMetric);

  const metricDisplayNames = getWidgetMetricDisplayNameMap(widget);

  const pathToFind = [selectedValue.pivot1Id, selectedValue.pivot2Id]
    .filter((p) => !!p)
    .join(PIVOT_HIERARCHY_SEPARATOR);
  const node = findNodeInTree(pathToFind, treeWidget?.tree || []);
  const y = node?.[selectedValue.metricId] || '-';

  const dashboardFilters =
    widget.dashboard_filters?.filter((elem) => elem.operator !== 'all') ?? [];
  const drilldownParams: DrillDownParams = {
    drill_down_filters: drillDownFilters,
    filters: clickedMetric?.filters ?? [],
    template_filters: widget.template_filters ?? [],
    dashboard_filters: dashboardFilters,
    order_by: [],
    offset: 0,
    limit: 1000000,
    duration:
      widget.group_by[0]?.type === 'date' ? widget.group_by[0].name : '',
    skip_business_validation: true,
  };

  const availablePivots =
    !!selectedValue.pivot1Id && !!selectedValue.pivot2Id ? 2 : 1;

  const dataRow = getDataRow(
    availablePivots,
    treeWidget,
    widget,
    selectedValue.pivot1Id,
    selectedValue.pivot2Id
  );

  openSyntheticMetricDetailsModal(
    widget.name || 'Drill Down',
    drilldownParams,
    syntheticMetricData,
    {
      ...selectedValue,
      metricsValues: getMetricsNameAndValueUsedInFormula(
        parsedClickedMetricFormulaAsArray,
        dataRow,
        metricDisplayNames
      ),
      y,
    },
    openRevBiDrilldownModalWithOnClose
  );
};

export const openChartDrilldownModal = (
  clickedMetric: BIMetricCreated,
  selectedValue: any,
  widget: BIWidget,
  treeWidget: HierarchicalWidget | undefined,
  businessType: string,
  dispatch: Dispatch<any>,
  openRevBiDrilldownModalWithOnClose: OpenRevBiDrilldownModal
) => {
  const drillDownFilters = getChartDrillDownFilters(selectedValue, widget);

  if ((clickedMetric as BIMetricFormula).synthetic_metric) {
    openChartSyntheticMetricDrillDownModal(
      clickedMetric as BIMetricFormula,
      drillDownFilters,
      selectedValue,
      widget,
      treeWidget,
      openRevBiDrilldownModalWithOnClose
    );
  } else {
    openDrillDownModal(
      drillDownFilters,
      clickedMetric as BIMetricSimple,
      selectedValue?.pivot2Id,
      widget,
      selectedValue?.pivot1Id,
      businessType,
      dispatch
    );
  }
};

/**
 * Returns drilldown filters based on provided pivot
 *
 * @param pivotValue - value of the applied pivot
 * @param row - Table row
 * @param widget
 * @returns DrillDownFilter[]
 */
const getTableDrilldownFilters = (
  pivotValue: string,
  row: IRow,
  widget: Partial<BIWidget>
): DrillDownFilter[] => {
  // If it is a subtotal row then we do not send second pivot
  if (row.isSubtotal) {
    return [];
  }

  return getDrilldownFilters(widget, pivotValue);
};

const openTableSyntheticMetricDrillDownModal = (
  clickedMetric: BIMetricFormula,
  column: IColumn,
  row: IRow,
  drillDownFilters: DrillDownFilter[],
  pivot1FieldName: string | undefined,
  widget: BIWidget,
  openRevBiDrilldownModalWithOnClose: OpenRevBiDrilldownModal
): void => {
  const parsedClickedMetricFormulaAsArray: string[] =
    parseFormulaToMetricsIdArray(clickedMetric.synthetic_metric || '');

  const syntheticMetricData: SyntheticMetricDataWidgetState =
    getSyntheticMetricData(
      parsedClickedMetricFormulaAsArray,
      clickedMetric as BIMetricFormula
    );

  const metricDisplayNames = getWidgetMetricDisplayNameMap(widget);

  const selectedValue: SelectedValue = {
    y: !Number.isNaN(row[column.field] as number)
      ? (row[column.field] as number)
      : 0,
    metricsValues: getMetricsNameAndValueUsedInFormula(
      parsedClickedMetricFormulaAsArray,
      row,
      metricDisplayNames,
      column.extraHeader
    ),
  };

  if (pivot1FieldName) {
    selectedValue.pivot1Id = row[pivot1FieldName] as string;
  }

  const dashboardFilters =
    widget.dashboard_filters?.filter((elem) => elem.operator !== 'all') ?? [];
  const syntheticDrilldownParams: DrillDownParams = {
    drill_down_filters: drillDownFilters,
    filters: clickedMetric?.filters ?? [],
    template_filters: widget.template_filters ?? [],
    dashboard_filters: dashboardFilters,
    offset: 0,
    order_by: [],
    limit: 1000000,
    duration:
      widget.group_by[0]?.type === 'date' ? widget.group_by[0].name : '',
    skip_business_validation: true,
  };

  openSyntheticMetricDetailsModal(
    widget.name || 'Drill Down',
    syntheticDrilldownParams,
    syntheticMetricData,
    selectedValue,
    openRevBiDrilldownModalWithOnClose
  );
};

export const openTableDrilldownModal = (
  clickedMetric: BIMetricCreated,
  column: IColumn,
  row: IRow,
  widget: BIWidget,
  widgetData: BIWidgetDataV2 | undefined,
  businessType: string,
  dispatch: Dispatch<any>,
  openRevBiDrilldownModalWithOnClose: OpenRevBiDrilldownModal
): void => {
  // pivot valure represents the pivot 2 value,
  // pivot 1 is sent out of drilldown filters.
  const pivotValue = (row?.expandValue as string) ?? '';
  const drillDownFilters = getTableDrilldownFilters(pivotValue, row, widget);

  if (
    clickedMetric.metadata?.type === MetricType.Formula &&
    (clickedMetric as BIMetricFormula).synthetic_metric
  ) {
    const pivotFieldName = widget.group_by[0]?.name || undefined;

    openTableSyntheticMetricDrillDownModal(
      clickedMetric as BIMetricFormula,
      column,
      row,
      drillDownFilters,
      pivotFieldName,
      widget,
      openRevBiDrilldownModalWithOnClose
    );
  } else {
    openDrillDownModal(
      drillDownFilters,
      clickedMetric as BIMetricSimple,
      pivotValue,
      widget,
      column.extraHeader ?? '',
      businessType,
      dispatch
    );
  }
};
